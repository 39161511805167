import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoginUser } from './loginuser'


// 認可制御対象機能リスト
const list = [
    { label: 'フィードバック機能', part: '^/feedback' },
]

const reg = new RegExp(`${list.map(l => `(${l.part})`).join('|')}`, "g")

export const useAuthorization = () => {
    const { pathname } = useLocation()
    const { data: user } = useLoginUser()

    useEffect(() => {
        // 対象ページか判定
        const ret = [...pathname.matchAll(reg)] || []
        if (!ret.length || !user) return

        // 対応する機能がOFFの場合認可エラーのステータスを投げる
        for (const [, ...match] of ret) {
            if (!user[list[match.findIndex(m => m)].label]) throw new Response("forbidden", { status: 403 })
        }
    }, [pathname, user])
}
