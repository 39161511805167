import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { MenuBarButton, MenuBarIconButton } from 'components/styledButtons'
import Badge from 'components/Badge'
import { useNewMessageCount } from 'hooks/message'

const MailboxButton = () => {
    const data = useNewMessageCount()
    const isWide = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    return (
        <Badge
            count={data}
            circular={isWide}
            menuButton={!isWide}
        >
            {isWide ? 
            <MenuBarButton
                color="button"
                variant="outlined"
                component={Link}
                to="message"
            >
                メッセージボックス
            </MenuBarButton>
            :
            <MenuBarIconButton
                component={Link}
                to="message"
            >
                <MailOutlineIcon />
            </MenuBarIconButton>
            }
        </Badge>
    )
}

export default MailboxButton
