import { Divider, Stack } from '@mui/material'
import { format } from 'date-fns'
import { Body, H4 } from 'components/styledFonts'
import { GreyLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import { TitledBlock } from 'components/Block'


const ResearchList = ({ title, data, recently, handleClick, ...props }) => {

    return (
        <TitledBlock
            title={title}
            {...props}
        >
            {data?.length === 0 &&
            <Body>{`${title}はありません`}</Body>
            }
            {data?.map((d, i) => (
            <Stack
                key={i}
                px={1}
            >
                {!recently &&
                <Divider />
                }
                <Stack
                    alignItems="flex-start"
                    pt={1}
                >
                    {recently &&
                    <Body>{format(d.募集開始日時_通常用, 'yyyy/MM/dd')}</Body>
                    }
                    {!recently &&
                    <Body>最終更新日：{format(d.更新日時, 'yyyy/MM/dd HH:mm')}</Body>
                    }
                    {handleClick &&
                    <UnderlineLink
                        onClick={() => handleClick(d.リクエストNo)}
                        to=""
                    >
                        <H4>{d.タイトル}</H4>
                    </UnderlineLink>
                    }
                    {recently &&
                    <UnderlineLink
                        to={`/research/detail/${d.リクエストNo}`}
                    >
                        <H4>{d.タイトル}</H4>
                    </UnderlineLink>
                    }
                    {(!handleClick && !recently) &&
                    <H4>{d.タイトル}</H4>
                    }
                    {recently &&
                    <GreyLabel>リサーチ締切：{format(d.募集締切日時, 'yyyy/MM/dd HH:mm')}</GreyLabel>
                    }
                </Stack>
            </Stack>
            ))}
        </TitledBlock>
    )
}

export default ResearchList

