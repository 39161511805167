import { format } from 'date-fns'
import { Stack } from '@mui/material'
import ComponentBase from './ComponentBase'
import { UnderlineLink } from 'components/links'
import { H5Overflow, Body } from 'components/styledFonts'
import { BlueLabel, GreyLabel } from 'components/styledLabels'
import Thumbnail from 'components/Thumbnail'
import { useNewFeedbackList } from 'hooks/feedback'

const path = (d) => {
    return `/feedback/input/${d.フィードバックNo}/${d.path}`
}

const NewFeedbackItem = ({ data }) => {
    return (
        <Stack
            gap={1}
            alignItems="flex-start"
        >
            <Stack
                direction="row"
                flexWrap="wrap"
                columnGap={1}
                rowGap={1}
                alignItems="center"
            >
                <GreyLabel>募集開始日：{format(data.メディア公開日時, 'yy/MM/dd HH:mm')}</GreyLabel>
                <BlueLabel>{data.カテゴリ}</BlueLabel>
            </Stack>
            <Stack
                direction="row"
                alignItems="flex-start"
                gap={1}
            >
                <Thumbnail
                    src={data.サムネイル}
                    to={path(data)}
                />
                <Stack
                    gap={1}
                >
                    <UnderlineLink
                        to={path(data)}
                    >
                        <H5Overflow>{data.タイトル}</H5Overflow>
                    </UnderlineLink>
                    <Body>{data.企業名}</Body>
                </Stack>
            </Stack>
        </Stack>
    )
}

const NewFeedback = () => {
    const { data } = useNewFeedbackList()

    return (
        <ComponentBase
            title="新着フィードバック依頼"
            count={data?.length}
            buttons={[{ label : 'フィードバック一覧', to : 'feedback/list' }]}
        >
            {(data && data.length > 0) &&
            <Stack
                px={1}
                gap={3}
            >
                {data.slice(0, 3).map((d, i) => (
                <NewFeedbackItem
                    key={i}
                    data={d}
                />
                ))}
            </Stack>
            }
        </ComponentBase>
    )
}

export default NewFeedback
