import { styled } from '@mui/material/styles'
import { blue } from '@mui/material/colors'
import { Box } from '@mui/material'


const BalloonInner = styled(Box)({
    position : 'relative',
    fontSize : 16,
    fontWeight : 700,
    lineHeight : 1.45,
    color : blue[500],
    background : '#fff',
    padding : '11.2px 11.2px 9.6px',
    border : `3px solid ${blue[100]}`,
    borderRadius : 16,
    '@media (max-width: 640px)' : {
        '&::after' : {
            top : '91.5% !important',
            left : '49% !important',
            transform : 'rotate(0deg) !important',
        },
    },
    '&::after' : {
        content : '""',
        position : 'absolute',
        marginLeft : -12.5,
        border : '15px solid transparent',
        borderTop : `30px solid #fff`,
        zIndex : 9,
    },
})

const BalloonOuter = styled(Box)({
    position : 'absolute',
    '@media (max-width: 640px)' : {
        position : 'relative',
        width : "90%",
        maxWidth : "90%",
        minWidth : "90%",
        '&::after' : {
            top : '98% !important',
            left : '50% !important',
            transform : 'rotate(0deg) !important',
        },
    },
    '&::after' : {
        content : '""',
        position : 'absolute',
        marginLeft : -15,
        border : '15px solid transparent',
        borderTop : `30px solid ${blue[100]}`,
        zIndex : -1,
    },
})

const Balloon = ({ children, outerSx, innerSx }) => {
    return (
        <BalloonOuter sx={outerSx}>
            <BalloonInner sx={innerSx}>
                {children}
            </BalloonInner>
        </BalloonOuter>
    )
}

export const Balloon1 = ({ children }) => {
    return (
        <Balloon
            outerSx={{
                top : '0%',
                left : { sm : "12%", xs : 0 },
                width : '25%',
                zIndex : { sm : 3, xs : 5 },
                '&::after' : {
                    top : '88%',
                    left : '73%',
                    transform: 'rotate(-30deg)',
                },
            }}
            innerSx={{
                '&::after' : {
                    top : '84%',
                    left : '70%',
                    transform: 'rotate(-30deg)',
                }
            }}
        >
            {children}
        </Balloon>
    )
}

export const Balloon2 = ({ children }) => {
    return (
        <Balloon
            outerSx={{
                top : '53%',
                left : { sm : 0, xs : "10%" },
                maxWidth : '33%',
                zIndex : { sm : 2, xs : 4 },
                '&::after' : {
                    top : '85%',
                    left : '73%',
                    transform: 'rotate(-20deg)',
                },
            }}
            innerSx={{
                '&::after' : {
                    top : '75%',
                    left : '71%',
                    transform: 'rotate(-20deg)',
                }
            }}
        >
            {children}
        </Balloon>
    )
}

export const Balloon3 = ({ children }) => {
    return (
        <Balloon
            outerSx={{
                top : '32%',
                left : { sm : "33%", xs : 0 },
                width : '30%',
                zIndex : { sm : 4, xs : 3 },
                '&::after' : {
                    top : '95%',
                    left : '73%',
                },
            }}
            innerSx={{
                '&::after' : {
                    top : '91%',
                    left : '72%',
                }
            }}
        >
            {children}
        </Balloon>
    )
}

export const Balloon4 = ({ children }) => {
    return (
        <Balloon
            outerSx={{
                top : '7%',
                right : 0,
                maxWidth : '50%',
                zIndex : { sm : 5, xs : 2 },
                '@media (max-width: 640px)' : {
                    left : "10%",
                },
                '&::after' : {
                    transform : 'rotate(20deg)',
                    top : '84%',
                    left : '32%',
                },
            }}
            innerSx={{
                '&::after' : {
                    transform : 'rotate(20deg)',
                    top : '74%',
                    left : '31%',
                }
            }}
        >
            {children}
        </Balloon>
    )
}

export const Balloon5 = ({ children }) => {
    return (
        <Balloon
            outerSx={{
                top : '45%',
                right : 0,
                maxWidth : '40%',
                zIndex : { sm : 6, xs : 1 },
                '&::after' : {
                    transform : 'rotate(30deg)',
                    top : '75%',
                    left : '40%',
                },
            }}
            innerSx={{
                '&::after' : {
                    transform : 'rotate(30deg)',
                    top : '65%',
                    left : '40%',
                }
            }}
        >
            {children}
        </Balloon>
    )
}


