import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import { LabeledTextField, RadioGroup, Required, Select, TextField } from 'components/Inputs'
import { Body, H2, H4, H5 } from 'components/styledFonts'
import { BlueContainedButton, BlueOutlinedButton, LinkButtonMiddle } from 'components/styledButtons'
import Block from 'components/Block'
import { message } from 'config/message'
import { useFeedbackByNo } from 'hooks/feedback'
import { useFeedbackMutaion } from 'hooks/mutation/feedback'
import { useLoginUser } from 'hooks/loginuser'


const createSelect = a => [{ label : '--------', value : '' }].concat(a.map(l => ({ label : l, value : l })))

const mediaCategories = createSelect([
    'テレビ',
    'ラジオ',
    '新聞',
    '雑誌',
    'WEB',
    'その他'
])
const mediaGenres = createSelect([
    'IT・テクノロジー',
    '美容・ファッション',
    '交通・運輸・輸送・流通',
    '住宅・建築・建設',
    '教育・人材・キャリア',
    'レジャー・アウトドア・観光・おでかけ',
    '自然・環境',
    '農林・水産',
    'ライフスタイル・生活用品・ガジェット',
    'マネー・ビジネス',
    '食品・グルメ',
    '地域・くらし・医療・育児',
    'エンタメ・スポーツ',
])
const mediaStatus = createSelect([
    'メディア関係者',
    '元メディア関係者',
])
const mediaTypes = createSelect([
    '【テレビ】制作',
    '【テレビ】放送作家',
    '【テレビ】リサーチャー',
    '【ラジオ】制作',
    '【ラジオ】放送作家',
    '【新聞】記者',
    '【新聞】編集委員',
    '【雑誌】編集 ',
    '【雑誌】ライター',
    '【WEB】編集',
    '【WEB】ライター',
    'その他',
])
const reviews = createSelect([
    'とてもよい',
    'よい',
    'よくも悪くもない',
    'よいとは思わない',
    '再考が必要',
])
const possiblities = [
    '大いにある',
    'どちらかといえばある',
    'どちらかといえばない',
    'ない',
    '検討するにも追加情報が必要',
]

const schema = yup.object().shape({
    ...[
        'メディアカテゴリー',
        'メディアジャンル',
        '担当者ステータス',
        '職種',
        '総評_見出し',
        '総評_文章構成',
        '総評_ネタ切り口',
        '総評_社会性時事性',
        '総評_エビデンス',
        '可能性',
    ].reduce((o, d) => ({ ...o, [d]: yup.string().required(message.SELECTIONREQUIERED) }), {}),
    ...[
        '媒体名',
        '興味を惹かれた点_メディア起票時',
        '興味を持てなかった点_メディア起票時',
        '欲しい追加情報_メディア起票時',
        'アドバイス_メディア起票時',
    ].reduce((o, d) => ({ ...o, [d]: yup.string().required(message.REQUIRED) }), {}),
})

const defaultValues = {
    メディアカテゴリー : '',
    メディアジャンル : '',
    媒体名 : '',
    担当者ステータス : '',
    職種 : '',
    総評_見出し : '',
    総評_文章構成 : '',
    総評_ネタ切り口 : '',
    総評_社会性時事性 : '',
    総評_エビデンス : '',
    可能性 : '',
    興味を惹かれた点_メディア起票時 : '',
    興味を持てなかった点_メディア起票時 : '',
    欲しい追加情報_メディア起票時 : '',
    アドバイス_メディア起票時 : '',
    その他_メディア起票時 : '',
}

const buttonSize = {
    maxWidth : 100,
    minWidth : 100,
}

const Category = ({ title, required, note, children }) => (
    <Stack
        gap={1}
    >
        <Stack
            gap={0.25}
        >
            <H5>{title}{required && <Required />}</H5>
            {note &&
            <Body>{note}</Body>
            }
        </Stack>
        {children}
    </Stack>
)

const LV = ({ required, label, children }) => (
    <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
    >
        <Body
            sx={{
                flexShrink : 0,
            }}
        >
            {label}{required && <Required />}
        </Body>
        <Body
            sx={{
                flexShrink : 0,
                width : { mobile : 210, xs : 180 },
            }}
        >
            {children}
        </Body>
    </Stack>
)

const FeedbackForm = () => {
    const { fbno } = useParams()
    const { data: fb } = useFeedbackByNo(fbno)
    const { data: user } = useLoginUser()
    const { mutate } = useFeedbackMutaion()

    const { control, handleSubmit, getValues, setValue, reset } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (!fb) return

        Object.keys(defaultValues).forEach(n => setValue(n, fb[n] || ''))
    }, [fb, setValue])

    const onSubmit = (data) => {
        mutate({
            post: true,
            form: data,
            id: fb.$id,
            revision: fb.$revision
        })
    }

    const onClickFeedback = () => {
        mutate({
            occupied: true,
            id: fb.$id,
            revision: fb.$revision
        })
    }

    const onClickCancel = () => {
        mutate({
            cancel: true,
            form: defaultValues,
            id: fb.$id,
            revision: fb.$revision
        })
        reset()
    }

    const onClickDraft = () => {
        mutate({
            form: getValues(),
            id: fb.$id,
            revision: fb.$revision
        })
    }

    return (
        <Block>
            <Stack
                alignItems="center"
                gap={2}
            >
                {fb?.一時保存 && fb?.媒体担当者ID !== user?.媒体担当者ID &&
                <H4 sx={{ color : red[400] }}>フィードバック済みです。入力できません</H4>
                }
                {!fb?.一時保存 && fb?.媒体担当者ID === '' &&
                <Stack
                    alignItems="center"
                    gap={1}
                >
                    <BlueOutlinedButton
                        label="フィードバックを行う"
                        onClick={onClickFeedback}
                        sx={{
                            width : 240,
                            height : 44,
                        }}
                    />
                    <Body sx={{ color : red[400] }}>※入力開始後7日が締切日です</Body>
                </Stack>
                }
                {fb?.一時保存 && fb?.媒体担当者ID === user?.媒体担当者ID && <>
                <Stack
                    alignSelf="flex-start"
                    gap={2}
                >
                    <H2>フィードバック入力</H2>
                    <Body sx={{ color : red[400] }}>※{format(fb.一時保存締切日, 'yyyy年MM月dd日')}が締切日です</Body>
                </Stack>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    gap={3}
                >
                    <Category
                        title="レビュアーのプロフィール"
                        required
                    >
                        <LV label="メディアカテゴリー">
                            <Select name="メディアカテゴリー" control={control} list={mediaCategories} />
                        </LV>
                        <LV label="メディアジャンル">
                            <Select name="メディアジャンル" control={control} list={mediaGenres} />
                        </LV>
                        <LV label="媒体名">
                            <TextField name="媒体名" control={control} />
                        </LV>
                        <LV label="ステータス">
                            <Select name="担当者ステータス" control={control} list={mediaStatus} />
                        </LV>
                        <LV label="職種">
                            <Select name="職種" control={control} list={mediaTypes} />
                        </LV>
                    </Category>
                    <Category
                        title="総評"
                        required
                    >
                        <LV label="見出し">
                            <Select name="総評_見出し" control={control} list={reviews} />
                        </LV>
                        <LV label="文章・構成">
                            <Select name="総評_文章構成" control={control} list={reviews} />
                        </LV>
                        <LV label="ネタ切り口">
                            <Select name="総評_ネタ切り口" control={control} list={reviews} />
                        </LV>
                        <LV label="社会性／時事性">
                            <Select name="総評_社会性時事性" control={control} list={reviews} />
                        </LV>
                        <LV label="エビデンス">
                            <Select name="総評_エビデンス" control={control} list={reviews} />
                        </LV>
                    </Category>
                    <Category
                        title="①担当メディアで取り上げる可能性"
                        note="※レビュアーによる取材／掲載をお約束するものではありません"
                        required
                    >
                        <RadioGroup name="可能性" control={control} list={possiblities} gap={0} />
                    </Category>
                    <LabeledTextField
                        label="②興味を惹かれた点"
                        name="興味を惹かれた点_メディア起票時"
                        required
                        control={control}
                        multiline
                        fullWidth
                        minRows={3}
                    />
                    <LabeledTextField
                        label="③興味を持てなかった点"
                        name="興味を持てなかった点_メディア起票時"
                        control={control}
                        required
                        multiline
                        fullWidth
                        minRows={3}
                    />
                    <LabeledTextField
                        label="④欲しい追加情報や素材、確認したい事項"
                        name="欲しい追加情報_メディア起票時"
                        required
                        control={control}
                        multiline
                        fullWidth
                        minRows={3}
                    />
                    <LabeledTextField
                        label="⑤メディアに取り上げられるためのアドバイス"
                        name="アドバイス_メディア起票時"
                        required
                        control={control}
                        multiline
                        fullWidth
                        minRows={3}
                    />
                    <LabeledTextField
                        label="⑥その他・所感など"
                        name="その他_メディア起票時"
                        control={control}
                        multiline
                        fullWidth
                        minRows={3}
                    />
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ gap : { mobile : 2, xs : 1.5 } }}
                    >
                        <LinkButtonMiddle
                            sx={buttonSize}
                            onClick={onClickCancel}
                        >
                            キャンセル
                        </LinkButtonMiddle>
                        <BlueOutlinedButton
                            label="一時保存"
                            sx={buttonSize}
                            onClick={onClickDraft}
                        />
                        <BlueContainedButton
                            label="送信"
                            type="submit"
                            sx={buttonSize}
                        />
                    </Stack>
                </Stack>
                </>}
            </Stack>
        </Block>
    )
}

export default FeedbackForm
