import MuiBadge from '@mui/material/Badge'

const Badge = ({ count, showCount, circular, menuButton, menuList, message, children, sx }) => {
    return (
        <MuiBadge
            badgeContent={showCount && count ? count : null}
            variant={!showCount && count ? 'dot' : null}
            color="badge"
            anchorOrigin={{
                vertical: 'top',
                horizontal : 'left'
            }}
            sx={{
                '& .MuiBadge-badge' : {
                    width : showCount ? null : 14,
                    height : showCount ? 20 : 14,
                    borderRadius : 9999,
                    top : menuButton ? 12 : menuList ? 0 : circular ? 4 : message ? 10 : 3,
                    left : menuButton ? 8 : menuList ? -8 : circular ? 10 : message ? 10 : 3,
                },
                ...sx,
            }}
        >
            {children}
        </MuiBadge>
    )
}

export default Badge
