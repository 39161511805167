import { Link, useLocation, useParams } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import { PaginationAccordion } from 'components/Accordion'
import Badge from 'components/Badge'
import S3Image from 'components/S3Image'
import { H1, H5 } from 'components/styledFonts'
import { useClientMessages, useReadFlags, useSupportMessages } from 'hooks/message'
import Entry from './Entry'
import History from './History'
import SubButton from './SubButton'

const max = 5
const MailBoxForMobile = () => {
    const { state } = useLocation()
    const { id } = useParams()
    const { data : messages } = useClientMessages(state, id)
    const { data : support } = useSupportMessages()
    const flags = useReadFlags()

    return (
        <Stack gap={2}>
            <H1>メッセージボックス</H1>
            <PaginationAccordion
                data={messages}
                keyName="企業ID"
                summary={d => (
                    <Badge
                        count={flags?.[d.$id]?.filter(f => f).length}
                        showCount
                        menuList
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            sx={{ wordBreak : "break-all" }}
                        >
                            <S3Image height={25} url={d.会社ロゴ} />
                            <H5>{d.会社名}</H5>
                        </Stack>
                    </Badge>
                )}
                detail={d => (
                    <Stack>
                        <History message={d} />
                        <Entry entryMessage={d} />
                    </Stack>
                )}
                max={max}
                defaultValue = {id}
                calcPage = {() => {
                    if (id) {
                        const index = messages?.findIndex(m => m.企業ID === id)
                        return Math.floor(index / max) + 1
                    }
                    return 1
                }}
            />
            <Stack
                gap={1}
                alignSelf="center"
            >
                <Badge
                    count={flags?.[support[0]?.$id]?.some(f => f)}
                >
                    <Button
                        variant="outlined"
                        color='inherit'
                        sx={{
                            bgcolor : '#e0e0e0',
                            padding : '8px 0px'
                        }}
                        component={Link}
                        to={"/message/support"}
                        fullWidth
                    >
                        <H5>サポートデスクへのお問い合わせ</H5>
                    </Button>
                </Badge>
                <SubButton />
            </Stack>
        </Stack>
    )
}

export default MailBoxForMobile
