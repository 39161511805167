import { Pagination, Stack } from '@mui/material'
import { useAtom, useSetAtom } from 'jotai'
import { searchPageAtom, searchTriggerAtom } from 'store/commonAtoms'

const PaginationList = ({ data, gap=0, max=50, siblingCount=0, total }) => {
    const [page, setPage] = useAtom(searchPageAtom)
    const setTrigger = useSetAtom(searchTriggerAtom)

    const handleChange = (e, p) => {
        setPage(p)
        if (total) setTrigger(true)
        window.scrollTo({
            top : 0,
            behavior : 'smooth',
        })
    }

    const startIndex = (page - 1) * max

    return (
        <Stack
            alignItems="center"
        >
            {data && (<>
            <Stack
                width="100%"
                gap={gap}
            >
                {total ? data : data.slice(startIndex, startIndex + max)}
            </Stack>
            <Pagination
                count={Math.ceil((total || data.length) / max)}
                variant="outlined"
                shape="rounded"
                page={page}
                siblingCount={siblingCount}
                onChange={handleChange}
                sx={{ pt : 3 }}
            />
            </>)}
        </Stack>
    )
}

export default PaginationList
