import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ResearchEntry from 'components/ResearchEntry'
import { API } from 'lib/api'
import { apiName } from 'config/api'


const PrintEntry = () => {
    const { no } = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        (async () => {
            const ret = await API.get(apiName, `/items/getEntry?no=${no}`, {})
            setData(ret)
        })()
    }, [no])

    return (
        <ResearchEntry
            data={data}
            print
        />
    )
}

export default PrintEntry
