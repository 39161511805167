import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, IconButton, Modal, Fade, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAtom } from 'jotai'
import { draftResearchNo, researchModal } from 'jotaiAtoms'
import { addBusinessDays, addYears } from 'date-fns'
import { H2, H5 } from 'components/styledFonts'
import { DraftButton, PostButton } from 'components/styledButtons'
import { message } from 'message/messageDefine'
import { useResearchByNo } from 'hooks/research'
import { useResearchMutaion } from 'hooks/mutation/research'

import {
    CharacterLimitField,
    CheckboxGroup,
    DatePicker,
    RadioGroup,
    LabeledTextField,
    Required,
} from 'components/Inputs'

const defaultValues = {
    媒体名: '',
    媒体のタイプ: '',
    媒体名の開示: '',
    掲載放送予定日: '',
    掲載放送未定: false,
    取材予定日: '',
    取材予定日未定: false,
    希望日時: null,
    テーマ: '',
    お探しの情報: '',
    詳細: '',
    一時保存: '',
    $revision: '',
    リクエストNo: ''
}

const schema = yup.object().shape({
    媒体名: yup
        .string()
        .required(message.REQUIRED)
    ,
    媒体のタイプ: yup
        .string()
        .required(message.SELECTIONREQUIERED)
    ,
    媒体名の開示: yup
        .string()
        .required(message.SELECTIONREQUIERED)
    ,
    希望日時: yup
        .date()
        .nullable()
        .transform(v => v ? v : null)
        .required(message.REQUIRED)
    ,
    掲載放送未定: yup
        .boolean()
        .when('掲載放送予定日', {
            is: (掲載放送予定日) => !掲載放送予定日 || 掲載放送予定日.trim() === '',
            then: yup.boolean().oneOf([true], message.EITHERONEREQUIRED)
        })
    ,
    取材予定日未定: yup
        .boolean()
        .when('取材予定日', {
            is: (取材予定日) => !取材予定日 || 取材予定日.trim() === '',
            then: yup.boolean().oneOf([true], message.EITHERONEREQUIRED)
        })
    ,
    テーマ: yup
        .string()
        .required(message.SELECTIONREQUIERED)
    ,
    お探しの情報: yup
        .string()
        .required(message.REQUIRED)
        .max(100, `100${message.MAX}`)
    ,
    詳細: yup
        .string()
        .max(2000, `2000${message.MAX}`)
    ,
})

// タイプ
const types = ['TV', '新聞', '雑誌', 'ラジオ', 'WEB']

// 開示
const disclosure = ['公開', '非公開']

// テーマ
const themes = [
    'モノ', 'サービス', '社外イベント', '店舗/施設', '企業', '読プレ',
    '社長', '社員', 'ロケ地', '調査データ', '企業制度・行事', 'その他',
]

const FreeResearchModal = () => {
    const [open, setOpen] = useAtom(researchModal)
    const [draftNo, setDraftNo] = useAtom(draftResearchNo)
    const { data } = useResearchByNo(draftNo)
    const { control, handleSubmit, reset, watch, setValue, getValues } = useForm({
        defaultValues: defaultValues, resolver: yupResolver(schema)
    })
    const { mutate } = useResearchMutaion()

    useEffect(() => {
        if (!data) return

        setValue('媒体名', data.媒体名_企業公開用)
        setValue('媒体のタイプ', data.媒体種類)
        setValue('媒体名の開示', data.媒体名公開 ? '公開' : '非公開')
        setValue('掲載放送予定日', data.掲載予定日)
        setValue('掲載放送未定', data.掲載予定日未定)
        setValue('取材予定日', data.取材予定日)
        setValue('取材予定日未定', data.取材予定日未定)
        setValue('希望日時', data.募集締切日時)
        setValue('テーマ', data.テーマ)
        setValue('お探しの情報', data.タイトル_メディア起票時)
        setValue('詳細', data.詳細_メディア起票時)
        setValue('一時保存', data.メディア一時保存)
        setValue('$revision', data.$revision)
        setValue('リクエストNo', data.リクエストNo)

    }, [data, setValue, reset])

    const onSubmit = (data) => {
        console.log('onSubmit', data)
        mutate(data)
        reset()
        setDraftNo(null)
        setOpen(false)
    }

    // 掲載放送未定がtrueだと掲載放送予定日をクリア
    const checkboxChange = () => {
        // clickされる瞬間がwatchに反映されるのでtrueに切り替える場合直前のfalse値になる。trueの場合逆
        if (!watch('掲載放送未定')) {
            setValue('掲載放送予定日', '')
        }
        if (!watch('取材予定日未定')) {
            setValue('取材予定日', '')
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                reset()
                setDraftNo(null)
                setOpen(false)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Fade in={open} timeout={1000}>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    bgcolor="#fff"
                    width="90%"
                    height="92%"
                    maxWidth={1100}
                    pt={2}
                    pl={2}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        py={2}
                        sx={{
                            pl: { mobile: 2, xs: 0.2 },
                        }}
                    >
                        <H2>無料リサーチ 入力フォーム</H2>
                        <IconButton
                            sx={{
                                height: 40,
                                mt: -3,
                                mr: 1
                            }}
                            onClick={() => {
                                reset()
                                setDraftNo(null)
                                setOpen(false)
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Box
                        overflow="hidden"
                        pb={1.5}
                        sx={{
                            pl: { mobile: 4, xs: 0 },
                            pr: { mobile: 1, xs: 0 }
                        }}
                    >
                        <Stack
                            height="100%"
                            overflow="auto"
                        >
                            <Stack
                                direction="column"
                                flexWrap="wrap"
                                rowGap={4}
                                pb={1}
                                sx={{
                                    pr: { mobile: 3, xs: 2 }
                                }}
                            >
                                <LabeledTextField
                                    name="媒体名"
                                    control={control}
                                    required
                                    placeholder="媒体名"
                                    sx={{
                                        width: { mobile: '50%', xs: '100%' }
                                    }}
                                />
                                <Stack
                                    gap={0.5}
                                >
                                    <H5>媒体のタイプ<Required /></H5>
                                    <RadioGroup
                                        name="媒体のタイプ"
                                        control={control}
                                        list={types}
                                        row
                                        sx={{
                                            gap: { mobile: 2.3, xs: 0 }
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    gap={0.5}
                                >
                                    <H5>媒体名の開示<Required /></H5>
                                    <RadioGroup
                                        name="媒体名の開示"
                                        control={control}
                                        list={disclosure}
                                        row
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="flex-end"
                                    flexWrap="wrap"
                                    gap={1}
                                >
                                    <LabeledTextField
                                        label="掲載・放送予定日"
                                        name="掲載放送予定日"
                                        control={control}
                                        placeholder="掲載・放送予定日"
                                    />
                                    <Stack pl={2} direction="row" alignItems="flex-end">
                                        <CheckboxGroup
                                            name="掲載放送未定"
                                            control={control}
                                            list={[{ label: '未定', value: true }]}
                                            boolean
                                            onClick={checkboxChange}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="flex-end"
                                    flexWrap="wrap"
                                    gap={1}
                                >
                                    <LabeledTextField
                                        label="取材予定日"
                                        name="取材予定日"
                                        control={control}
                                        placeholder="取材予定日"
                                    />
                                    <Stack pl={2} direction="row" alignItems="flex-end">
                                        <CheckboxGroup
                                            name="取材予定日未定"
                                            control={control}
                                            list={[{ label: '未定', value: true }]}
                                            boolean
                                            onClick={checkboxChange}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack
                                    gap={0.5}
                                >
                                    <H5>いつまでに情報が欲しいですか？<Required /></H5>
                                    <DatePicker
                                        name="希望日時"
                                        control={control}
                                        placeholderText="希望日時"
                                        showTimeInput
                                        includeDateIntervals={[{
                                            start: addBusinessDays(new Date(), 4),
                                            end: addYears(new Date(), 1),
                                        }]}
                                    />
                                </Stack>
                                <Stack
                                    gap={0.5}
                                >
                                    <H5>探しているテーマを選択して下さい<Required /></H5>
                                    <RadioGroup
                                        name="テーマ"
                                        control={control}
                                        list={themes}
                                        row
                                        sx={{
                                            columnGap: 0,
                                            '& .MuiFormControlLabel-root': {
                                                width: { mobile: '23%', xs: '60%' },
                                            },
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    gap={0.5}
                                >
                                    <CharacterLimitField
                                        name="お探しの情報"
                                        limit={100}
                                        control={control}
                                        required
                                        disableEnter
                                        multiline
                                        watch={watch("お探しの情報")}
                                        placeholder="お探しの情報"
                                    />
                                </Stack>
                                <Stack
                                    gap={0.5}
                                >
                                    <CharacterLimitField
                                        name="詳細"
                                        limit={2000}
                                        control={control}
                                        label="詳細（自由記述）"
                                        multiline
                                        rows={3}
                                        watch={watch("詳細")}
                                        placeholder="求める情報を具体的に記載"
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    pt={3}
                                    pb={5}
                                    gap={1.5}
                                    flexWrap="wrap"
                                >
                                    <DraftButton
                                        label="キャンセル"
                                        type="button"
                                        onClick={() => {
                                            reset()
                                            setDraftNo(null)
                                            setOpen(false)
                                        }}
                                    />
                                    <DraftButton
                                        label="一時保存"
                                        name="draft"
                                        type="button"
                                        onClick={() => {
                                            setValue('一時保存', true)
                                            onSubmit(getValues())
                                        }}
                                    />
                                    <PostButton
                                        label="リサーチ申請"
                                        name="post"
                                        onClick={() => {
                                            setValue('一時保存', false)

                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Fade>
        </Modal>
    )
}

export default FreeResearchModal
