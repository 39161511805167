import { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { Divider, Stack } from '@mui/material'
import { H2, Body } from 'components/styledFonts'
import { DraftButton, SnsButtons } from 'components/styledButtons'
import Block from 'components/Block'
import { WebsiteLink } from 'components/links'
import { useCompanyById } from 'hooks/company'
import { loadedComAtom } from 'store/commonAtoms'


const LV = ({ label, value, odd }) => (<>
    <Stack
        direction="row"
        alignItems="center"
        py={1.5}
        px={1}
        gap={1}
    >
        <Body
            sx={{
                width: 100,
                flexShrink: 0,
            }}
        >
            {label}
        </Body>
        <Body
            sx={{ wordBreak: "break-all" }}
        >
            {value}
        </Body>
    </Stack>
    <Divider />
</>)


const CompanyInfo = ({ contact, title }) => {
    const { clientId } = useParams()
    const { data } = useCompanyById(clientId)
    const setLoadedCom = useSetAtom(loadedComAtom)
    const { pathname } = useLocation()

    useEffect(() => {
        if (data && !/\/entry/.test(pathname)) setLoadedCom(true)
    }, [data, setLoadedCom, pathname])

    return (
        <Block
            sx={{
                minHeight : 100,
                width : "100%"
            }}
        >
            <Stack
                gap={2}
            >
                {data && (<>
                <Stack>
                    <H2>{data.会社名 || data.企業名}</H2>
                </Stack>
                <Stack
                    px={1}
                >
                    <Divider />
                    {data.会社名 && <>
                    <LV label="問合せ担当者" value={contact || `${data.広報担当者部署 ? `${data.広報担当者部署}　` : ''}${data.広報担当者役職 ? `${data.広報担当者役職}　` : ''}${data.広報担当者名 || '' }`} />
                    <LV label="URL" value=<WebsiteLink url={data.Webサイト_URL} /> />
                    <LV label="住所" value={data.住所} />
                    <LV label="業種" value={data.業種} />
                    <LV label="お問い合わせ先" value={data.広報担当者電話} />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        rowGap={2}
                        pt={2}
                        sx={{
                            px : { mobile : 4, xs : 2 },
                        }}
                    >
                        <SnsButtons data={data} />
                    </Stack>
                    </>}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="center"
                    gap={4}
                    py={1}
                >
                    <DraftButton
                        component={Link}
                        to={`/company/${data.企業ID}`}
                        type="button"
                        label="企業詳細"
                    />
                    <DraftButton
                        component={Link}
                        to={`/message/${data.企業ID}`}
                        state={{
                            会社ロゴ : data.会社ロゴ,
                            会社名 : data.会社名 || data.企業名,
                            タイトル : title
                        }}
                        type="button"
                        label="メッセージ送信"
                    />
                </Stack>
                </>)}
            </Stack>
        </Block>
    )
}

export default CompanyInfo
