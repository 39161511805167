import { Box } from '@mui/material'


const TwoColumns = ({ children, breakpoint="md", ratio="1fr 1fr", gap=1.5 }) => {
    return (
        <Box
            display="grid"
            gap={gap}
            width="100%"
            sx={{
                gridTemplateColumns : { [breakpoint] : ratio, xs : "1fr" }
            }}
        >
            {children}
        </Box>
    )
}

export default TwoColumns
