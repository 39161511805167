import { useSetAtom } from 'jotai'
import { searchPageAtom, searchTriggerAtom } from 'store/commonAtoms'
import { BlueContainedButton } from './styledButtons'


const SearchButton = ({ sx }) => {
    const setPage = useSetAtom(searchPageAtom)
    const setTrigger = useSetAtom(searchTriggerAtom)

    const handleClick =  () => {
        setTrigger(true)
        setPage(1)
        window.scrollTo({
            top : 0,
            behavior : 'smooth',
        })
    }

    return (
        <BlueContainedButton
            label="検索"
            onClick={handleClick}
            sx={sx}
        />
    )
}

export default SearchButton
