import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { styled } from '@mui/material/styles'
import { blue, grey } from '@mui/material/colors'

export const NodecorationLink = styled(Link)({
    color : grey[700],
    textDecoration : 'none',
    '&:focus, &:hover, &:visited, &:active' : {
        color : grey[700],
        textDecoration : 'none',
    },
})

export const UnderlineLink = styled(Link)({
    textDecoration : 'none',
    color : 'inherit',
    '&:hover' : {
        textDecoration : 'underline',
    }
})

export const BlueUnderlineLink = styled(UnderlineLink)({
    color : blue[400],
})

export const WebsiteLink = ({ url }) => (
    <MuiLink
        href={url}
        target="_blank"
        sx={{
            textDecoration: "none",
            "&:hover": { textDecoration: "underline", },
        }}
        color="inherit"
    >
        {url}
    </MuiLink>
)

