import { Link } from 'react-router-dom'
import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material'
import MuiLink from '@mui/material/Link'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import { styled } from '@mui/material/styles'
import MenuButton from './MenuButton'
import HomeButton from './HomeButton'
import ResearchButton from './ResearchButton'
import MailboxButton from './MailboxButton'
import logo from 'img/menu_logo_media.png'
import tel from 'img/menu_01_media.png'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

const MenuBar = () => {

    return (<>
        <AppBar
            sx={{
                bgcolor : '#fff',
                alignItems : 'center',
                borderBottom : 1,
                borderColor : 'grey.300',
                px : 1,
                boxShadow : '0px 0px 5px rgb(0 0 0 / 30%)',
            }}
        >
            <Toolbar
                sx={{
                    width : "100%",
                    maxWidth : 1212,
                    p : { md : 0, xs : 1 },
                }}
            >
                <HomeButton />
                <MenuButton />
                <Stack
                    direction="row"
                    gap={1.5}
                    pl={2}
                    width="100%"
                    sx={{ justifyContent : { sm : "flex-start", xs : "center" } }}
                >
                    <ResearchButton />
                    <MailboxButton />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    <MuiLink
                        href="tel:0354110088"
                        sx={{
                            pt : 0.75,
                            display : { md : "block", xs : "none" },
                        }}
                    >
                        <Box
                            component="img"
                            src={tel}
                        />
                    </MuiLink>
                    <Button
                        component={Link}
                        to="message/support"
                        sx={{
                            p : 0,
                            display : { md : "block", xs : "none" },
                        }}
                    >
                        <ContactMailIcon
                            sx={{ color : 'grey.600', fontSize : 46 }}
                        />
                    </Button>
                    <Box
                        component="img"
                        src={logo}
                        ml={0.5}
                        pt={0.5}
                    />
                </Stack>
            </Toolbar>
        </AppBar>
        <Offset />
    </>)
}

export default MenuBar
