import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { H5Overflow } from 'components/styledFonts'
import { EditButton } from 'components/styledButtons'
import S3Image from 'components/S3Image'


const InquiryItem = ({ data, link }) => {

    return (
        <Stack
            gap={1.5}
            px={1.5}
            alignItems="center"
        >
            {data &&
            <Stack
                height={100}
            >
                <S3Image url={data.メインイメージ} />
            </Stack>
            }
            <H5Overflow>{data.タイトル}</H5Overflow>
            <Stack
                pb={1.5}
            >
                <EditButton
                    component={Link}
                    to={data.to}
                >
                    {data.カテゴリ}
                </EditButton>
            </Stack>
        </Stack>
    )
}

export default InquiryItem

