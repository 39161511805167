import { Box, CircularProgress, Stack } from '@mui/material'
import { H2 } from 'components/styledFonts'

const Block = ({ children, sx, isLoading, ...props }) => {
    return (
        <Box
            bgcolor="#fff"
            border={1}
            sx={{
                pt : 2,
                pb : 2.5,
                pl : 2,
                pr : 2.5,
                flexGrow : 1,
                borderColor : 'grey.400',
                borderRadius : 1,
                ...sx,
            }}
            {...props}
        >
            {isLoading ?
            <LoadingProgress />
            :
            <>{children}</>
            }
        </Box>
    )
}

export const TitledBlock = ({ title, children, sx, ...props }) => {
    return (
        <Block
            sx={sx}
            {...props}
        >
            <Stack
                gap={2}
            >
                <H2>{title}</H2>
                {children}
            </Stack>
        </Block>
    )
}

export const LoadingProgress = ({ minHeight=300 }) => (
    <Stack
        justifyContent="center"
        alignItems="center"
        minHeight={minHeight}
    >
        <CircularProgress
            color="grey400"
        />
    </Stack>
)

export default Block
