import { Box, Stack } from '@mui/material'
import { Body } from 'components/styledFonts'


const ArticleImage = ({ src, caption }) => {
    return (
        <Stack
            alignItems="center"
            width="100%"
            gap={1.5}
        >
            <Stack
                maxWidth={600}
                alignItems="center"
            >
                <Box
                    component="img"
                    maxWidth="100%"
                    maxHeight={400}
                    src={src}
                    sx={{
                        objectFit : "contain",
                    }}
                />
            </Stack>
            {caption &&
            <Body>{caption}</Body>
            }
        </Stack>
    )
}

export default ArticleImage

