import { Button } from '@mui/material'
import { useLoginuserMutaion } from 'hooks/mutation/loginuser'
import { useMessagesMutaion } from 'hooks/mutation/message'
import { useSupportMessages } from 'hooks/message'
import { useLoginUser } from 'hooks/loginuser'

const SubButton = () => {
    const { mutate : mutateLoginuser } = useLoginuserMutaion()
    const { mutate : mutateMessage } = useMessagesMutaion()
    const { data : support } = useSupportMessages()
    const { data : loginUser } = useLoginUser()
    
    const handleSubButtonClick = () => {
        mutateLoginuser({
            お知らせメール配信停止 : !loginUser.お知らせメール配信停止,
            id : loginUser.$id,
            revision : loginUser.$revision
        })
        mutateMessage({
            登録 : !support[0].日時,
            メッセージ区分 : "メディア・ネタもとサポート間",
            企業ID : "",
            媒体名 : loginUser.担当媒体_1,
            媒体担当者ID : loginUser.媒体担当者ID,
            姓 : loginUser.姓,
            名 : loginUser.名,
            回答テーブル : support[0].回答テーブル.map(i => ({ "id": i.id })),
            送信者 : "媒体担当者",
            回答内容 : loginUser.お知らせメール配信停止 ? "<div>メルマガ「本日のネタもと」配信開始をお願いします。</div>" : "<div>メルマガ「本日のネタもと」配信停止をお願いします。</div>",
            id : support[0].$id,
            revision : support[0].$revision
        })
    }

    return (
        loginUser && 
        <Button
            variant="outlined"
            color='inherit'
            sx={{ bgcolor : '#ffffff' }}
            onClick={handleSubButtonClick}
        >
            {loginUser.お知らせメール配信停止?
            "メルマガ「本日のネタもと」配信開始"
            :
            "メルマガ「本日のネタもと」配信停止"
            }
        </Button>
    )
}

export default SubButton