import { Box } from '@mui/material'

const viewer = "https://s3-ap-northeast-1.amazonaws.com/netamoto/static/pdfjs/web/viewer.html?file="

const PdfViewer = ({ url }) => {

    return (
        <Box
            sx={{
                height: { sm: 1000, xs: 500 },
            }}
        >
            <Box
                component="iframe"
                src={`${viewer}${url}#page=1&pagemode=none`}
                type="application/pdf"
                height="100%"
                width="100%"
            />
        </Box>
    )
}

export default PdfViewer
