import { atom } from 'jotai'

// スピナー、フラッシュメッセージ
export const spinnerAtom = atom(false)
export const messageAtom = atom(null)


// 情報検索画面の検索条件
export const searchCondInit = {
    キーワード : '',
    地域 : '',
    上場 : false,
    業種 : '',
    カテゴリー : [],
}
export const searchCondAtom = atom(searchCondInit)

// 情報検索画面のページ
export const searchPageAtom = atom(1)

// 情報検索画面の検索トリガー
export const searchTriggerAtom = atom(true)

// 閲覧履歴の記事読み込み完了トリガー
export const loadedAtom = atom(false)

// 閲覧履歴の企業情報読み込み完了トリガー
export const loadedComAtom = atom(false)
