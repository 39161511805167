import { createBrowserRouter } from 'react-router-dom'
import BrowseHistoryList from 'pages/browsehistory/BrowseHistoryList'
import CompanyDetail from 'pages/company/CompanyDetail'
import CompanyPosts from 'pages/company/CompanyPosts'
import EntryDetail from 'pages/research/EntryDetail'
import ErrorBoundary from 'pages/base/ErrorBoundary'
import FeedbackDetail from 'pages/feedback/FeedbackDetail'
import FeedbackInquiryDetail from 'pages/feedback/FeedbackInquiryDetail'
import FeedbackList from 'pages/feedback/FeedbackList'
import FeedbackNewsLetterDetail from 'pages/feedback/FeedbackNewsLetterDetail'
import FeedbackPressReleaseDetail from 'pages/feedback/FeedbackPressReleaseDetail'
import InquiryDetail from 'pages/post/InquiryDetail'
import MailBox from 'pages/mailbox/MailBoxMain'
import MyPage from 'pages/mypage/MyPage'
import NewsLetterDetail from 'pages/post/NewsLetterDetail'
import PageBase from 'pages/base/PageBase'
import PressReleaseDetail from 'pages/post/PressReleaseDetail'
import PrintBase from 'pages/print/PrintBase'
import PrintEntry from 'pages/print/PrintEntry'
import PrintInquiry from 'pages/print/PrintInquiry'
import PrintNewsLetter from 'pages/print/PrintNewsLetter'
import PrintPressRelease from 'pages/print/PrintPressRelease'
import ResearchDetail from 'pages/research/ResearchDetail'
import ResearchManagement from 'pages/research/ResearchManagement'
import SearchBase from 'pages/search/SearchBase'
import SearchCompany from 'pages/search/SearchCompany'
import SearchInquiry from 'pages/search/SearchInquiry'
import SearchNewsLetter from 'pages/search/SearchNewsLetter'
import SearchPressRelease from 'pages/search/SearchPressRelease'
import Top from 'pages/top/Top'


const router = createBrowserRouter([
    // 印刷画面
    {
        path : 'print/:clientId',
        element : <PrintBase />,
        children : [
            {
                path : 'pressrelease/:no',
                element : <PrintPressRelease />
            },
            {
                path : 'newsletter/:no',
                element : <PrintNewsLetter />
            },
            {
                path : 'inquiry/:no',
                element : <PrintInquiry />
            },
            {
                path : 'entry/:no',
                element : <PrintEntry />
            },
        ]
    },

    // 通常画面
    {
        path : '/',
        element : <PageBase />,
        errorElement : <ErrorBoundary />,
        children : [
            {
                index : true,
                element : <Top />,
            },

            // フィードバック
            {
                path : 'feedback',
                children : [
                    {
                        path : 'list',
                        element : <FeedbackList />,
                    },
                    {
                        path : ':type/:fbno',
                        element : <FeedbackDetail />,
                        children : [
                            {
                                path : 'pressrelease/:no',
                                element : <FeedbackPressReleaseDetail />,
                            },
                            {
                                path : 'newsletter/:no',
                                element : <FeedbackNewsLetterDetail />,
                            },
                            {
                                path : ':category/:no',
                                element : <FeedbackInquiryDetail />,
                            },
                        ]
                    },
                ],
            },

            // リサーチ
            {
                path : 'research',
                element : <ResearchManagement />,
            },
            {
                path : 'research/detail/:no',
                element : <ResearchDetail />,
            },
            {
                path : 'research/entry/:requestNo/:clientId/:entryNo',
                element : <EntryDetail />,
            },

            // 情報検索
            {
                path : 'search',
                element : <SearchBase />,
                children : [
                    {
                        path : '',
                        element : <SearchCompany />,
                    },
                    {
                        path : 'company',
                        element : <SearchCompany />,
                    },
                    {
                        path : 'pressrelease',
                        element : <SearchPressRelease />,
                    },
                    {
                        path : 'newsletter',
                        element : <SearchNewsLetter />,
                    },
                    {
                        path : 'inquiry',
                        element : <SearchInquiry />,
                    },
                ],
            },

            // 企業情報
            {
                path : 'company',
                children : [
                    {
                        path : ':id',
                        element : <CompanyDetail />
                    },
                    {
                        path : ':id/postlist',
                        element : <CompanyPosts />
                    },
                ],
            },

            // 企業探訪
            {
                path : 'inquiry',
                children : [
                    {
                        path : ':clientId/:category/:no',
                        element : <InquiryDetail />
                    },
                ],
            },

            // 報道資料
            {
                path : 'post',
                children : [
                    {
                        path : 'pressrelease/:clientId/:no',
                        element : <PressReleaseDetail />
                    },
                    {
                        path : 'newsletter/:clientId/:no',
                        element : <NewsLetterDetail />
                    },
                    {
                        path : `inquiry/:clientId/:no`,
                        element : <InquiryDetail />
                    },
                ],
            },

            // 閲覧履歴一覧
            {
                path : 'browsehistorylist',
                element : <BrowseHistoryList />,
            },


            // メッセージボックス
            {
                path : 'message',
                element : <MailBox />,
            },
            {
                path : 'message/:id',
                element : <MailBox />,
            },

            // マイページ
            {
                path : 'mypage',
                element : <MyPage />,
            },

        ],
    },
])

export default router
