import { useParams } from 'react-router-dom'
import Inquiry from 'components/Inquiry'
import { useInquiryByNo } from 'hooks/post'


const FeedbackInquiryDetail = () => {
    const { no } = useParams()
    const { data, isLoading } = useInquiryByNo(no)

    return (<>
        <Inquiry
            data={data}
            feedback
            isLoading={isLoading}
        />
    </>)
}

export default FeedbackInquiryDetail
