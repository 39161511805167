import { format } from 'date-fns'
import { Stack } from '@mui/material'
import ComponentBase from './ComponentBase'
import { useNewResearchEntryList } from 'hooks/research'
import { useCompanyById } from 'hooks/company'
import { BodyLH1, H5Overflow } from 'components/styledFonts'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'

const path = (d) => {
    return `/research/entry/${d.リクエストNo}/${d.エントリーNo}`
}

const UnreadResearchItem = ({ data }) => {
    const { data : company } = useCompanyById(data.クライアント企業ID)

    return (
        <Stack
            direction="row"
            alignItems="flex-start"
            gap={1.5}
        >
            <Thumbnail
                src={data.メイン画像}
                to={path(data)}
            />
            <Stack
                gap={0.75}
            >
                <BodyLH1>{format(data.更新日_データ連携用, 'yyyy/MM/dd HH:mm')}</BodyLH1>
                <H5Overflow>
                    <UnderlineLink
                        to={path(data)}
                    >
                        {data.タイトル}
                    </UnderlineLink>
                </H5Overflow>
                <BodyLH1>{company?.会社名}</BodyLH1>
            </Stack>
        </Stack>
    )
}

const UnreadResearch = () => {
    const data = useNewResearchEntryList()

    return (
        <ComponentBase
            title="未読のリサーチ結果"
            count={data?.length}
            buttons={[{ label : 'リサーチ管理', to : 'research' }]}
        >
            {(data && data.length > 0) &&
            <Stack
                gap={2}
            >
                {data.slice(0, 3).map((d, i) => (
                <UnreadResearchItem data={d} key={i} />
                ))}
            </Stack>
            }
        </ComponentBase>
    )
}

export default UnreadResearch
