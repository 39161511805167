import { Auth } from 'aws-amplify'
import { S3Client } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import awsconfig from 'aws-exports'


export const createS3Client = async () => {

    // トークン取得（更新）
    const cs = await Auth.currentSession()

    // S3Client生成
    const cognitoId = cs.idToken.payload.iss.replace('https://', '')
    return new S3Client({
        region : awsconfig.aws_project_region,
        credentials : fromCognitoIdentityPool({
            clientConfig : { region : awsconfig.aws_project_region },
            identityPoolId : awsconfig.aws_cognito_identity_pool_id,
            logins : {
                [cognitoId] : cs.idToken.jwtToken,
            },
        }),
    })
}

