import { Stack } from '@mui/material'
import TwoColumns from 'components/TwoColumns'
import FreeResarch from './FreeResarch'
import UnreadResearch from './UnreadResearch'
import NewMail from './NewMail'
import NewEnterprise from './NewEnterprise'
import NewFeedback from './NewFeedback'
import BrowseHistory from './BrowseHistory'
import { useLoginUser } from 'hooks/loginuser'

const Top = () => {
    const { data } = useLoginUser()
    const feedback = data?.フィードバック機能

    return (
        <Stack
            gap={1.5}
        >
            <TwoColumns
                ratio="4fr 3fr"
            >
                <FreeResarch />
                <Stack
                    gap={1.5}
                >
                    <UnreadResearch />
                    <NewMail />
                </Stack>
            </TwoColumns>
            {feedback &&
            <TwoColumns>
                <NewEnterprise />
                <NewFeedback />
            </TwoColumns>
            }
            {!feedback &&
                <NewEnterprise />
            }
            <BrowseHistory />
        </Stack>
    )
}

export default Top
