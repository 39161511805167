import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'

export const useResearchMutaion = () => {

    return useMyMutation({
        mutationFn: async (param) => {
            const ret = await API.post(apiName, '/items/postResearch', { body: param })
            return ret
        },
        queryKey: ['research'],
    })
}