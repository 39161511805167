import { Stack } from '@mui/material'
import { H1, H2 } from 'components/styledFonts'
import Block from 'components/Block'
import MyPageBase from './MyPageBase'
import MyPageMail from './MyPageMail'
import MyPagePassword from './MyPagePassword'

const MyPage = () => {
    return (
        <Stack
            gap={2}
        >
            <H1>マイページ</H1>
            <Block>
                <Stack
                    gap={2}
                >
                    <H2>会員登録情報</H2>
                    <MyPageBase />
                    <MyPageMail />
                    <MyPagePassword />
                </Stack>
            </Block>
        </Stack>
    )
}

export default MyPage

