import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material' 
import { useLoginUser } from 'hooks/loginuser'
import { Body, H5 } from 'components/styledFonts'
import { LabeledTextField } from 'components/Inputs'
import { BlueContainedButton } from 'components/styledButtons'
import Settings from './Settings'
import { Auth } from 'aws-amplify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAtom } from 'jotai'
import { messageAtom } from 'store/commonAtoms'
import { message } from 'config/message'
import { useLoginuserMutaion } from 'hooks/mutation/loginuser'

const defaultValues = {
    メールアドレス : '',
    メールアドレス確認用 : '',
    確認コード : '',
}

const schema = yup.object().shape({
    メールアドレス : yup
        .string()
        .required(message.REQUIRED)
        .matches(/^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
            , { message : message.MAILADDRESS_VALIDATION })
    ,
    メールアドレス確認用 : yup
        .string()
        .required(message.REQUIRED)
        .oneOf([yup.ref('メールアドレス'), null], message.MAILADDRESS_MATCH_FAILED)
    ,
    確認コード : yup
        .string()
        .required(message.REQUIRED)
    ,
})

const MyPageMail = () => {
    const { control, handleSubmit, trigger, getValues, reset } = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })
    const { data } = useLoginUser()
    const { mutate } = useLoginuserMutaion()
    const [, setMessage] = useAtom(messageAtom)
    const [edit, setEdit] = useState(false)

    const handleConfirmCodeClick = async () => {
        const result = await trigger(["メールアドレス", "メールアドレス確認用"])
        if (result) {
            try {
                const user = await Auth.currentAuthenticatedUser()
                await Auth.updateUserAttributes(user, { email : getValues("メールアドレス") })
            } catch (e) {
                const msg = { type : 'error', text : message.ERR_REGISTRATION_FAILED }
                setMessage(msg)
            }
        }
        setEdit(true)
    }

    const onSubmit = async (d) => {
        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', d.確認コード)
            mutate({
                ...data,
                登録メールアドレス : d.メールアドレス,
                id : data.$id,
                revision : data.$revision,
                mypage : true
            })
            reset()
        } catch (e) {
            if (e.code === "AliasExistsException") {
                const msg = { type : 'error', text : message.ERR_MAILADDRESS_EXISTS }
                setMessage(msg)
            } else {
                const msg = { type : 'error', text : message.ERR_REGISTRATION_FAILED }
                setMessage(msg)
            }
            reset()
        }
        setEdit(false)
    }

    if (!data) return null

    return (
        <Settings
            category="登録メールアドレス"
            currents={[
                { value : <Body>{data.登録メールアドレス}</Body> },
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <Stack>
                        <H5>現在のメールアドレス</H5>
                        <Body>{data.登録メールアドレス}</Body>
                    </Stack>
                    <LabeledTextField name="メールアドレス" label="新しいメールアドレス" control={control} />
                    <LabeledTextField name="メールアドレス確認用" label="新しいメールアドレス（確認用）" control={control} />
                    <BlueContainedButton label="確認コード発行" onClick={handleConfirmCodeClick} />
                    <LabeledTextField name="確認コード" label="確認コード" control={control} />
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
        />
    )
}

export default MyPageMail
