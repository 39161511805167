import { format } from 'date-fns'
import { styled } from '@mui/material/styles'
import { grey, lightBlue } from '@mui/material/colors'
import { Stack } from '@mui/material'
import { TabUnstyled } from '@mui/base'
import { H5 } from 'components/styledFonts'
import Badge from 'components/Badge'
import S3Image from 'components/S3Image'

const Tab = styled(TabUnstyled)({
    display : 'block',
    width : '100%',
    height : 90,
    textAlign : "left",
    padding : '12px',
    color : grey[700],
    backgroundColor : '#fff',
    border : '1px solid #ccc',
    borderRadius : 4,
    position : 'relative',

    '&.Mui-selected' : {
        border : `3px solid ${lightBlue[500]}`,

        '&::before' : {
            content : '""',
            position : 'absolute',
            top : '20%',
            right : -59,
            border : '20px solid transparent',
            borderLeft : '46px solid #fff',
            zIndex : 1000,
        },
        '&::after' : {
            content : '""',
            position : 'absolute',
            top : '20%',
            right : -66,
            border : '20px solid transparent',
            borderLeft : `46px solid ${lightBlue[500]}`,
            zIndex : 999,
        }
    },
})

const ContactTab = ({ count, value, date, logo, name, onClick }) => {
    return (
        <Badge count={count} showCount>
            <Tab value={value} onClick={onClick}>
                <Stack
                    height="100%"
                    gap={1.5}
                >
                    {date && <H5>{format(date, 'yyyy/MM/dd HH:mm')}</H5>}
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{ wordBreak : "break-all" }}
                    >
                        <S3Image width={120} height={25} url={logo} />
                        <H5>{name}</H5>
                    </Stack>
                </Stack>
            </Tab>
        </Badge>
    )
}

export default ContactTab