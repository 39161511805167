import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Box } from '@mui/material' 
import { useLoginUser } from 'hooks/loginuser'
import { H5, Body, Body2 } from 'components/styledFonts'
import { LabeledTextField, TextField, CheckboxGroup, Required } from 'components/Inputs'
import Settings from './Settings'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { message } from 'config/message'
import { useLoginuserMutaion } from 'hooks/mutation/loginuser'
import { useNavigate } from 'react-router-dom'

const defaultValues = {
    所属企業 : '',
    担当媒体_1 : '',
    担当媒体_2 : '',
    担当媒体_3 : '',
    姓 : '',
    名 : '',
    電話番号 : '',
    お知らせメール配信停止 : true
}

const schema = yup.object().shape({
    所属企業 : yup
        .string()
        .required(message.REQUIRED)
    ,
    担当媒体_1 : yup
        .string()
        .required(message.REQUIRED)
    ,
    姓 : yup
        .string()
        .required(message.REQUIRED)
    ,
    名 : yup
        .string()
        .required(message.REQUIRED)
    ,
    電話番号 : yup
        .string()
        .required(message.REQUIRED)
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
            , { message : message.PHONENUMBER_VALIDATION } )
    ,
})

const LV = ({ label, required, width="auto", sx, children }) => {
    return (
        <Stack
            gap={0.5}
            width={width}
        >
            <H5>{label}{required ? <Required /> : ''}</H5>
            <Box sx={sx}>
                {children}
            </Box>
        </Stack>
    )
}

const MyPageBase = () => {
    const { control, setValue, handleSubmit } = useForm({ defaultValues : defaultValues, resolver: yupResolver(schema) })
    const { data } = useLoginUser()
    const { mutate } = useLoginuserMutaion()
    const navi = useNavigate()
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if (!data) return

        Object.keys(defaultValues).forEach(n => {
            setValue(n, data[n])
        })
        // 画面とkintoneで意味合いが逆なので反転させて設定。
        setValue('お知らせメール配信停止', !data.お知らせメール配信停止)

    }, [data, setValue])

    const onSubmit = async (d) => {
        mutate({ 
            ...d,
            登録メールアドレス : data.登録メールアドレス,
            お知らせメール配信停止 : !d.お知らせメール配信停止,
            id : data.$id,
            revision : data.$revision,
            mypage : true
        })
        navi('/mypage')
        setEdit(false)
    }

    if (!data) return null

    return (
        <Settings
            category="基本情報"
            currents={[
                { label : '所属企業', value : data.所属企業 },
                { label : '担当媒体①', value : data.担当媒体_1},
                { label : '担当媒体②', value : data.担当媒体_2},
                { label : '担当媒体③', value : data.担当媒体_3},
                { label : '氏名', value : `${data.姓} ${data.名}`},  
                { label : '電話番号', value : data.電話番号 },
                { label : '通知設定', value : `プレスリリースやネタの新着通知を${data.お知らせメール配信停止 ? '受け取らない' : '受け取る'}` },
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <LabeledTextField name="所属企業" control={control} required />
                    <LabeledTextField label="担当媒体①" name="担当媒体_1" control={control} required/>
                    <LabeledTextField label="担当媒体②" name="担当媒体_2" control={control} />
                    <Stack>
                        <LabeledTextField label="担当媒体③" name="担当媒体_3" control={control} /> 
                        <Body2>※複数媒体を担当している方は、複数記入してください</Body2>
                    </Stack>
                    <LV
                            label="名前（姓名）"
                            required
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                            >
                                <Body>姓</Body>
                                <TextField
                                    name="姓"
                                    control={control}
                                    sx={{ maxWidth : 200 }}
                                />
                                <Box width={15} />
                                <Body>名</Body>
                                <TextField
                                    name="名"
                                    control={control}
                                    sx={{ maxWidth : 200 }}
                                />
                            </Stack>
                        </LV>
                    <LabeledTextField name="電話番号" control={control} required/>
                    <Stack>
                        <H5>通知設定</H5>
                        <Stack
                            sx={{
                                paddingLeft: 1
                            }}
                        >
                            <CheckboxGroup
                                name="お知らせメール配信停止"
                                control={control}
                                list={[{ value : true, label : 'プレスリリースやネタの新着通知を受け取る' }]}
                                row
                                boolean
                            />
                        </Stack>
                        <Body2>※新着のお知らせは1日1通です。</Body2>
                    </Stack>
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
        />
    )
}

export default MyPageBase
