import { Outlet, ScrollRestoration } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { useAuthorization } from 'hooks/authorization'
import { useRegisterHistory } from 'hooks/browsehistory'
import FreeResearchModal from 'modals/FreeResearchModal'
import MenuBar from './MenuBar'
import Footer from './Footer'
import Spinner from './Spinner'
import FlashMessage from './FlashMessage'


const PageBase = () => {
    useAuthorization()
    useRegisterHistory()

    return (<>
        <MenuBar />
        <Stack
            alignItems="center"
            sx={{
                bgcolor : 'grey.100',
                minHeight : { sm : 'calc(100vh - 160px)', xs : 'calc(100vh - 196px)' },
                pt : 4,
                px : 1,
            }}
        >
            <Box
                sx={{
                    maxWidth : {lg : 1212},
                    width : "100%",
                    pb : 4,
                }}
            >
                <Outlet />
            </Box>
        </Stack>
        <Footer />
        <Spinner />
        <FlashMessage />
        <ScrollRestoration />
        <FreeResearchModal />
    </>)
}

export default PageBase
