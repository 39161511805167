import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useNavigate } from 'react-router-dom'

export const useFeedbackMutaion = () => {
    const navi = useNavigate()

    return useMyMutation({
        mutationFn: async (param) => {
            const ret = await API.post(apiName, '/items/postFeedback', { body: param })
            return ret
        },
        queryKey: ['feedback'],
        onSettledFn: (ret) => {
            if (/^OK_SEND/.test(ret.result)) {
                navi('/feedback/list')
            }
        }
    })
}