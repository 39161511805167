import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, TableCell } from '@mui/material'
import { blue, blueGrey, grey } from '@mui/material/colors'

// 各種フォントの設定
// デフォルトのcolorは、grey.700


// 太字
export const Bold = styled(Box)({
    fontWeight : 700,
})

// ページタイトル
export const H1 = styled(Bold)({
    fontSize : 22,
    color : grey[800],
})

// ブロックタイトル
export const H2 = styled(Bold)({
    fontSize : 20,
})

// ブロックサブタイトル
export const H3 = styled(Bold)({
    fontSize : 18,
})

// ブロックサブタイトル罫線付き
export const H3Underline = styled(H3)({
    width : '100%',
    lineHeight : '26px',
    borderBottom : `2px solid ${grey[900]}`
})

// 小見出し
export const H4 = styled(Bold)({
    fontSize : 16,
})
export const H5 = styled(Bold)({
    fontSize : 14,
    lineHeight : 1.3,
})
export const H5Overflow = styled(H5)({
    overflow : "hidden",
    textOverflow : "ellipsis",
    display : "-webkit-box",
    WebkitBoxOrient : "vertical",
    WebkitLineClamp : 3,
    lineBreak : "anywhere",
})

// ブロックリード文
export const Lead = styled(Box)({
    fontSize : 16,
    color : grey[800]
})

// 本文
export const Body = styled(Box)({
    fontSize : 14,
})

// 本文（line-height 1）
export const BodyLH1 = styled(Body)({
    lineHeight : 1,
})

// 本文（小）
export const Body2 = styled(Box)({
    fontSize : 13,
})
export const SmallBody = styled(Body)({
    fontSize : 12,
})

// テーブルヘッダ
export const TH = styled(TableCell)({
    color : blueGrey[400],
})

// リンク
export const StyledLink = styled(Link)({
    textDecoration : 'none',
    color : blue[400],
    '&:hover' : {
        textDecoration : 'underline',
    },
    '&:visited' : {
        color : blue[400]
    },
})

