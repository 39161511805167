import React from 'react'
import { Link, useHref } from 'react-router-dom'
import { MenuBarIconButton } from 'components/styledButtons'
import HomeIcon from '@mui/icons-material/Home'

const HomeButton = () => {
    const href = useHref()

    return (
        <MenuBarIconButton
            onClick={e => true}
            component={Link}
            to="/"
            disabled={href === '/'}
        >
            <HomeIcon />
        </MenuBarIconButton>
    )
}

export default HomeButton
