import { useParams } from 'react-router-dom'
import ResearchList from './ResearchList'
import { useRecentlyResearches } from 'hooks/research'


const RecentlyResearches = ({ ...props }) => {
    const { no } = useParams()
    const { data } = useRecentlyResearches(no)

    return (
        <ResearchList
            title="最近の無料リサーチ"
            data={data}
            recently
            {...props}
        />
    )
}

export default RecentlyResearches

