import { styled } from '@mui/material/styles'
import { grey, lightBlue } from '@mui/material/colors'
import { Stack } from '@mui/material'
import { TabUnstyled, } from '@mui/base'
import { H5 } from 'components/styledFonts'
import Badge from 'components/Badge'


const Tab = styled(TabUnstyled)({
    display : 'block',
    width : '100%',
    padding : '8px 12px',
    color : grey[700],
    backgroundColor : grey[300],
    border : `1px solid ${grey[400]}`,
    borderRadius : 4,
    position : 'relative',

    '&.Mui-selected' : {
        border : `3px solid ${lightBlue[500]}`,

        '&::before' : {
            content : '""',
            position : 'absolute',
            top : '15%',
            right : -50,
            border : '12px solid transparent',
            borderLeft : `46px solid ${grey[300]}`,
            zIndex : 1000,
        },
        '&::after' : {
            content : '""',
            position : 'absolute',
            top : '15%',
            right : -60,
            border : '12px solid transparent',
            borderLeft : `46px solid ${lightBlue[500]}`,
            zIndex : 999,
        }
    },
})


const SupportTab = ({ count, value, onClick }) => {

    return (
        <Badge count={count}>
            <Tab value={value} onClick={onClick}>
                <Stack
                    alignItems="center"
                    gap={0.2}
                >
                    <H5>サポートデスクへのお問い合わせ</H5>
                </Stack>
            </Tab>
        </Badge>
    )
}

export default SupportTab
