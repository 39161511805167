import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { MenuBarIconButton } from 'components/styledButtons'
import Badge from 'components/Badge'
import MenuList from './MenuList'
import { useNewFeedbackCount } from 'hooks/feedback'
import { useNewResearchEntryCount } from 'hooks/research'

const MenuButton = () => {
    const { data : fcnt } = useNewFeedbackCount()
    const rcnt = useNewResearchEntryCount()
    const [ menuEl, setMenuEl ] = useState(null)

    return (<>
        <Badge
            count={(fcnt || 0) + (rcnt || 0)}
            menuButton
        >
            <MenuBarIconButton
                onClick={e => setMenuEl(e.currentTarget)}
            >
                <MenuIcon />
            </MenuBarIconButton>
        </Badge>
        <MenuList
            menuEl={menuEl}
            setMenuEl={setMenuEl}
        />
    </>)
}

export default MenuButton
