import { Box } from '@mui/material'


const S3Image = ({ url, width="100%", height="100%", objectFit="contain" }) => {
    return (<>
        {objectFit === "contain" ?
        <Box
            component="img"
            maxWidth={width}
            maxHeight={height}
            src={url}
            sx={{
                objectFit : objectFit,
            }}
        />
        :
        <Box
            component="img"
            width={width}
            height={height}
            src={url}
            sx={{
                objectFit : objectFit,
            }}
        />
        }
    </>)
}

export default S3Image
