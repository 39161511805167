import { format } from 'date-fns'
import { TitledBlock } from 'components/Block'
import PaginationList from 'components/PaginationList'
import CommonList from './CommonList'
import { useCheckingFeedbacks } from 'hooks/feedback'
import { Body } from 'components/styledFonts'

// TODO : プレスリリース以外の対応
const CheckingFeedbacks = () => {
    const { data } = useCheckingFeedbacks()

    return (
        <TitledBlock
            title="ネタもと確認中のフィードバック"
        >
            {data?.length > 0 ?
            <PaginationList
                max={3}
                gap={3}
                data={data.map((d, i) => (
                    <CommonList
                        key={i}
                        title={d.タイトル}
                        mainPict={d.サムネイル}
                        client={d.企業名}
                        date={`投稿日：${format(d.メディア投稿日時, 'yyyy/MM/dd HH:mm')}`}
                        to={`/feedback/history/${d.フィードバックNo}/${d.path}`}
                    />
                ))}
            />
            :
            <Body>ネタもと確認中のフィードバックはありません</Body>
            }
        </TitledBlock>
    )
}

export default CheckingFeedbacks
