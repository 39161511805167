import { useSearch } from 'hooks/search'
import SearchList from './SearchList'
import ListItem from './ListItem'


const SearchNewsLetter = () => {
    const query = useSearch('NewsLetters')

    return (
        <SearchList
            query={query}
            dispRow={d => <ListItem type="その他" data={d} />}
        />
    )
}

export default SearchNewsLetter
