import { useSearch } from 'hooks/search'
import SearchList from './SearchList'
import ListItem from './ListItem'


const SearchPressRelease = () => {
    const query = useSearch('PressReleases')

    return (
        <SearchList
            query={query}
            dispRow={d => <ListItem type="プレスリリース" data={d} />}
        />
    )
}

export default SearchPressRelease
