// メッセージ定義
export const message = {
    //------------------------------------------------------------
    // ヴァリデーションメッセージ
    //------------------------------------------------------------
    REQUIRED : "入力してください",
    SELECTIONREQUIERED : "選択してください",
    EITHERONEREQUIRED : "どちらか一つを入力してください",
    MAX : "文字以内で入力してください",
    PHONENUMBER_VALIDATION : "半角数字もしくはハイフンで入力してください。",
    PASSWORD_VALIDATION : 'パスワードは半角6文字以上で設定してください。',
    PASSWORD_MATCH_FAILED : '異なるパスワードが入力されています。',
    MAILADDRESS_VALIDATION : 'メールアドレス形式で入力してください。',
    MAILADDRESS_MATCH_FAILED : '異なるメールアドレスが入力されています。',

    //------------------------------------------------------------
    // 画面固有メッセージ
    //------------------------------------------------------------
    // エラー画面
    403: 'このページへのアクセス権限がありません。',
    404: 'ページが見つかりません。',
    ERR_DEFAULT : '障害が発生しました。管理者にお問い合わせください。',

    // メッセージボックス
    MESSAGE_FORM : (companyName, title) => `${companyName}”ご担当者様”\n【${title}】の資料を見てご連絡しました。`,

    //------------------------------------------------------------
    // API実行結果メッセージ
    //------------------------------------------------------------
    // 成功
    OK_POST_SUCCESS : '登録しました。',
    OK_TEMPORARY_SUCCESS : '一時保存しました。',
    OK_UPDATE_SUCCESS : '更新しました。',
    OK_SEND_SUCCESS : "送信しました",
    OK_CANCEL_SUCCESS : "取りやめました",

    // エラー
    ERR_MAILADDRESS_EXISTS : '該当のメールアドレスは既にアカウントが存在しているため作成できません。',
    ERR_REGISTRATION_FAILED : 'データの登録に失敗しました。管理者にお問い合わせください。',
    GAIA_CO02 : '既に変更されております。再読み込みをして再度実行して下さい。',
}