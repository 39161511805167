import { Stack } from '@mui/material'
import { Body, H2 } from 'components/styledFonts'
import { GreyLabel, BlueLabel, WhiteLabel } from 'components/styledLabels'
import Block from 'components/Block'
import { format } from 'date-fns'
import ArticleImage from 'components/ArticleImage'
import ResponsivePlayer from 'components/ResponsivePlayer'
import PdfViewer from 'components/PdfViewer'


const PressRelease = ({ data, print, feedback }) => {

    const nums = [1, 2, 3, 4].filter(n => data?.[`メイン画像${n}`] || data?.[`本文${n}`] || data?.[`画像${n}`] || (print ? false : data?.[`動画${n}`]))

    const sx = print ? { border : 0 } : {}
    console.log(data)

    return (
        <Block
            sx={{
                ...sx,
                minHeight : 700,
            }}
        >
            {data &&
            <Stack
                gap={2}
                pt={1}
                pb={3}
                sx={{ wordBreak : "break-word" }}
            >
                {feedback &&
                <H2>元の報道資料</H2>
                }
                <H2>{data.タイトル}</H2>
                <Stack
                    direction="row"
                    columnGap={1}
                    rowGap={1}
                    flexWrap="wrap"
                    pb={2}
                >
                    <BlueLabel>{data.種類}</BlueLabel>
                    {data.ジャンル.map((e, i) =>
                    <GreyLabel key={i}>{e}</GreyLabel>
                    )}
                    {data.カテゴリ &&
                    <GreyLabel>{data.カテゴリ}</GreyLabel>
                    }
                    {data.公開日 &&
                    <WhiteLabel>{`公開日：${format(data.公開日, 'yyyy/MM/dd HH:mm')}`}</WhiteLabel>
                    }
                </Stack>
                <Stack
                    gap={4}
                >
                    {data.リード文 &&
                    <Body whiteSpace="pre-wrap">{data.リード文}</Body>
                    }           
                    {nums.map(i => (
                    <Stack
                        key={i}
                        gap={4}
                    >
                        {data[`メイン画像${i}`] &&
                        <ArticleImage
                            src={data[`メイン画像${i}`]}
                            caption={data[`メイン画像${i}キャプション`]}
                        />
                        }
                        {(i === 1 && data.PDF入稿 && data.PDF) &&
                        <Stack>
                            <PdfViewer
                                url={data[`PDF`]}
                            />
                        </Stack>
                        }                            
                        {data[`本文${i}`] &&
                        <Body className="innerHTML"
                            dangerouslySetInnerHTML={{ __html: data[`本文${i}`] }}
                            whiteSpace="pre-wrap"
                        />
                        }
                        {/* todo check  */}
                        {(data[`動画${i}`] && !print) &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                url={data[`動画${i}`]}
                            />
                        </Stack>
                        }
                        {/* todo check  */}
                        {(data[`動画${i}URL`] && !print) &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                url={data[`動画${i}URL`]}
                            />
                        </Stack>
                        }
                        {data[`画像${i}`] &&
                        <ArticleImage
                            src={data[`画像${i}`]}
                            caption={data[`画像${i}キャプション`]}
                        />
                        }
                    </Stack>
                    ))}
                </Stack>
            </Stack>
            }
        </Block>
    )
}

export default PressRelease
