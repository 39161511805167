import { useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { apiName } from 'config/api'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getDynamo', {})
    return ret
}

export const useDynamo = () => {
    return useQuery(['dynamo'], getData)
}

export const useDynamoItem = (item) => {
    return useQuery(['dynamo'], getData, {
        select : (data) => {
            return data[item]
        },
    })
}