import { useParams } from 'react-router-dom'
import { Avatar, Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { H2, Body } from 'components/styledFonts'
import { SnsButtons } from 'components/styledButtons'
import { WebsiteLink } from 'components/links'
import Block from 'components/Block'
import { useCompanyById } from 'hooks/company'


const LV = ({ label, value, odd }) => (
    <Stack
        direction="row"
        alignItems="center"
        py={1.5}
        px={1}
        gap={1}
        borderBottom={`1px solid ${grey[300]}`}
        bgcolor={odd ? grey[50] : ''}
    >
        <Body
            sx={{
                width: { mobile: 200, xs: 120 },
                flexShrink: 0,
            }}
        >
            {label}
        </Body>
        <Body
            sx={{ wordBreak: "break-all" }}
        >
            {value}
        </Body>
    </Stack>
)


const Overview = () => {
    const { id } = useParams()
    const { data } = useCompanyById(id)

    const pr = [data?.広報担当者部署, data?.広報担当者役職, data?.広報担当者名].filter(p => p).join('　')

    return (
        <Block
            sx={{
                height: "100%"
            }}
            minHeight={600}
        >
            {data &&
            <Stack
                gap={2}
            >
                <H2>会社概要</H2>
                <Stack
                    px={1}
                >
                    <Box borderBottom={`2px solid ${grey[500]}`} />
                    <LV label="本社" value={data.住所} />
                    <LV label="代表" value={data.代表者} odd />
                    <LV label="電話番号（代表）" value={data.電話番号_代表} />
                    <LV label="URL" value=<WebsiteLink url={data.Webサイト_URL} /> odd />
                    <LV label="業種" value={data.業種} />
                    <LV label="事業内容" value={data.事業内容} odd />
                    <LV label="設立年" value={data.設立年 ? `${data.設立年}年` : ''} />
                    <LV label="資本金" value={data.資本金 ? `${Number(data.資本金).toLocaleString()}百万円` : ''} odd />
                    <LV label="従業員数" value={data.従業員数 ? `${data.従業員数}名` : ''} />
                    <LV label="代表的なサービス・商品" value={data.代表的な商品やサービス} odd />
                    <LV label="広報担当者" value={
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={2}
                        >
                            <Stack>
                                <Body>{pr}</Body>
                            </Stack>
                            {data.広報担当者写真 &&
                            <Avatar src={data.広報担当者写真} />
                            }
                        </Stack>
                        }
                    />
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        columnGap={3}
                        rowGap={2}
                        pt={3}
                        sx={{
                            justifyContent : { mobile : "flex-start", xs : "center" },
                        }}
                    >
                        <SnsButtons
                            data={data}
                        />
                    </Stack>
                </Stack>
            </Stack>
            }
        </Block>
    )
}

export default Overview
