import { useMutation, useQueryClient } from '@tanstack/react-query'

const useOptimismMutation = ({ mutationFn, queryKey, item }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn : mutationFn,
        onMutate : async (param) => {
            await queryClient.cancelQueries({ queryKey : queryKey })
            const previousData = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old) => {
                old[item] = param.content
                return old
            })
            return { previousData }
        },
        onSuccess : (ret) => {
            console.log(`success ${ret}`)
        },
        onError : (error, data, context) => {
            queryClient.setQueryData(queryKey, context.previousData)
            console.log(`error ${error} ${data} ${context}`)
        },
        onSettled : () => {
            queryClient.invalidateQueries({ queryKey : queryKey })
        },
        retry : 2,
    })
}

export default useOptimismMutation
