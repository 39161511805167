import { Link } from 'react-router-dom'
import { Divider, Stack } from '@mui/material'
import { format, isBefore } from 'date-fns'
import { Body, H1, H3 } from 'components/styledFonts'
import { BlueLabel, GreyLabel, DraftLabel } from 'components/styledLabels'
import { BlueContainedButton } from 'components/styledButtons'
import { UnderlineLink } from 'components/links'
import { TitledBlock } from 'components/Block'
import PaginationList from 'components/PaginationList'
import TwoColumns from 'components/TwoColumns'
import CheckingResearches from './CheckingResearches'
import DraftResearches from './DraftResearches'
import EntryListItem from './EntryListItem'
import { usePublishedResearches } from 'hooks/research'


const CollectedButton = ({ count, to }) => (
    <BlueContainedButton
        component={Link}
        to={to}
        disabled={count === 0}
        label={
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width={125}
                fontWeight={400}
            >
                <Body>集まった情報</Body>
                <Body>{count}件</Body>
            </Stack>
        }
        sx={{
            width : 145,
            height : 28,
        }}
    />
)


const ResearchManagementItem = ({ data }) => (
    <Stack
        gap={1}
    >
        <Divider sx={{ borderWidth : 1.5 }}/>
        <Stack
            px={1}
        >
            <Body>{format(data.募集開始日時_通常用, 'yyyy/MM/dd')}</Body>
            <H3>
                {data.エントリー.length > 0 &&
                <UnderlineLink
                    to={`/research/detail/${data.リクエストNo}`}
                >
                    {data.タイトル}
                </UnderlineLink>
                }
                {data.エントリー.length === 0 &&
                data.タイトル
                }
            </H3>
            <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                gap={1}
            >
                {isBefore(new Date(), data.募集締切日時) &&
                <BlueLabel>受付中</BlueLabel>
                }
                {!isBefore(new Date(), data.募集締切日時) &&
                <DraftLabel>受付終了</DraftLabel>
                }
                <GreyLabel>{`リサーチ締切：${format(data.募集締切日時, 'yyyy/MM/dd HH:mm')}`}</GreyLabel>
                <CollectedButton
                    count={data.エントリー.length}
                    to={`/research/detail/${data.リクエストNo}`}
                />
            </Stack>
        </Stack>
        {data.エントリー.length > 0 &&
        <Divider />
        }
        <Stack
            gap={3}
            sx={{ px : { mobile : 1, xs : 0 } }}
        >
            {data.エントリー.slice(0, 3).map((e, j) => (
            <EntryListItem
                key={j}
                data={e}
            />
            ))}
        </Stack>
    </Stack>
)


const ResearchManagement = () => {
    const { data } = usePublishedResearches()

    return (
        <Stack
            gap={2}
        >
            <H1>リサーチ管理</H1>
            <TwoColumns
                ratio="7fr 5fr"
            >
                <TitledBlock
                    title="公開済みリサーチ"
                >
                    {data?.length > 0 ?
                    <PaginationList
	                    max={5}
                        gap={4}
                        data={data?.map((d, i) => (
                            <ResearchManagementItem
                                key={i}
                                data={d}
                            />
                        ))}
                    />
                    :
                    <Body>公開済みリサーチはありません</Body>
                    }
                </TitledBlock>
                <Stack
                    alignSelf="start"
                    gap={1.5}
                >
                    <CheckingResearches />
                    <DraftResearches />
                </Stack>
            </TwoColumns>
        </Stack>
    )
}

export default ResearchManagement

