import { Link, useParams } from 'react-router-dom'
import { LinkButtonLarge } from 'components/styledButtons'
import { useCompanyById } from 'hooks/company'


const SendMessageButton = () => {
    const { id } = useParams()
    const { data } = useCompanyById(id)

    if (!data) return

    return (
        <LinkButtonLarge
            component={Link}
            to={`/message/${data.企業ID}`}
            state={{
                会社ロゴ : data.会社ロゴ,
                会社名 : data.会社名
            }}
        >
            この企業にメールを送信
        </LinkButtonLarge>
    )
}

export default SendMessageButton
