import { useLocation } from 'react-router-dom'
import { Divider, Stack } from '@mui/material'
import { useAtom } from 'jotai'
import { searchCondAtom } from 'store/commonAtoms'
import { Body, H3 } from 'components/styledFonts'
import { CheckboxGroupBase, SelectBase, TextFieldBase } from 'components/Inputs'
import Block from 'components/Block'
import TargetSelector from './TargetSelector'
import { categories, industry, prefectures } from 'config/options'
import SearchButton from 'components/SearchButton'


const Condition = ({ label, children }) => (
    <Stack
        gap={0.5}
    >
        <Body>{label}</Body>
        {children}
    </Stack>
)

const Checkbox = ({ list, ...props }) => (
    <Stack
        gap={0.5}
    >
        <Divider />
        <Stack
            px={2}
        >
            <CheckboxGroupBase
                list={list}
                {...props}
            />
        </Stack>
    </Stack>
)



const SearchConditions = () => {
    const { pathname } = useLocation()
    const [cond, setCond] = useAtom(searchCondAtom)

    const changeCondition = (e, name) => {
        if (Array.isArray(cond[name])) {
            setCond(c => ({
                ...c,
                [name] : e.target.checked ? [...c[name], e.target.value] : c[name].filter(v => v !== e.target.value)
            }))
        }
        else if (typeof cond[name] === 'boolean') {
            setCond(c => ({ ...c, [name] : e.target.checked }))
        }
        else {
            setCond(c => ({ ...c, [name] : e.target.value }))
        }
    }

    return (
        <Block>
            <TargetSelector />
            <Stack
                gap={2}
            >
                <H3>検索条件</H3>
                <Condition label="キーワード">
                    <TextFieldBase
                        value={cond.キーワード}
                        onChange={e => changeCondition(e, 'キーワード')}
                    />
                </Condition>
                <Condition label="地域">
                    <SelectBase
                        list={prefectures}
                        value={cond.地域}
                        onChange={e => changeCondition(e, '地域')}
                    />
                </Condition>
                <Condition label="上場">
                    <Checkbox
                        list={['上場']}
                        value={cond.上場}
                        boolean
                        onChange={e => changeCondition(e, '上場')}
                    />
                </Condition>
                <Condition label="業種">
                    <SelectBase
                        list={industry}
                        value={cond.業種}
                        onChange={e => changeCondition(e, '業種')}
                    />
                </Condition>
                <Condition label="カテゴリー">
                    <Checkbox
                        list={categories}
                        value={cond.カテゴリー}
                        onChange={e => changeCondition(e, 'カテゴリー')}
                        row={false}
                        disabled={/company/.test(pathname) || /\/search[/]?$/.test(pathname) || /inquiry$/.test(pathname)}
                    />
                </Condition>
                <SearchButton sx={{ alignSelf: 'center' }} />
            </Stack>
        </Block>
    )
}

export default SearchConditions
