import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Body, H5 } from 'components/styledFonts'
import { API } from 'lib/api'
import { apiName } from 'config/api'

const border = `1px solid ${grey[900]}`

const LVBox = ({ text, isLabel, last }) => (
    <Stack
        direction="row"
        alignItems="center"
        px={1}
        py={0.5}
        bgcolor={isLabel ? grey[200] : "#fff"}
        borderTop={border}
        borderLeft={border}
        borderRight={isLabel ? 0 : border}
        borderBottom={last ? border : 0}
        width={isLabel ? "30%" : "70%"}
        sx={{ wordBreak: "break-all" }}
    >
        <Body>{text}</Body>
    </Stack>
)

const LV = ({ label, value, last }) => (
    <Stack
        direction="row"
    >
        <LVBox text={label} last={last} isLabel />
        <LVBox text={value} last={last} />
    </Stack>
)

const ClientInfo = () => {
    const { clientId } = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        (async () => {
            const ret = await API.get(apiName, `/items/getCompany?id=${clientId}`, {})
            console.log(ret)
            setData(ret)
        })()
    }, [clientId])

    return (
        <Stack
            width="100%"
            maxWidth={1000}
            py={2}
            px={2}
        >
            <H5>企業情報</H5>
            {data &&
            <Stack
                borderBottom={border}
            >
                <LV
                    label="会社名"
                    value={data.会社名 || data.企業名}
                />
                {data.会社名 && <>
                <LV
                    label="URL"
                    value={data.URL}
                />
                <LV
                    label="本社"
                    value={data.本社}
                />
                <LV
                    label="事業内容"
                    value={data.事業内容}
                />
                <LV
                    label="設立"
                    value={`${data.設立}年`}
                />
                {data.資本金 > 0 &&
                <LV
                    label="資本金"
                    value={`${data.資本金.toLocaleString()}百万円`}
                />
                }
                {data.従業員数 > 0 &&
                <LV
                    label="従業員数"
                    value={`${data.従業員数.toLocaleString()}名`}
                />
                }
                {data.広報担当者 &&
                <LV
                    label="広報担当者"
                    value={data.広報担当者}
                />
                }
                <LV
                    label="お問い合わせ先"
                    value={data.お問い合わせ先}
                />
                </>}
            </Stack>
            }
        </Stack>
    )
}

export default ClientInfo
