import { Stack } from '@mui/material'
import { H1 } from 'components/styledFonts'
import Block from 'components/Block'
import History from './History'
import Entry from './Entry'
import { useSupportMessages } from 'hooks/message'


const SupportForMobile = () => {
    const { data } = useSupportMessages()

    return (
        <Stack gap={2}>
            <H1>お問い合わせ</H1>
            {data?.map((d, i) => (
            <Block
                key={i}
            >
                <History message={d} />
                <Entry entryMessage={d} />
            </Block>
            ))}
        </Stack>
    )
}

export default SupportForMobile

