import { Stack } from '@mui/material'
import { Body, H2, H5 } from 'components/styledFonts'
import { RedLabel } from 'components/styledLabels'
import Block from 'components/Block'
import TwoColumns from 'components/TwoColumns'
import ArticleImage from 'components/ArticleImage'


const ResearchEntry = ({ data, print }) => {
   
    if (!data) return null

    const sx = print ? { border : 0 } : {}

    return (
        <Block
            sx={{
                ...sx,
                minHeight : 700,
            }}
        >
            <Stack
                alignItems="flex-start"
                gap={4}
            >
                {data.結果 === '成立'  &&
                <RedLabel>連絡先入手済み</RedLabel>
                }
                <H2>{data.タイトル}</H2>
                {data.メイン画像 &&
                <ArticleImage
                    src={data.メイン画像}
                    caption={data.メイン画像キャプション}
                />
                }
                {Object.keys(data).filter(n => /^質問/.test(n) && data[n]).sort((a, b) => {
                    return Number(a.replace('質問', '')) - Number(b.replace('質問', ''))
                }).map((n, i) => (
                <Stack
                    key={i}
                    gap={1}
                >
                    <H5>{data[n]}</H5>
                    <Body
                        whiteSpace="pre-wrap"
                    >
                        {data[`回答${Number(n.replace('質問', ''))}`]}
                    </Body>
                </Stack>
                ))}
                {(data.画像1 || data.画像2 || data.画像3 || data.画像4) &&
                <Stack
                    alignItems="center"
                    width="100%"
                    px={1}
                >
                    <TwoColumns
                        gap={4}
                    >
                    {[...Array(4).keys()].map(i => (
                        <ArticleImage
                            key={i}
                            src={data[`画像${i + 1}`]}
                            caption={data[`画像${i + 1}キャプション`]}
                        />
                    ))}
                    </TwoColumns>
                </Stack>
                }
            </Stack>
        </Block>
    )
}

export default ResearchEntry
