import { Stack } from '@mui/material'
import { UnderlineLink } from 'components/links'
import { H5Overflow } from 'components/styledFonts'
import ComponentBase from './ComponentBase'
import Thumbnail from 'components/Thumbnail'
import { useBroweHistoryList } from 'hooks/browsehistory'


const BrowseHistory = () => {
    const data = useBroweHistoryList()

    return (
        <ComponentBase
            title="閲覧履歴"
            buttons={[{ label : '閲覧履歴一覧', to : 'browsehistorylist' }]}
        >
            {data.length > 0 &&
            <Stack 
                direction="row"
                flexWrap="wrap"
                rowGap={2}
                mb={2}
                px={1}
                sx={{
                    columnGap : {mobile : 4.3, xs : 0 },
                    justifyContent : { mobile : "flex-start", xs : "space-between" },
                }}
            >
                {data.slice(0, 6).map((d, i) => (
                <Stack
                    key={i}
                    alignItems="center"
                    gap={0.5}
                >
                    <Thumbnail
                        src={d.サムネイル}
                        to={d.path}
                        width={135}
                        height={90}
                    />
                    <UnderlineLink
                        to={d.path}
                    >
                        <H5Overflow
                            sx={{ width :  { mobile : 155, xs : 135 } }}
                        >
                            {d.タイトル}
                        </H5Overflow>
                    </UnderlineLink>
                </Stack>
                ))}
            </Stack>
            }
        </ComponentBase>
    )
}

export default BrowseHistory
