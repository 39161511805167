import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { isAfter } from 'date-fns'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useDynamoItem } from './dynamo'


const getData = async () => {
    const ret = await API.get(apiName, '/items/getResearch', {})
    return ret.map(r => ({
        ...r,
        エントリー: r.エントリー.map(e => ({ ...e, 更新日_データ連携用: new Date(e.更新日_データ連携用) })),
        更新日時: new Date(r.更新日_データ連携用),
        募集締切日時: r.募集締切日時 ? new Date(r.募集締切日時) : null,
        募集開始日時_通常用: r.募集開始日時_通常用 ? new Date(r.募集開始日時_通常用) : null,
    }))
}

// 全件取得
export const useAllResearches = () => {
    return useQuery(['research'], getData)
}

// 公開済みリサーチ
export const usePublishedResearches = () => {
    return useQuery(['research'], getData, {
        select : (data) => data
            .filter(d => isAfter(new Date(), d.募集開始日時_通常用) && d.募集締切日時)
            .filter(d => !d.下書き_通常用 && !d.メディア一時保存)
            .sort((a, b) => b.募集開始日時_通常用 - a.募集開始日時_通常用)
    })
}

// 一時保存中のリサーチ
export const useDraftResearches = () => {
    return useQuery(['research'], getData, {
        select : (data) => data
            .filter(d => d.メディア起票 && d.メディア一時保存)
            .sort((a, b) => b.更新日時 - a.更新日時)
    })
}

// 運営が確認中のリサーチ
export const useCheckingResearches = () => {
    return useQuery(['research'], getData, {
        select : (data) => data
            .filter(d => isAfter(d.募集開始日時_通常用, new Date()) || !d.募集開始日時_通常用)
            .filter(d => d.メディア起票 && !d.メディア一時保存)
            .sort((a, b) => b.募集開始日時_通常用 - a.募集開始日時_通常用)
    })
}

// 最近の無料リサーチ
export const useRecentlyResearches = (no) => {
    return useQuery(['research'], getData, {
        select : (data) => data
            .filter(d => isAfter(new Date(), d.募集開始日時_通常用) && d.募集締切日時)
            .filter(d => d.リクエストNo !== no)
            .filter(d => !d.下書き_通常用 && !d.メディア一時保存)
            .sort((a, b) => b.募集開始日時_通常用 - a.募集開始日時_通常用)
            .slice(0, 5)
    })
}

// 指定されたリクエストNoを条件に抽出
export const useResearchByNo = (no) => {
    return useQuery(['research'], getData, {
        select : (data) => data.find(d => d.リクエストNo === no)
    })
}

export const useEntryByNo = (reqNo, entNo) => {
    return useQuery(['research'], getData, {
        select : (data) => data.find(d => d.リクエストNo === reqNo)?.エントリー.find(e => e.エントリーNo === entNo)
    })
}




export const useNewResearches = () => {
    return useQuery(['research'], getData, {
        select : (data) => data.concat().sort((a, b) => b.募集開始日時_通常用 - a.募集開始日時_通常用).slice(0, 5)
    })
}

export const useResearchEntry = () => {
    return useQuery(['research'], getData, {
        select : (data) => data
            .filter(d => !d.下書き_通常用)
            .flatMap(d => d.エントリー)
    })
}

export const useNewResearchEntryCount = () => {
    const count = useNewResearchEntryList().length
    return count
}

export const useNewResearchEntryList = () => {
    const { data : entry } = useResearchEntry()
    const { data : dynamo = [], isSuccess } = useDynamoItem('エントリー既読一覧')
    const [newResearchEntry, setNewResearchEntry] = useState([])

    useEffect(() => {
        if (!entry?.length || !isSuccess) return

        const ret = entry
            .filter(d => !dynamo.includes(d.エントリーNo))
            .sort((a, b) => b.更新日_データ連携用 - a.更新日_データ連携用)
        setNewResearchEntry(ret)
    }, [entry, dynamo, isSuccess])

    return newResearchEntry
}

