import { Stack } from '@mui/material'
import { H5Overflow, Body, BodyLH1 } from 'components/styledFonts'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'

const CommonList = ({ title, mainPict, date, client, to }) => {

    return (
        <Stack
            gap={1}
        >
            <Stack
                direction="row"
                alignItems="flex-start"
                gap={2}
            >
                <Thumbnail
                    src={mainPict}
                    to={to}
                />
                <Stack
                    gap={0.5}
                >
                    <BodyLH1>{date}</BodyLH1>
                    <Body>{client}</Body>
                    <UnderlineLink
                        to={to}
                    >
                        <H5Overflow>{title}</H5Overflow>
                    </UnderlineLink>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default CommonList
