import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { blue, orange, green, grey, purple, red } from '@mui/material/colors'

// ラベル
const BaseLabel = styled(Box)({
    padding : '4px 4px 2.2px',
    borderRadius : 5,
    lineHeight : '14px',
    fontSize : 12,
    borderWidth : 1,
    borderStyle : 'solid',
})
const BoldLabel = styled(BaseLabel)({
    padding : '3px 4px 2.2px',
    borderWidth : 2,
})


export const WhiteLabel = styled(BaseLabel)({
    borderColor : grey[400],
    background : "#fff",
})

export const GreyLabel = styled(BaseLabel)({
    borderColor : grey[400],
    background : grey[100],
})

export const RedLabel = styled(BoldLabel)({
    borderColor : red[500],
    color : red[500],
})

export const BlueLabel = styled(BoldLabel)({
    borderColor : blue[500],
    color : blue[500],
})

export const GroupA = styled(BaseLabel)({
    borderColor : orange[400],
    color : orange[400],
})

export const GroupB = styled(BaseLabel)({
    borderColor : purple[300],
    color : purple[300],
})

export const GroupC = styled(BaseLabel)({
    borderColor : green.A200,
    color : green.A200,
})

export const VideoTag = styled(BaseLabel)({
    fontSize : 14,
    borderColor : grey[300],
    background : grey[200],
    padding : '6px 12px 4px 12px',
})

export const PublicLabel = styled(BoldLabel)({
    borderColor : grey[600],
    color : grey[600],
    fontWeight : 700,
})

export const DraftLabel = styled(BaseLabel)({
    borderColor : grey[600],
    backgroundColor : grey[600],
    color : '#fff',
})


// 新着
export const NewlyLabel = styled(BaseLabel)({
    backgroundColor : red[500],
    color : '#fff',
})

// トップページの新着件数
export const CountBadge = styled(NewlyLabel)({
    fontSize : 14,
    fontWeight : 700,
    textAlign : "center",
    minWidth : 45,
})

// 報道関係者用
export const BlackLabel = styled(Box)({
    borderColor : '#000',
    background : '#000',
    color : '#fff',
    fontSize : 14,
    padding : '2px 6px',
    borderRadius : 4,
})
export const ForMedia = () => <BlackLabel>報道関係者用</BlackLabel>
