import { Link, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { EditButton } from 'components/styledButtons'
import { TitledBlock } from 'components/Block'
import PostListItem from './PostListItem'
import { usePostsOfClient } from 'hooks/post'


const PostList = () => {
    const { id } = useParams()
    const { data } = usePostsOfClient(id)

    if (!data || !data.length) return

    return (
        <TitledBlock
            title="この企業のプレスリリース・その他"
        >
            <Stack
                gap={2}
                sx={{ px : { sm : 8, xs : 1 } }}
            >
                {data?.slice(0, 5).map((d, i) => (
                <PostListItem
                    key={i}
                    data={d}
                />
                ))}
            </Stack>
            <Stack
                direction="row"
                pt={1}
                justifyContent="flex-end"
            >
                <EditButton
                    component={Link}
                    to={`/company/${id}/postlist`}
                >
                    一覧
                </EditButton>
            </Stack>
        </TitledBlock>
    )
}

export default PostList
