import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'
import { BlueContainedButton } from 'components/styledButtons'
import { useMessagesMutaion } from 'hooks/mutation/message'
import { useLoginUser } from 'hooks/loginuser'
import { Body } from 'components/styledFonts'
import { BlueUnderlineLink } from 'components/links'
import { SelectBase, TextFieldBase } from 'components/Inputs'
import { message as messages } from 'config/message'

const Entry = ({ entryMessage }) => {
    const [message, setMessage] = useState('')
    const { mutate } = useMessagesMutaion()
    const { data : loginUser } = useLoginUser()
    const [media, setMedia] = useState(0)
    const { state } = useLocation()
    const navi = useNavigate()

    useEffect(() => {
        if (!state?.タイトル) return
        setMessage(messages.MESSAGE_FORM(state.会社名, state.タイトル)) 
    }, [state?.会社名, state?.タイトル])

    const createSelect = a => [{ label : '-------', value : 0 }].concat(a.map(l => ({ label : l, value : l })))

    const mediaUsers = createSelect([
        loginUser?.担当媒体_1,
        loginUser?.担当媒体_2,
        loginUser?.担当媒体_3,
    ]).filter(m => m.label !== '')

    const handleButtonClick = () => {
        const client = entryMessage.回答テーブル.filter(t => t.送信者 === "企業担当者").slice(-1)[0]

        mutate({
            // メッセージアプリ登録・更新用パラメータ
            登録 : !entryMessage.日時,
            メッセージ区分 : entryMessage.企業ID ? "企業・メディア間" : "メディア・ネタもとサポート間",
            企業ID : entryMessage.企業ID || "",
            媒体名 : media,
            媒体担当者ID : loginUser.媒体担当者ID,
            姓 : loginUser.姓,
            名 : loginUser.名,
            回答テーブル : entryMessage.回答テーブル.map(i => ({ "id": i.id })),
            送信者 : "媒体担当者",
            回答内容 : message,
            id : entryMessage.$id,
            revision : entryMessage.$revision,

            // メール送信用パラメータ
            テンプレート区分 : "メッセージ通知_メディア",
            送信先_企業名 : entryMessage.会社名,
            送信先_姓 : client?.企業担当者_姓,
            送信先_名 : client?.企業担当者_名,
            送信者_企業名 : loginUser.所属企業,
            送信者_姓 : loginUser.姓,
            送信者_名 : loginUser.名,
            メッセージ : message,
            企業担当者ID : client?.企業担当者ID,
        })
        setMessage('')
        if (entryMessage.企業ID) {navi(`/message/${entryMessage.企業ID}`)}
    }

    return (
        <Box
            bgcolor={blueGrey[50]}
            border={1}
            borderColor={grey[400]}
            px={2}
            mb={2}
            sx={{
                borderRadius : { mobile : "0 0 4px 4px", xs : 1 },
                mt : { mobile : "-4px", xs : 0 },
                py : { mobile : 4, xs : 2 },
            }}
        >
            {!entryMessage.日時 &&
            <Stack
                alignItems="left"
                gap={1.5}
                pb={2}
                sx={{ px : { mobile : 2, xs : 0 } }}
            >
                <Body
                    sx={{ whiteSpace : "pre-wrap" }}
                >
                    <Stack>
                        <Body>選択肢よりメールの受信者に表示させる媒体を選択してください。</Body>
                        <Body>担当媒体が未設定の場合は、<BlueUnderlineLink to="/mypage">マイページ画面</BlueUnderlineLink>から担当媒体の設定をお願いいたします。</Body>
                    </Stack>
                </Body>
                
                <SelectBase
                    list={mediaUsers}
                    value={media}
                    onChange={e => setMedia(e.target.value)}
                    sx={{
                        maxWidth : 150,
                        bgcolor : '#fff',
                        fontSize : 14,
                    }}
                />
            </Stack>
            }
            <Stack
                alignItems="center"
                gap={2}
            >
                <TextFieldBase
                    placeholder="こちらにメッセージを入力して下さい"
                    multiline
                    minRows={4}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    sx={{
                        bgcolor : '#fff',
                        width : { mobile : "80%", xs : "100%" },
                    }}
                />
                <BlueContainedButton
                    label="メッセージ送信"
                    sx={{
                        width : { xs : 160 },
                        lineHeight : 2.4,
                    }}
                    onClick={handleButtonClick}
                    disabled={!message || (!entryMessage.日時 && media === 0)}
                />
            </Stack>
        </Box>
    )
}

export default Entry


