import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { Box, Divider, Menu, MenuItem } from '@mui/material'
import Badge from 'components/Badge'
import { useNewFeedbackCount } from 'hooks/feedback'
import { useNewResearchEntryCount } from 'hooks/research'
import { useLoginUser } from 'hooks/loginuser'

const menu = [
    {l : 'フィードバック依頼一覧'   , p : 'feedback/list',  b : 'fcnt'},
    {l : 'リサーチ管理'             , p : 'research',  b : 'rcnt'},
    {l : '情報検索'                 , p : 'search'},
    {l : 'マイページ'               , p : 'mypage'},
    {l : 'ログアウト'               , p : '/'},
]

const menuitem = (m, c) => {
    if (m.b) {
        return <Badge count={c[m.b]} menuList>{m.l}</Badge>
    }
    return m.l
}

const MenuList = ({ menuEl, setMenuEl }) => {
    const { data : fcnt } = useNewFeedbackCount()
    const rcnt = useNewResearchEntryCount()
    const cnt = { fcnt : fcnt, rcnt : rcnt }
    const { data : loginuser } = useLoginUser()

    const filteredMenu = menu.filter(m => loginuser?.フィードバック機能 ? true : m.l !== 'フィードバック依頼一覧')

    return (
        <Menu
            open={Boolean(menuEl)}
            anchorEl={menuEl}
            onClose={() => setMenuEl(null)}
        >
            {filteredMenu.map((m, i) => (
            <Box key={i}>
                <MenuItem
                    component={Link}
                    to={m.p}
                    sx={{ py : 0.5, pl : 2.5, pr : 9, fontSize : 14 }}
                    onClick={async () => {
                        if (m.l === 'ログアウト') {
                            await Auth.signOut().catch(e => console.log(e))
                        }
                        else {
                            setMenuEl(null)
                        }
                    }}
                >
                    {menuitem(m, cnt)}
                </MenuItem>
                {i !== filteredMenu.length - 1 ? <Divider /> : null}
            </Box>
            ))}
        </Menu>
    )
}

export default MenuList
