import { Stack } from '@mui/material'
import { NodecorationLink } from 'components/links'
import S3Image from 'components/S3Image'
import { CountBadge } from 'components/styledLabels'



const Thumbnail = ({ src, width=99, height=66, to="#", newly }) => (
    <NodecorationLink
        to={to}
    >
        <Stack
            position="relative"
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                width={width}
                height={height}
                flexShrink={0}
            >
                <S3Image
                    url={src}
                />
            </Stack>
            {newly &&
            <CountBadge
                sx={{
                    position : "absolute",
                    top : -4,
                    left : -4,
                }}
            >
                NEW
            </CountBadge>
            }
        </Stack>
    </NodecorationLink>
)

export default Thumbnail
