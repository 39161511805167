import { format } from 'date-fns'
import { grey } from '@mui/material/colors'
import { Box, Stack } from '@mui/material'
import { H5, Body } from 'components/styledFonts'
import { NodecorationLink } from 'components/links'
import ComponentBase from './ComponentBase'
import { useNewMessages } from 'hooks/message'
import UserIcon from 'img/icon-user-media.png'

const NewMail = () => {
    const data = useNewMessages()

    return (
        <ComponentBase
            title="新着メッセージ"
            count={data?.length}
            buttons={[{ label : 'メッセージボックス', to : 'message' }]}
        >
            {(data && data.length > 0) &&
            <Stack
                gap={1.5}
                pb={1}
            >
                {data.slice(0, 3).map((d, i) => (
                <Stack
                    key={i}
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                    p={2}
                    bgcolor={grey[100]}
                    border={`1px solid ${grey[400]}`}
                    borderRadius={1}
                >
                    <Box
                        component="img"
                        src={UserIcon}
                        width={38}
                    />
                    <Stack
                        gap={0.5}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={2}
                        >
                            <H5>{format(d.日時, 'yyyy/MM/dd HH:mm')}</H5>
                            <H5>{d.企業担当者_姓 + "　" + d.企業担当者_名}</H5>
                        </Stack>
                        <NodecorationLink
                            to={`/message/${d.企業ID ? d.企業ID : "support" }`}
                        >
                            <Body
                                textAlign="left"
                                height={40}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                sx={{
                                    display : '-webkit-box',
                                    WebkitBoxOrient : 'vertical',
                                    WebkitLineClamp : 2,
                                }}
                                dangerouslySetInnerHTML={{__html: d.回答内容}}
                            />
                        </NodecorationLink>
                    </Stack>
                </Stack>
                ))}
            </Stack>
            }
        </ComponentBase>
    )
}

export default NewMail
