import { Stack, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { Body, H5Overflow } from 'components/styledFonts'
import { BlueLabel, GreyLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'


const ListItem = ({ type, data }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    return (
        <Stack
            direction="row"
            gap={2}
        >
            <Stack
                gap={1}
                alignItems="flex-start"
            >
                {isMobile &&
                <BlueLabel>{type}</BlueLabel>
                }
                <Thumbnail
                    src={data.サムネイル}
                    to={data.to}
                />
            </Stack>
            <Stack
                gap={1}
            >
                <H5Overflow>
                    <UnderlineLink
                        to={data.to}
                    >
                        {data.タイトル}
                    </UnderlineLink>
                </H5Overflow>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    gap={1}
                >
                    {!isMobile &&
                    <BlueLabel>{type}</BlueLabel>
                    }
                    <GreyLabel>公開日：{format(data.公開日, 'yyyy/MM/dd HH:mm')}</GreyLabel>
                    <Body>{data.会社名}</Body>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ListItem
