import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useSetAtom } from 'jotai'
import { searchCondAtom, searchCondInit, searchPageAtom, searchTriggerAtom } from 'store/commonAtoms'
import { H1 } from 'components/styledFonts'
import TwoColumns from 'components/TwoColumns'
import SearchConditions from './SearchConditions'


const SearchBase = () => {
    const { pathname } = useLocation()
    const navi = useNavigate()
    const setCond = useSetAtom(searchCondAtom)
    const setPage = useSetAtom(searchPageAtom)
    const setTrigger = useSetAtom(searchTriggerAtom)

    useEffect(() => {
        if (/search$/.test(pathname)) {
            // メニューから遷移時は、検索条件をクリアしpathを変更する
            setCond(searchCondInit)
            setTrigger(true)
            setPage(1)
            navi('/search/company')
        } else {
            setTrigger(true)
        }
    }, [pathname, navi, setCond, setPage, setTrigger])

    return (
        <Stack
            gap={2}
        >
            <H1>情報検索</H1>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <Outlet />
                <SearchConditions />
            </TwoColumns>
        </Stack>
    )
}

export default SearchBase
