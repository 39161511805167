import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'

export const useMessagesMutaion = () => {
    return useMyMutation({
        mutationFn : async (param) => {
            const ret = await API.post(apiName, '/items/postMessage', { body : param })
            return ret
        },
        queryKey : ['message'],
    })
}