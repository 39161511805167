import { useParams } from 'react-router-dom'
import PressRelease from 'components/PressRelease'
import { usePressReleaseByNo } from 'hooks/post'


const FeedbackPressReleaseDetail = () => {
    const { no } = useParams()
    const { data } = usePressReleaseByNo(no)

    return (
        <PressRelease data={data} feedback />
    )
}

export default FeedbackPressReleaseDetail
