import { forwardRef, useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup as MuiRadioGroup,
    Select as MuiSelect,
    Stack,
    TextField as MuiTextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import { Body, H5 } from 'components/styledFonts'
import { SelectImage } from 'components/fileUploader'

registerLocale('ja', ja)

const StyledText = styled(MuiTextField)({
    '& .MuiOutlinedInput-input' : {
        backgroundColor : '#fff',
    },
})

export const Required = () => <Body component="span" color={red[400]} pl={0.3}>*</Body>

export const TextFieldBase = ({ ...props }) => {
    return (
        <StyledText
            size="small"
            {...props}
        />
    )
}

export const TextField = ({ name, control, ...props }) => {
    const { field : { ref, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <TextFieldBase
            name={name}
            inputRef={ref}
            error={!!error}
            {...props}
            {...rest}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const LabeledTextField = ({ name, label, control, required, ...props }) => {
    return (
        <Stack
            width={props.fullWidth ? "100%" : "auto"}
            gap={0.5}
        >
            <H5>
                {label || name}
                {required &&
                <Required />
                }
            </H5>
            <TextField
                name={name}
                control={control}
                {...props}
            />
        </Stack>
    )
}

export const CharacterLimitField = ({ limit, disableEnter, watch, ...props }) => {
    const [count, setCount] = useState(0)

    useEffect(() => setCount((watch || '').length), [watch])

    if (disableEnter) {
        props.inputProps = {
            onKeyDown : e => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }
        }
    }

    return (
        <Stack
        >
            <LabeledTextField
                {...props}
            />
            <Body>※{limit}字以内（残り{limit - count}文字）</Body>
        </Stack>
    )
}

export const ImageCaption = ({ captionName, control, pict, setPict }) => {
    return (
        <Stack gap={1}>
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
            >
                <SelectImage
                    file={pict}
                    setFile={setPict}
                    showName
                    removable
                />
            </Stack>
            {pict?.name &&
            <TextField name={captionName} control={control} placeholder="写真のキャプションを入力してください。"></TextField>
            }
        </Stack>
    )
}

export const SelectBase = forwardRef(({ list, onChange, ...props }, ref) => {
    return (
        <FormControl
            ref={ref}
            size="small"
            fullWidth
        >
            <MuiSelect
                displayEmpty
                sx={{ fontSize : 14 }}
                onChange={onChange}
                {...props}
            >
                {list?.map(l => (
                <MenuItem
                    key={l.value}
                    value={l.value}
                    sx={{ fontSize : 14 }}
                >
                    {l.label}
                </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    )
})

export const Select = ({ name, control, list, handleChange, ...props }) => {
    const { field : { ref, onChange, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <SelectBase
            list={list}
            ref={ref}
            onChange={e => {
                onChange(e)
                if (handleChange) handleChange()
            }}
            {...rest}
            {...props}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const RadioGroupBase = forwardRef(({ list, sx, gap, error, fullWidth, onChange, ...props }, ref) => {

    return (
        <FormControl
            error={!!error}
            fullWidth={fullWidth}
            sx={sx}
            ref={ref}
        >
            <MuiRadioGroup
                sx={{ gap : gap }}
                {...props}
            >
                {list.map((r, i) => (
                <FormControlLabel
                    key={i}
                    value={r.value || r}
                    label={<Body>{r.label || r}</Body>}
                    control={<Radio size="small" />}
                    onChange={onChange}
                />
                ))}
            </MuiRadioGroup>
        </FormControl>
    )
})

export const RadioGroup = ({ name, control, list, gap=1, ...props }) => {
    const { field : { ref, ...rest }, fieldState : { error } } = useController({ name, control })
    return (<>
        <RadioGroupBase
            list={list}
            gap={gap}
            ref={ref}
            error={error}
            fullWidth
            {...props}
            {...rest}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const CheckboxGroupBase = forwardRef(({ list, value, boolean, max, sx, onChange, row=true, ...props }, ref) => {
    return (
        <FormGroup
            ref={ref}
            onChange={onChange}
            row={row}
            {...props}
        >
            {list.map(v => (
            <FormControlLabel
                key={v.value || v}
                label={<Body>{v.label || v}</Body>}
                control={<Checkbox
                    value={v.value || v}
                    size="small"
                    color="primary"
                    checked={boolean ? value : value.includes(v.value || v)}
                    disabled={!boolean && !value.includes(v.value || v) && value.length >= max}
                />}
                {...props}
                sx={{
                    mr : 3,
                    '& .MuiFormControlLabel-label' : {
                        ml : -0.3,
                    },
                    ...sx,
                }}
            />
            ))}
        </FormGroup>
    )
})

export const CheckboxGroup = ({ name, control, list, row=true, max, sx, boolean, ...props }) => {
    const { field : { ref, value, onChange, ...rest }, fieldState : { error } } = useController({ name, control })

    const handleChange = e => {
        if (e.target.checked) {
            return [...new Set([...value, e.target.value])]
        }
        else {
            return value.filter(v => v !== e.target.value)
        }
    }

    const handleBooleanChange = e => {
        return e.target.checked
    }

    return (<>
        <CheckboxGroupBase
            list={list}
            ref={ref}
            row={row}
            value={value}
            boolean={boolean}
            max={max}
            sx={sx}
            onChange={e => onChange(boolean ? handleBooleanChange(e) : handleChange(e))}
            {...rest}
            {...props}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const DatePicker = ({ name, control, ...props }) => {
    const { field : { ref, value, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <FormControl error={!!error}
            sx={{
                '& .MuiInputBase-root input' : {
                    p : '8.5px 14px',
                },
            }}
        >
            <ReactDatePicker
                locale="ja"
                showIcon
                dateFormat={props.showTimeInput ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd'}
                timeInputLabel="時間："
                ref={ref}
                selected={value}
                customInput={<StyledText error={!!error} />}
                {...rest}
                {...props}
            />
        </FormControl>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const SearchWord = ({ value, handleChange, handleClick, placeholder, ...props }) => {

    return (
        <Box
            pb={1}
            maxWidth={400}
            {...props}
        >
            <MuiTextField
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                InputProps={{
                    endAdornment : (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClick}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                size="small"
                fullWidth
            />
        </Box>
    )
}

