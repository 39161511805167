import { useParams } from 'react-router-dom'
import { Stack, Box } from '@mui/material'
import { H1 } from 'components/styledFonts'
import TwoColumns from 'components/TwoColumns'
import Logo from './Logo'
import Overview from './Overview'
import Inquery from './Inquery'
import PostList from './PostList'
import SendMessageButton from './SendMessageButton'
import { useInquiryByClientId } from 'hooks/post'
import { useCompanyById } from 'hooks/company'


const CompanyDetail = () => {
    const { id } = useParams()
    const { data : inqs } = useInquiryByClientId(id)
    const { data : company } = useCompanyById(id)

    const hasInquiry = Object.keys(inqs || {}).length > 0


    return (
        <Stack
            gap={2}
        >
            <H1>企業詳細</H1>
            <Stack
                gap={1.5}
            >
                <Logo />
                <TwoColumns
                    ratio={hasInquiry ? "9fr 3fr" : "1fr"}
                >
                    {company?.会社名 ? 
                    <Overview /> : 
                    <Stack
                        position="relative"
                    >
                        <Box
                            position="absolute"
                            sx={{ 
                                width : { xs : "45%", sm : "70%" }, 
                                height : "72%",
                                bgcolor : "gray", 
                                opacity : "50%", 
                                top : "16%", 
                                left : { xs : "45%", sm : "22%" }
                            }}
                        >
                            <Box 
                                position="absolute" 
                                sx={{ 
                                    color : "white", 
                                    fontSize : { xs : 15, sm : 30 }, 
                                    top : "45%", 
                                    left : { xs : "35%", sm : "45%" } 
                                }}
                            >
                                非表示
                            </Box>
                        </Box>
                        <Overview />
                    </Stack>
                    }
                    {hasInquiry &&
                    <Inquery />
                    }
                </TwoColumns>
                <PostList />
                <Stack
                    direction="row"
                    justifyContent="center"
                    py={2}
                >
                    <SendMessageButton />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default CompanyDetail
