import { Outlet } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { ForMedia } from 'components/styledLabels'
import ClientInfo from './ClientInfo'
import logo from 'img/new-logo-g.gif'


const PrintBase = () => {

    return (
        <Stack
            alignItems="center"
            bgcolor="#fff"
        >
            <Stack
                direction="row"
                width="100%"
                justifyContent="center"
                alignItems="center"
                gap={1}
                py={2}
                borderBottom={`1px solid ${grey[300]}`}
            >
                <Box
                    component="img"
                    src={logo}
                    width={120}
                />
                <ForMedia />
            </Stack>
            <Stack
                width="100%"
                maxWidth={1000}
                px={2}
                pt={2}
                pb={8}
                gap={4}
            >
                <Outlet />
                <ClientInfo />
            </Stack>
        </Stack>
    )
}

export default PrintBase
