import { useParams } from 'react-router-dom'
import { TitledBlock } from 'components/Block'
import InquiryItem from 'components/InquiryItem'
import { cate, useOtherInquiries } from 'hooks/post'

const OtherInquiries = () => {
    const { clientId, no } = useParams()
    const { data } = useOtherInquiries(clientId, no)

    if (!data || Object.keys(data).length === 0) return null

    return (
        <TitledBlock
            title="この企業の企業探訪"
            sx={{ width : "100%" }}
        >
            {cate.filter(c => data[c]).map((c, i) => (
            <InquiryItem
                key={i}
                data={data[c]}
            />
            ))}
        </TitledBlock>
    )
}

export default OtherInquiries

