import { Box, Button, Link, Stack } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { H1, H2, Body } from 'components/styledFonts'
import { ForMedia } from 'components/styledLabels'
import { PenIcon } from 'components/svgIcons'
import { urls } from 'config/urls'
import logo from 'img/new-logo-g.gif'
import logo2 from 'img/logo@2x.png'
import about1 from 'img/about1.png'
import about3 from 'img/about3.png'


const Header = ({ title }) => 
    <Box
        pl={1}
        mb={2}
        borderLeft="4px solid #000"
        lineHeight={1}
    >
        <H2>{title}</H2>
    </Box>



const About = ({ title, img, about }) =>
    <Stack
        gap={3}
    >
        <Box
            p={0.75}
            width="100%"
            borderRadius={1.5}
            fontSize={20}
            fontWeight={700}
            bgcolor="#666"
            color="#fff"
            textAlign="center"
        >
            {title}
        </Box>
        <Stack
            alignItems="center"
        >
            <Stack
                height={154}
                justifyContent="center"
            >
            <Box
                component="img"
                src={img}
            />
            </Stack>
        </Stack>
        <Body>{about}</Body>
    </Stack>

const FooterLink = ({ href, label }) =>
    <Link
        href={href}
        underline="hover"
        target="_blank"
        sx={{
            color : 'grey.700',
            fontSize : 14,
        }}
    >
        {label}
    </Link>

export const loginComponents = {
    Header() {
        return (
            <Stack
                alignItems="center"
                width="100%"
                borderBottom="1px solid #ccc"
                bgcolor="#fff"
                sx={{
                    pt : { mobile : 2.5, xs : 2 },
                    pb : { mobile : 2.5, xs : 2 },
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    <Box
                        component="img"
                        src={logo}
                        sx={{
                            width : { mobile : 120, xs : 80 },
                        }}
                    />
                    <ForMedia />
                </Stack>
            </Stack>
        )
    },
    Footer() {
        return (
            <Stack
                alignItems="center"
                pt={3}
                pb={10}
                gap={3}
                width="100%"
                borderTop="1px solid #ccc"
                bgcolor="#f2f2f2"
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{ 
                        gap : { mobile : 7, xs : 4 },
                    }}
                >
                    {Object.keys(urls.footer).map(l => (
                    <FooterLink key={l} label={l} href={urls.footer[l]} />
                    ))}
                </Stack>
                <Body>Copyright © Netamoto Inc. All rights reserved.</Body>
            </Stack>
        )
    },
    SignIn: {
        Header() {
            return (
                <Box
                    px={1}
                    sx={{ width : { mobile : 460, xs : 350 } }}
                >
                    <Header title="ログイン" />
                </Box>
            )
        },
        Footer() {
            const { toResetPassword } = useAuthenticator()

            return (<>
                <Stack
                    px={1}
                    pb={5}
                    gap={1.5}
                    sx={{ width : { mobile : 460, xs : 350 } }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        <HelpIcon
                            sx={{ fontSize : 22 }}
                        />
                        <Link
                            underline="hover"
                            onClick={toResetPassword}
                            sx={{
                                pt : 0.2,
                                color : 'grey.700',
                                fontSize : 15,
                                lineHeight : 1,
                            }}
                        >
                            パスワードをお忘れの方はこちら
                        </Link>
                    </Stack>
                    <Button
                        component={Link}
                        href={process.env.REACT_APP_MEDIA_REGISTER}
                        variant="outlined"
                        color="grey800"
                        startIcon={<PenIcon />}
                        sx={{ fontSize : 16, bgcolor : "#fff" }}
                    >
                        新規のご登録はこちらから
                    </Button>
                    <Button
                        component={Link}
                        href={process.env.REACT_APP_CLIENT}
                        target="_blank"
                        variant="outlined"
                        color="grey800"
                        sx={{ fontSize : 16, bgcolor : "#fff" }}
                    >
                        ご登録済みの企業・団体の方はこちら
                    </Button>
                </Stack>
                <Stack
                    alignItems="center"
                    width="100%"
                    pt={4}
                    pb={8}
                    px={3}
                    gap={1}
                    bgcolor="#fff"
                >
                    <H1
                        sx={{
                            fontSize : { mobile : 26, xs : 22 }
                        }}
                    >
                        欲しい情報をリサーチ出来る
                    </H1>
                    <Body
                        textAlign="center"
                        px={1}
                        sx={{
                            fontSize : { mobile : 20, xs : 18 }
                        }}
                    >
                        他にはない報道関係者専用のクローズドサイト
                    </Body>
                    <Box
                        component="img"
                        src={logo2}
                        py={2}
                    />
                    <Stack
                        maxWidth={770}
                        gap={3}
                    >
                        <Body>「ネタもと」は、登録している約600社の企業・団体に対して、報道関係者が好きなときに必要な情報をリサーチ出来る双方向コミュニケーションプラットフォームです。各社のプレスリリースやニュースレターはもちろん、それだけでは見つけられない企業の取組み、社長や社員のストーリー、開発秘話など、様々な情報を入手できます。</Body>
                        <Box
                            display="grid"
                            columnGap={4}
                            rowGap={3.5}
                            sx={{
                                gridTemplateColumns : { sm : "1fr 1fr", xs : "1fr" },
                                gridTemplateRows : { sm : "1fr", xs : "auto" },
                                px : { sm : 0, xs : 2 },
                            }}
                        >
                            <About
                                title="リサーチ"
                                img={about3}
                                about="「ネタもと」を利用する企業・団体から、探している情報を無料でリサーチします。通常の情報収集のほか、「寄稿者を募集したい」「急遽キャンセルになった取材先の代替えを見つけたい」等といったご要望にも対応いたします。"
                            />
                            <About
                                title="プレスリリース"
                                img={about1}
                                about="新商品・サービスだけでなく、既存商品・サービス、トレンド情報など、バリエーション豊かな情報が満載。報道関係者だけのクローズドサイトのため、記者発表会の案内や読者プレゼント提供商品情報など、他のプレスリリースサイトでは得られない情報も閲覧可能です。"
                            />
                        </Box>
                    </Stack>
                </Stack>
            </>)
        },
    },
    ResetPassword: {
        Header() {
            return (
                <Stack>
                    <Header title="パスワード再発行" />
                    <Body>
                        ご登録のメールアドレスを入力してください。<br />
                        パスワード再設定のためのコードをお送りします。
                    </Body>
                </Stack>
            )
        },
    },
}

export const formFields = {
    signIn: {
        username: {
            labelHidden: true,
            isRequired: true,
        },
        password: {
            labelHidden: true,
            isRequired: true,
        },
    },
    resetPassword: {
        username: {
            labelHidden: true,
            isRequired: true,
        },
    },
}


