import { format } from 'date-fns'
import { TitledBlock } from 'components/Block'
import PaginationList from 'components/PaginationList'
import CommonList from './CommonList'
import { useFeedbackHistories } from 'hooks/feedback'
import { Body } from 'components/styledFonts'

const FeedbackHistories = () => {
    const { data } = useFeedbackHistories()

    return (
        <TitledBlock
            title="フィードバック履歴"
        >
            {data?.length > 0 ?
            <PaginationList
                max={3}
                gap={3}
                data={data.map((d, i) => (
                    <CommonList
                        key={i}
                        title={d.タイトル}
                        mainPict={d.サムネイル}
                        client={d.企業名}
                        date={format(d.クライアント公開日時, 'yyyy/MM/dd HH:mm')}
                        to={`/feedback/history/${d.フィードバックNo}/${d.path}`}
                    />
                ))}
            />
            :
            <Body>フィードバック履歴の情報はありません</Body>
            }
        </TitledBlock>
    )
}

export default FeedbackHistories
