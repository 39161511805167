import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ListObjectsCommand } from '@aws-sdk/client-s3'
import { addHours, isAfter } from 'date-fns'
import { createS3Client } from 'lib/common'
import { API } from 'lib/api'
import { apiName } from 'config/api'


// 企業が発信した情報用のhook

//------------------------------------------------------------------------
// プレスリリース
//------------------------------------------------------------------------
const getPressRelease = async (no) => {
    const ret = await API.get(apiName, `/items/getPressReleaseByNo?no=${no}`, {})
    ret.公開日 = new Date(ret.リリース日時)
    return ret
}

export const usePressReleaseByNo = (no) => {
    return useQuery(['pressreleaseByNo', no], () => getPressRelease(no))
}


//------------------------------------------------------------------------
// ニュースレター
//------------------------------------------------------------------------
const getNewsLetter = async (no) => {
    const ret = await API.get(apiName, `/items/getNewsLetterByNo?no=${no}`, {})
    ret.公開日 = new Date(ret.公開日)
    return ret
}

export const useNewsLetterByNo = (no) => {
    return useQuery(['newsletterByNo', no], () => getNewsLetter(no))
}


//------------------------------------------------------------------------
// 企業探訪
//------------------------------------------------------------------------
export const cate = [
    '社長ストーリー',
    'こんな会社',
    'イチオシ！',
]

const getInquiry = async (no) => {
    const ret = await API.get(apiName, `/items/getInquiryByNo?no=${no}`, {})
    ret.公開日 = new Date(ret.公開日)
    return ret
}

export const useInquiryByClientId = (id) => {
    return useQuery(['post', id], () => getPosts(id), {
        select : data => data
            .map(d => ({ ...d, メインイメージ: d.サムネイル }))
            .filter(d => d.カテゴリ)
            .reduce((o, d) => ({ ...o, [d.カテゴリ] : d }), {})
    })
}

export const useOtherInquiries = (id, no) => {
    return useQuery(['post', id], () => getPosts(id), {
        select : data => data
            .map(d => ({ ...d, メインイメージ: d.サムネイル }))
            .filter(d => d.カテゴリ)
            .filter(d => d.no !== no)
            .reduce((o, d) => ({ ...o, [d.カテゴリ] : d }), {})
    })
}

export const useInquiryByNo = (no) => {
    return useQuery(['inquiryByNo', no], () => getInquiry(no))
}

//------------------------------------------------------------------------
// 対象企業のプレスリリース・その他
//------------------------------------------------------------------------
const getPosts = async (id) => {
    const ret = (await API.get(apiName, `/items/getPostsById?id=${id}`, {}))
        .map(r => ({
            ...r,
            公開日: new Date(r.公開日),
            newly : isAfter(addHours(new Date(r.公開日), 48), new Date()),
        }))
    return ret
}

export const usePostsOfClient = (id, no) => {
    return useQuery(['post', id], () => getPosts(id), {
        select: data => data
            .filter(d => d.no !== no)
            .sort((a, b) => b.公開日 - a.公開日),
    })
}


//------------------------------------------------------------------------
// 報道資料画面用
//------------------------------------------------------------------------
// URLから報道資料のタイプを抽出
export const useType = () => {
    const loc = useLocation()
    return (loc.pathname.match(/\/(pressrelease|newsletter|inquiry|entry)\//) || [])[1]
}

// 報道資料のダウンロード対象フィールド一覧
const downloadFields = [
    'メインイメージ',
    'メイン画像',
]
.concat([...Array(4).keys()].flatMap(n => [
    `メイン画像${n + 1}`,
    `画像${n + 1}`,
    `イメージ${n + 1}`,
]))
.concat(['PDF'])
.concat([...Array(10).keys()].flatMap(n => [
    `添付資料${n + 1}`,
]))

// ダウンロード情報
export const useDownloads = (data) => {
    const [downloads, setDownloads] = useState([])

    useEffect(() => {(async () => {
        if (!data) return

        // 報道資料データからダウンロード対象フィールドを抽出
        const downloads = downloadFields
            .filter(f => data[f])
            .map(f => {
                const [, bucket, prefix, key] = data[f].match(/com\/([^/]+)\/(.+)\/([^/]+$)/)
                return {
                    url : data[f],
                    bucket : bucket,
                    prefix : prefix,
                    key : key,
                    original : data[`${f}オリジナルファイル名`] || key,
                }
            })

        // 念のため異なるプレフィクスに登録されている場合を考慮してオブジェクト一覧を取得
        const s3 = await createS3Client()
        const ret = await Promise.all([...new Set(downloads.map(d => d.prefix))].map(p => 
            s3.send(new ListObjectsCommand({
                Bucket : downloads[0].bucket,
                Prefix : p,
            })))
        )

        // オブジェクト一覧のサイズを反映
        ret.flatMap(r => r.Contents || []).forEach(c => {
            const target = downloads.find(d => `${d.prefix}/${d.key}` === c.Key)
            const kb = Math.round(c.Size / 1024)
            const mb = Math.round(kb / 1024)
            if (target) target.size = kb < 1024 ? `${kb}KB` : `${mb}MB`
        })

        setDownloads(downloads)

    })()}, [data])


    return downloads
}


//------------------------------------------------------------------------
// トップページ画面用
//------------------------------------------------------------------------
const getNewEnterprise = async () => {
    const ret = (await API.get(apiName, `/items/getNewEnterprise`, {}))
        .map(r => ({
            ...r,
            公開日: new Date(r.公開日),
            newly : isAfter(addHours(new Date(r.公開日), 48), new Date()),
        }))
    return ret
}

export const useNewEnterpriseList = () => {
    return useQuery(['newEnterprise'], getNewEnterprise, {
        select: data => data
            .filter(r => r.newly)
            .slice(0, 8)
    })
}
