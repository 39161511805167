import { useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { format } from 'date-fns'
import { Body, H2, H5 } from 'components/styledFonts'
import Block from 'components/Block'
import { useFeedbackByNo } from 'hooks/feedback'


const border = `solid 1px ${grey[400]}`

const LV = ({ label, value, last }) => (
    <Stack
        direction="row"
        borderBottom={last ? border : 0}
    >
        <Body
            sx={{
                width : 150,
                flexShrink : 0,
                py : 0.5,
                px : 1,
                borderTop : border,
                borderLeft : border,
                bgcolor : grey[100],
            }}
        >
            {label}
        </Body>
        <Body
            sx={{
                width : "100%",
                py : 0.5,
                px : 1,
                borderTop : border,
                borderLeft : border,
                borderRight : border,
            }}
        >
            {value}
        </Body>
    </Stack>
)

const TV = ({ title, value }) => (
    <Stack>
        <H5>{title}</H5>
        <Body
            sx={{ whiteSpace : "pre-wrap" }}
        >
            {value}
        </Body>
    </Stack>
)

const FeedbackResult = () => {
    const { fbno } = useParams()
    const { data } = useFeedbackByNo(fbno)

    if (!data) return null

    const isPublished = data.クライアント公開

    return (
        <Block>
            <Stack
                gap={2}
            >
                <Stack>
                    <H2>{isPublished ? "公開済みのフィードバック" : "ネタもと確認中のフィードバック"}</H2>
                    {isPublished ?
                    <Body>公開日：{format(data.クライアント公開日時, 'yyyy/MM/dd HH:mm')}</Body>
                    :
                    <Body>投稿日：{format(data.メディア投稿日時, 'yyyy/MM/dd HH:mm')}</Body>
                    }
                </Stack>
                <Stack
                    gap={3}
                    sx={{ wordBreak : "break-word" }}
                >
                    <Stack>
                        <H5>レビュアーのプロフィール</H5>
                        <Stack>
                            <LV label="メディアカテゴリー" value={data.メディアカテゴリー} />
                            <LV label="メディアジャンル" value={data.メディアジャンル} />
                            <LV label="媒体名" value={data.媒体名} />
                            <LV label="ステータス" value={data.担当者ステータス} />
                            <LV label="職種" value={data.職種} last />
                        </Stack>
                    </Stack>
                    <Stack>
                        <H5>総評</H5>
                        <Stack>
                            <LV label="見出し" value={data.総評_見出し} />
                            <LV label="文章・構成" value={data.総評_文章構成} />
                            <LV label="ネタ切り口" value={data.総評_ネタ切り口} />
                            <LV label="社会性／時事性" value={data.総評_社会性時事性} />
                            <LV label="エビデンス" value={data.総評_エビデンス} last />
                        </Stack>
                    </Stack>
                    <TV title="①担当メディアで取り上げる可能性" value={data.可能性} />
                    {isPublished ?
                    <>
                    <TV title="②興味を惹かれた点" value={data.興味を惹かれた点_クライアント公開用} />
                    <TV title="③興味を持てなかった点" value={data.興味を持てなかった点_クライアント公開用} />
                    <TV title="④欲しい追加情報や素材、確認したい事項" value={data.欲しい追加情報_クライアント公開用} />
                    <TV title="⑤メディアに取り上げられるためのアドバイス" value={data.アドバイス_クライアント公開用} />
                    <TV title="⑥その他・所感など" value={data.その他_クライアント公開用} />
                    <TV title="公開日時" value={format(data.クライアント公開日時, 'yyyy/MM/dd HH:mm')} />
                    </>
                    :
                    <>
                    <TV title="②興味を惹かれた点" value={data.興味を惹かれた点_メディア起票時} />
                    <TV title="③興味を持てなかった点" value={data.興味を持てなかった点_メディア起票時} />
                    <TV title="④欲しい追加情報や素材、確認したい事項" value={data.欲しい追加情報_メディア起票時} />
                    <TV title="⑤メディアに取り上げられるためのアドバイス" value={data.アドバイス_メディア起票時} />
                    <TV title="⑥その他・所感など" value={data.その他_メディア起票時} />
                    <TV title="投稿日時" value={format(data.メディア投稿日時, 'yyyy/MM/dd HH:mm')} />
                    </>
                    }
                </Stack>
            </Stack>
        </Block>
    )
}

export default FeedbackResult
