import { Stack, useMediaQuery } from '@mui/material'
import { H5Overflow, Body } from 'components/styledFonts'
import { GreyLabel, BlueLabel } from 'components/styledLabels'
import PaginationList from 'components/PaginationList'
import { useFeedbackRequest } from 'hooks/feedback'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'
import { format } from 'date-fns'

const FeedbackRequest = () => {
    const { data } = useFeedbackRequest()
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    return (<>
        {data?.length > 0 ?
        <PaginationList
            max={isSm ? 5 : 10}
            gap={3}
            data={data.map((d, i) => (
                <Stack
                    key={i}
                    gap={1}
                >
                    <Stack
                        direction="row"
                        columnGap={1}
                        rowGap={1}
                        flexWrap="wrap"
                    >
                        <GreyLabel>募集開始日:{format(d.メディア公開日時, 'yyyy/MM/dd HH:mm')}</GreyLabel>
                        <BlueLabel>{d.カテゴリ}</BlueLabel>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        gap={2}
                    >
                        <Thumbnail
                            newly={d.新着}
                            src={d.サムネイル}
                            to={`/feedback/input/${d.フィードバックNo}/${d.path}`}
                        />
                        <Stack
                            justifyContent="space-between"
                            gap={1}
                            minHeight={64}
                        >
                            <UnderlineLink
                                to={`/feedback/input/${d.フィードバックNo}/${d.path}`}
                            >
                                <H5Overflow>{d.タイトル}</H5Overflow>
                            </UnderlineLink>
                            <Body>{d.企業名}</Body>
                        </Stack>
                    </Stack>
                </Stack>
            ))}
        />
        :
        <Body>フィードバック依頼中の情報はありません</Body>
        }
    </>)
}

export default FeedbackRequest
