import { useMediaQuery } from '@mui/material'
import { useSetAtom } from 'jotai'
import { draftResearchNo, researchModal } from 'jotaiAtoms'
import { MenuBarButton } from 'components/styledButtons'

const ResearchButton = () => {
    const setOpen = useSetAtom(researchModal)
    const setDraft = useSetAtom(draftResearchNo)
    const isWide = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    if (!isWide) return null

    return (
        <MenuBarButton
            color="indigo"
            variant="contained"
            onClick={() => {
                setDraft(null)
                setOpen(true)
            }}
        >
            無料リサーチ
        </MenuBarButton>
    )
}

export default ResearchButton
