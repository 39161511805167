import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useDynamoItem } from 'hooks/dynamo'
import { useEffect, useState } from 'react'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getMessage', {})
    const updateRet = ret.map(r => {
        return {
            ...r,
            回答テーブル : r.回答テーブル.map(d => ({
                ...d,
                日時 : new Date(d.日時),
            })),
            日時 : new Date(r.日時),
        }
    })

    return updateRet
}

export const useReadFlags = () => {
    const { data : dynamo, isSuccess, dataUpdatedAt } = useDynamoItem("メッセージ既読履歴")
    const { data : messages } = useAllMessages()
    const [flags, setFlags] = useState(null)

    useEffect(() => {
        if (!isSuccess || !messages) return

        const f = messages.reduce((o, r) => {
            const count = dynamo?.find(d => d.レコード番号 === Number(r.$id))?.既読数 || 0
            return {
                ...o,
                [r.$id] : r.回答テーブル.map((t, i) => {
                    if (i + 1 > count) {     
                        return t.送信者 !== '媒体担当者'
                    } else {
                        return false
                    }
                })
            }
        }, {})

        setFlags(f)

    }, [dynamo, messages, isSuccess, dataUpdatedAt])

    return flags
}

export const useAllMessages = () => {
    return useQuery(['message'], getData)
}

export const useClientMessages = ( state, id ) => {
    return useQuery(['message'], getData, {
        select : (data) => {
            const cmsg = data.filter(d => d.メッセージ区分 === '企業・メディア間')
            if (id && id !== 'support' && !cmsg.find(c => c.企業ID === id)) {
                return [{
                    "メッセージ区分": "企業・メディア間",
                    "企業ID": id,
                    "会社名": state.会社名,
                    "媒体名": "",
                    "媒体担当者_姓": "",
                    "媒体担当者_名": "",
                    "回答テーブル": [],
                    "会社ロゴ": state.会社ロゴ,
                    "日時": ""
                }].concat(cmsg)
            }
            return cmsg
        }
    })
}

export const useSupportMessages = () => {
    return useQuery(['message'], getData, {
        select : (data) => {
            const cmsg = data.filter(d => d.メッセージ区分 === 'メディア・ネタもとサポート間')
            if (cmsg.length === 0) {
                return [{
                    "メッセージ区分": "",
                    "媒体名": "",
                    "媒体担当者_姓": "",
                    "媒体担当者_名": "",
                    "回答テーブル": [],
                    "会社ロゴ": "",
                    "日時": ""
                }]
            }
            return cmsg
        }
    })
}

export const useNewMessageCount = () => {
    const flags = useReadFlags()

    return Object.keys(flags || {}).reduce((a, n) => a + (flags[n] || []).filter(f => f).length, 0)
}

export const useNewMessages = () => {
    const { data : dynamo, isSuccess, dataUpdatedAt } = useDynamoItem("メッセージ既読履歴")
    const { data : messages } = useAllMessages()
    const [newMessage, setNewMessage] = useState([])
    
    useEffect(() => {
        const newData = []
        if (!isSuccess || !messages) return
        
        messages.forEach(m => {
            const count = dynamo?.find(d => d.レコード番号 === Number(m.$id))?.既読数 || 0

            m.回答テーブル.forEach((t, i) => {
                if (i + 1 > count && (t.送信者 !== '媒体担当者')) {
                    newData.push({ ...t, "企業ID": m.企業ID })
                }
            })
        })

        setNewMessage(newData.sort((a, b) => b.日時 - a.日時))

    }, [dynamo, messages, isSuccess, dataUpdatedAt])

    return newMessage
}
