import { Divider, Stack, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import Block from 'components/Block'
import { H1, H5Overflow, BodyLH1 } from 'components/styledFonts'
import { WhiteLabel, BlueLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import PaginationList from 'components/PaginationList'
import Thumbnail from 'components/Thumbnail'
import { useBroweHistoryList } from 'hooks/browsehistory'

const BrowseHistoryList = () => {
    const data = useBroweHistoryList()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    return (
        <Stack
            gap={2}
        >
            <H1>閲覧履歴</H1>
            <Block
                sx={{
                    py : { mobile : 3, xs : 2 },
                    pl : { mobile : 4, xs : 2 },
                    pr : { mobile : 6, xs : 2 },
                }}
            >
                <PaginationList
                    max={10}
                    gap={2}
                    data={data?.map((d, i) => (
                        <Stack
                            key={i}
                            gap={2}
                        >
                            <Stack
                                direction="row"
                                alignItems="flex-start"
                                gap={2}
                            >
                                <Thumbnail
                                    src={d.サムネイル}
                                    to={d.path}
                                    width={isMobile ? 99 : 120}
                                    height={isMobile ? 66 : 80}
                                >
                                </Thumbnail>
                                <Stack
                                    minWidth={0}
                                    sx={{ gap : { mobile : 2, xs : 0.5 } }}
                                >
                                    <UnderlineLink
                                        to={d.path}
                                    >
                                        <H5Overflow>
                                            {d.タイトル}
                                        </H5Overflow>
                                    </UnderlineLink>
                                    <Stack
                                        direction="row"
                                        columnGap={1}
                                        rowGap={0.5}
                                        alignItems="center"
                                        flexWrap="wrap"
                                    >
                                        <BlueLabel>{d.type}</BlueLabel>
                                        <BodyLH1>{format(d.公開日, isMobile ? 'MM/dd HH:mm' : 'yyyy/MM/dd HH:mm')}</BodyLH1>
                                        <WhiteLabel>{d.会社名}</WhiteLabel>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Divider />
                        </Stack>
                    ))}
                />
            </Block>
        </Stack>
    )
}

export default BrowseHistoryList
