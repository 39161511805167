import { Stack } from '@mui/material'
import { H4 } from 'components/styledFonts'

const color = '#3ebe5c'

const TemplateTitle = ({ text }) => {

    return (
        <Stack
            border={`2px solid ${color}`}
            alignItems="center"
            columnGap={0.5}
            sx={{
                flexDirection : { mobile : "row", xs : "column" },
                height : { mobile : 70, xs : "auto" },
                ml : { mobile : 3.5, xs : 0 },
                mr : { mobile : 2, xs : 0 },
            }}
        >
            <H4
                textAlign="center"
                backgroundColor="#3ebe5c"
                color="#fff"
                sx={{
                    width : { mobile : "auto", xs : "100%" },
                    py : { mobile : 1.5, xs : 0.5 },
                    px : { mobile : 2.5, xs : 0 },
                    ml : { mobile : -3.5, xs : 0 },
                }}
            >
                募集のテーマ
            </H4>
            <H4
                color={color}
                p={1}
                sx={{
                    width : { mobile : "auto", xs : "100%" },
                }}
            >
                {text}
            </H4>
        </Stack>
    )
}

export default TemplateTitle
