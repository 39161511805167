import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useQueryClient } from '@tanstack/react-query'
import { addMinutes, isBefore } from 'date-fns'
import { loadedAtom, loadedComAtom } from 'store/commonAtoms'
import { useDynamoItem } from './dynamo'
import { useDynamoMutation } from './mutation/useDynamoMutation'


const types = {
    pressrelease : { type : 'プレスリリース', key : 'リリースNo' },
    newsletter   : { type : 'ニュースレター', key : 'ネタNo' },
    inquiry      : { type : '企業探訪'      , key : '企業探訪No' },
}

const reg = new RegExp(`^/post/(${Object.keys(types).join('|')})/`)


// 対象ページアクセス時の閲覧履歴登録
export const useRegisterHistory = () => {
    const { data : hist={}, isSuccess } = useDynamoItem('閲覧履歴')
    const { mutate } = useDynamoMutation('閲覧履歴')
    const queryClient = useQueryClient()
    const { pathname } = useLocation()
    const params = useParams()
    const [loaded, setLoaded] = useAtom(loadedAtom)
    const [loadedCom, setLoadedCom] = useAtom(loadedComAtom)


    useEffect(() => {
        if (!isSuccess && !loaded && !loadedCom) return
        setLoaded(false)
        setLoadedCom(false)

        // 対象ページか判定
        const [match, type] = pathname.match(reg) || []
        if (!match) return

        // キャッシュより参照ページのリソース取得
        const keyName = types[type].key
        const data = queryClient.getQueryData([`${type}ByNo`, params.no])
        if (!data) return
        const mypage = queryClient.getQueryData([`companyById`, data.企業ID])

        if (hist[data[keyName]]) {
            // 閲覧履歴に存在するページへのアクセス
            // 無限ループ回避のため、前回の閲覧日時が1分以内の場合は更新をスキップ
            if (isBefore(new Date(), addMinutes(new Date(hist[data[keyName]].閲覧日時), 1))) return
        }
        else {
            // 初めてアクセスするページの場合、閲覧履歴数の調整を行う
            const keys = Object.keys(hist)
            if (keys.length >= 100) {
                const delKey = keys.sort((a, b) => new Date(hist[a].閲覧日時) - new Date(hist[b].閲覧日時))[0]
                delete hist[delKey]
            }
        }

        // DynamoDBの更新
        mutate({
            content : {
                ...hist,
                [data[keyName]] : {
                    タイトル : data.タイトル,
                    サムネイル : data.サムネイル,
                    type : data.カテゴリ === '社長ストーリー' ? '社長ストーリー' : types[type].type,
                    会社名 : mypage?.会社名,
                    公開日 : data.公開日,
                    閲覧日時 : new Date(),
                    path : pathname,
                },
            }
        })
    }, [pathname, params, hist, isSuccess, mutate, queryClient, loaded, setLoaded, loadedCom, setLoadedCom])
}


// 閲覧履歴取得
export const useBroweHistoryList = (id) => {
    const { data : hist={} } = useDynamoItem('閲覧履歴')

    return Object.values(hist)
        .map(h => ({
            ...h,
            閲覧日時 : new Date(h.閲覧日時),
            公開日 : new Date(h.公開日),
        }))
        .sort((a, b) => b.閲覧日時 - a.閲覧日時)
}

