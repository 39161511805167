import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Pagination, Stack } from '@mui/material'
import {
    TabsUnstyled as Tabs,
    TabsListUnstyled as TabList,
    TabPanelUnstyled as TabPanel,
} from '@mui/base'
import { H1, H2, H5 } from 'components/styledFonts'
import { EntryMessageButton } from 'components/styledButtons'
import { useClientMessages, useSupportMessages, useReadFlags } from 'hooks/message'
import Block from 'components/Block'
import Scroll from 'components/Scroll'
import ContactTab from './ContactTab'
import SupportTab from './SupportTab'
import History from './History'
import Entry from './Entry'
import SubButton from './SubButton'

const HistryTabPanel = ({ value, data, setScroll }) => (
    <TabPanel
        value={value}
    >
        {data.日時 &&
        <Block
            sx={{ pt : 1 }}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={2}
                pb={2}
            >
                <H2>メッセージ履歴</H2>
                <EntryMessageButton
                    label="メッセージ新規作成"
                    onClick={() => setScroll(true)}
                />
            </Stack>
            <History
                message={data}
            />
        </Block>
        }
    </TabPanel>
)

const MailBox = () => {
    const [page, setPage] = useState(1)
    const { state } = useLocation()
    const { id } = useParams()
    const { data : messages } = useClientMessages(state, id)
    const { data : support } = useSupportMessages()
    const [value, setValue] = useState(0)
    const [scroll, setScroll] = useState(false)
    const flags = useReadFlags()
    const navi = useNavigate()

    const handleChange = (e, p) => {
        const idx = messages[0].回答テーブル.length !== 0 ? (p - 1) * max : (p - 1) * max + 1
        navi(`/message/${messages[idx]?.企業ID}`)
    }

    useEffect(() => {
        if (!messages || !support) return

        if (id) {
            if (id === "support") {
                setValue(9999)
            }
            else {
                const index = messages.findIndex(m => m.企業ID === id)
                setPage(Math.floor(index / max) + 1)
                setValue(id)
            }
        }
        else {
            setValue(messages[0]?.企業ID || 0)
            setPage(1)
        }
    }, [messages, support, id])

    const max = 5
    const startIndex = (page - 1) * max
    
    return (
        <Stack
            gap={2}
        >
            <H1>メッセージボックス</H1>
            <Tabs
                value={value}
                onChange={(e, v) => { navi( v === 9999 ? `/message/support` : `/message/${v}`)}}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                >
                    <Stack
                        width={300}
                        flexShrink={0}
                    >
                        <H2>送信履歴</H2>
                        <TabList>
                            <Stack gap={1.5}>
                                {messages?.slice(startIndex, startIndex + max).map((d, i) => (
                                <ContactTab
                                    key={i}
                                    value={d.企業ID}
                                    count={flags?.[d.$id]?.filter(f => f).length}
                                    date={d.日時}
                                    logo={d.会社ロゴ}
                                    name={d.会社名}
                                />
                                ))}
                                <Stack alignItems="center">
                                    {messages && 
                                    <Pagination
                                        count={Math.floor(messages.length / max) + (messages.length % max ? 1 : 0)}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handleChange}
                                        page={page}
                                    />}
                                </Stack>
                                {support?.map((d, i) => (
                                <SupportTab
                                    key={i}
                                    count={flags?.[d.$id]?.some(f => f)}
                                    value={9999}
                                />
                                ))}
                                <SubButton />
                            </Stack>
                        </TabList>
                    </Stack>
                    <Stack
                        flexGrow={1}
                    >
                        {messages?.map((d, i) => (
                        <HistryTabPanel
                            key={i}
                            value={d.企業ID}
                            data={d}
                            setScroll={setScroll}
                        />
                        ))}
                        {support?.map((d, i) => (
                        <HistryTabPanel
                            key={i}
                            value={9999}
                            data={d}
                            setScroll={setScroll}
                        />
                        ))}
                        {messages?.map((d, i) => (
                        <TabPanel
                            key={i}
                            value={d.企業ID}
                        >
                            <Entry
                                entryMessage={d}
                            />
                        </TabPanel>
                        ))}
                        {support?.map((d, i) => (
                        <TabPanel
                            key={i}
                            value={9999}
                        >
                            <Entry
                                entryMessage={d}
                            />
                        </TabPanel>
                        ))}
                        {!messages?.length && value !== 9999 &&
                        <Stack
                            minHeight={300}
                        >
                            <Block
                                sx={{ pt : 1 }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    pb={2}
                                >
                                    <H2>メッセージ履歴</H2>
                                </Stack>
                                <H5>メッセージ履歴はありません</H5>
                            </Block>
                        </Stack>
                        }
                        <Scroll scroll={scroll} setScroll={setScroll} block="end" />
                    </Stack>
                </Stack>
            </Tabs>
        </Stack>
    )
}

export default MailBox
