import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api.js'
import { apiName } from 'config/api'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getMediaUser', {})
    return ret
}

export const useLoginUser = () => {
    return useQuery(['loginuser'], getData)
}



