import { Stack, Divider } from '@mui/material'
import { format } from 'date-fns'
import { Body, H5Overflow } from 'components/styledFonts'
import { BlueLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'


const PostListItem = ({ data }) => {

    return (
        <Stack
            gap={2}
        >
            <Stack
                direction="row"
                alignItems="flex-start"
                gap={2}
            >
                <Thumbnail
                    src={data.サムネイル}
                    to={data.to}
                    newly={data.newly}
                />
                <Stack
                    gap={1}
                >
                    <H5Overflow>
                        <UnderlineLink
                            to={data.to}
                        >
                            {data.タイトル}
                        </UnderlineLink>
                    </H5Overflow>
                    <Stack
                        gap={1}
                        sx={{
                            flexDirection : { mobile : "row", xs : "column" },
                            alignItems : { mobile : "center", xs : "flex-start" },
                        }}
                    >
                        <BlueLabel>{data.種類 === 'プレスリリース' ? data.種類 : 'その他'}</BlueLabel>
                        <Body>{format(data.公開日, 'yyyy/MM/dd HH:mm')}</Body>
                    </Stack>
                </Stack>
            </Stack>
            <Divider />
        </Stack>
    )
}

export default PostListItem
