import { apiName } from 'config/api'
import { useAtom, useAtomValue } from 'jotai'
import { API } from 'lib/api'
import { useEffect, useState } from 'react'
import { searchCondAtom, searchPageAtom, searchTriggerAtom } from 'store/commonAtoms'


export const useSearch = (target) => {
    const [query, setQuery] = useState([])
    const [trigger, setTrigger] = useAtom(searchTriggerAtom)
    const cond = useAtomValue(searchCondAtom)
    const page = useAtomValue(searchPageAtom)

    useEffect(() => {
        if (trigger) {
            (async () => {
                const ret = await API.post(apiName, `/items/get${target}`, {
                    body: {
                        cond: cond,
                        offset: (page - 1) * 10,
                    }
                })
                ret.totalCount = Number(ret.totalCount)
                ret.records = ret.records.map(r => {
                    const to = target === 'PressReleases' ? `/post/pressrelease/${r.取引先ID}/${r.リリースNo}`
                        : target === 'NewsLetters' ? `/post/newsletter/${r.クライアント企業ID}/${r.ネタNo}`
                        : target === 'Inquiries' ? `/post/inquiry/${r.企業ID}/${r.企業探訪No}`
                        : `/company/${r.企業ID}`
                    return {
                        ...r,
                        公開日: new Date(r.公開日),
                        to: to,
                    }
                })
                ret.isLoading = false
                setQuery(ret)
                setTrigger(false)
            })()
        }

    }, [trigger, cond, page, target, setTrigger])

    return query
}
