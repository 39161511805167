import { Link, useParams } from 'react-router-dom'
import { Stack, useMediaQuery } from '@mui/material'
import { H1 } from 'components/styledFonts'
import { LinkButtonLarge } from 'components/styledButtons'
import Block from 'components/Block'
import PaginationList from 'components/PaginationList'
import PostListItem from './PostListItem'
import SendMessageButton from './SendMessageButton'
import { useCompanyById } from 'hooks/company'
import { usePostsOfClient } from 'hooks/post'


const CompanyPosts = () => {
    const { id } = useParams()
    const { data : company } = useCompanyById(id)
    const { data: list } = usePostsOfClient(id)
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    if (!company || !list) return

    return (
        <Stack
            gap={2}
        >
            <H1>{`${company.会社名}のプレスリリース・その他`}</H1>
            <Block
                sx={{ minHeight : 500 }}
            >
                <Stack
                    py={1}
                    sx={{ px : { mobile : 2, xs : 0 } }}
                >
                    <PaginationList
                        max={10}
                        gap={2}
                        siblingCount={isMobile ? 0 : 1}
                        data={list.map((d, i) => (
                            <PostListItem
                                key={i}
                                data={d}
                            />
                        ))}
                    />
                </Stack>
            </Block>
            <Stack
                direction="row"
                justifyContent="center"
                flexWrap="wrap"
                columnGap={4}
                rowGap={3}
                py={2}
            >
                <SendMessageButton />
                <LinkButtonLarge
                    component={Link}
                    to={`/company/${id}`}
                >
                    企業詳細
                </LinkButtonLarge>
            </Stack>
        </Stack>
    )
}

export default CompanyPosts
