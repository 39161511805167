import { useEffect, useRef } from 'react'

const Scroll = ({ scroll, setScroll, block='start' }) => {
    const ref = useRef(null)

    useEffect(() => {
        if (!scroll) return 

        if (block === 'end') {
            ref?.current?.scrollIntoView({
                behavior : 'smooth',
                block : block,
            })
        }
        else {
            const top = ref?.current?.getBoundingClientRect().top
            window.scrollTo({
                top : top - 80,
                left :0,
                behavior : 'smooth',
            })
        }

        setScroll(false)
    }, [scroll, setScroll, block])

    return <div ref={ref} />
}

export default Scroll
