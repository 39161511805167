import { IconButton, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import FolderZipIcon from '@mui/icons-material/FolderZip'
import { zipSync } from 'fflate'
import { saveAs } from 'file-saver'
import { format } from 'date-fns'
import { Body } from 'components/styledFonts'
import { LinkButtonMiddle } from 'components/styledButtons'
import { TitledBlock } from 'components/Block'
import S3Image from 'components/S3Image'
import { useDownloads } from 'hooks/post'


// 個別ダウンロードハンドラ
const handleDownload = async (url, name) => {
    // chromeのcacheによりCORSエラーとなる可能性があるため、タイムスタンプを付与
    const blob = await fetch(`${url}?=${new Date().getTime()}`).then(r => r.blob())
    saveAs(blob, name)
}

// 一括ダウンロードハンドラ
const handleBatchDownload = async (downloads) => {
    const ret = await Promise.all(
        downloads.map(d => fetch(`${d.url}?=${new Date().getTime()}`).then(r => r.arrayBuffer()))
    )

    // zip化
    const zip = zipSync(ret.reduce((o, r, i) => ({ ...o, [downloads[i].original] : new Uint8Array(r) }), {}))

    saveAs(new Blob([zip]), `${format(new Date(), 'yyyyMMddHHmmss')}.zip`)
}

const MaterialIcon = ({ url, original }) => (
    <Stack
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
        width={46}
        height={46}
        bgcolor={grey[100]}
        border={`1px solid ${grey[300]}`}
    >
        {/pdf$/.test(original) ?
        <PictureAsPdfIcon fontSize="large" />
        :
        /zip$/.test(original) ?
        <FolderZipIcon fontSize="large" />
        :
        <S3Image
            url={url}
            objectFit="cover"
        />
        }
    </Stack>
)

const DownloadItem = ({ data }) => (
    <Stack
        direction="row"
        alignItems="center"
        gap={1}
    >
        <MaterialIcon
            url={data.url}
            original={data.original}
        />
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
        >
            <Body
                sx={{
                    //width : 220,
                    wordBreak : "break-all"
                }}
            >
                {`${data.original}／${data.size}`}
            </Body>
            <IconButton
                onClick={() => handleDownload(data.url, data.original)}
            >
                <DownloadForOfflineIcon />
            </IconButton>
        </Stack>
    </Stack>
)

const DownloadMaterials = ({ data }) => {
    const downloads = useDownloads(data)

    if (downloads.length === 0) return null

    return (
        <TitledBlock
            title="素材ダウンロード"
            sx={{ width : "100%" }}
        >
            <Stack
                gap={2}
            >
                <Stack
                    gap={2}
                    pl={1}
                >
                    {downloads.map((d, i) => (
                    <DownloadItem
                        key={i}
                        data={d}
                    />
                    ))}
                </Stack>
                <Stack
                    alignItems="center"
                >
                    <LinkButtonMiddle
                        onClick={() => handleBatchDownload(downloads)}
                    >
                        一括ダウンロード
                    </LinkButtonMiddle>
                </Stack>
            </Stack>
        </TitledBlock>
    )
}

export default DownloadMaterials
