import { Link, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { Body, H1 } from 'components/styledFonts'
import { BlockButton, BlueOutlinedButton } from 'components/styledButtons'
import { TitledBlock } from 'components/Block'
import CompanyInfo from 'components/CompanyInfo'
import DownloadMaterials from 'components/DownloadMaterials'
import ResearchEntry from 'components/ResearchEntry'
import TwoColumns from 'components/TwoColumns'
import { useEntryByNo, useResearchByNo } from 'hooks/research'
import { useDynamoItem } from 'hooks/dynamo'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'
import EntryListItem from './EntryListItem'
import { useEffect } from 'react'


const EntryDetail = () => {
    const { requestNo, entryNo } = useParams()
    const { data : entry } = useEntryByNo(requestNo, entryNo)
    const { data : request } = useResearchByNo(requestNo)
    const { data : dynamoData = [], isSuccess } = useDynamoItem("エントリー既読一覧")
    const { mutate : mutateDynamo } = useDynamoMutation("エントリー既読一覧")

    useEffect(() => {
        if (!isSuccess) return

        if (!dynamoData.includes(entryNo)) {
            const timeId = setTimeout(() => {
                mutateDynamo({ content: [...new Set([...dynamoData, entryNo])] })
            }, 2000)
            return () => clearTimeout(timeId)
        }
    }, [isSuccess, entryNo, dynamoData, mutateDynamo])
     
    const contact = entry?.問い合わせ担当者_上書き ?
        `${entry?.問い合わせ担当者部署_上書き}　${entry?.問い合わせ担当者_上書き}` : ''

    const otherEntry = request?.エントリー.filter(e => e.エントリーNo !== entryNo)

    return (
        <Stack
            gap={2}
        >
            <H1>エントリー詳細</H1>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <ResearchEntry 
                    data={entry}
                />
                <Stack
                    alignSelf="start"
                    gap={3}
                >
                    <CompanyInfo
                        contact={contact}
                    />
                    <Stack
                        width="100%"
                        alignItems="center"
                    >
                        <BlueOutlinedButton
                            component={Link}
                            to={`/print/${entry?.クライアント企業ID}/entry/${entryNo}`}
                            target="_blank"
                            label="印刷"
                            sx={{
                                width: 280,
                                height: 46,
                            }}
                        />
                    </Stack>
                    <DownloadMaterials
                        data={entry}
                    />
                    <TitledBlock
                        title="他のエントリー"
                    >
                        {otherEntry?.length > 0 ?
                        <Stack
                            px={1}
                            rowGap={3}
                        >
                        {otherEntry?.slice(0, 5).map((e, i) => (
                        <EntryListItem
                            key={i}
                            data={e}
                        />
                        ))}
                        </Stack>
                        :
                        <Body>他のエントリーはありません</Body>
                        }
                        <Stack
                            alignItems="flex-end"
                        >
                            <BlockButton
                                to={`/research/detail/${requestNo}`}
                                label="エントリー一覧"
                            />
                        </Stack>
                    </TitledBlock>
                </Stack>
            </TwoColumns>
        </Stack>
    )
}

export default EntryDetail
