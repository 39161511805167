import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import theme from './MyTheme'
import router from './router'
import { formFields, loginComponents } from 'login/loginComponents'
import 'login/i18nSettings'
import '@aws-amplify/ui-react/styles.css'
import 'login/login.css'

import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

const queryClient = new QueryClient({
    defaultOptions : {
        queries : {
            staleTime : Infinity,
        },
    },
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Authenticator
                hideSignUp={true}
                components={loginComponents}
                formFields={formFields}
            >
                {({ signOut, user }) => (
                <Authenticator.Provider>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                        {/*
                        <ReactQueryDevtools />
                        */}
                    </QueryClientProvider>
                </Authenticator.Provider>
                )}
            </Authenticator>
        </ThemeProvider>
    )
}

export default App
