import { Stack, useMediaQuery } from '@mui/material'
import { H4, Body } from 'components/styledFonts'
import { GroupA } from 'components/styledLabels'
import Thumbnail from 'components/Thumbnail'
import { UnderlineLink } from 'components/links'
import { useSearch } from 'hooks/search'
import SearchList from './SearchList'


const SearchCompanies = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))
    const query = useSearch('Companies')

    return (
        <SearchList
            query={query}
            dispRow={d => (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={3}
                >
                    <Stack
                        alignItems="center"
                        gap={1}
                    >
                        <Thumbnail
                            src={d.会社ロゴ}
                            width={isMobile ? 120 : 150}
                            to={`/company/${d.企業ID}`}
                        />
                        {(isMobile && d.都道府県) &&
                        <GroupA>{d.都道府県}</GroupA>
                        }
                    </Stack>
                    <Stack
                        gap={2}
                        flexGrow={1}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                        >
                            <H4>
                                <UnderlineLink
                                    to={`/company/${d.企業ID}`}
                                >
                                    {d.会社名 || d.企業名} 
                                </UnderlineLink>
                            </H4>
                            {(!isMobile && d.都道府県) &&
                            <GroupA>{d.都道府県}</GroupA>
                            }
                        </Stack>
                        <Body>{d.事業内容}</Body>
                    </Stack>
                </Stack>
            )}
        />
    )
}

export default SearchCompanies
