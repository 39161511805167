import { Stack } from '@mui/material'
import { BodyLH1, H5Overflow } from 'components/styledFonts'
import { RedLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'
import { useDynamoItem } from 'hooks/dynamo'
import { useCompanyById } from 'hooks/company'


const EntryListItem = ({ data }) => {
    const { data : company } = useCompanyById(data.クライアント企業ID)
    const { data : dynamoData = [] } = useDynamoItem("エントリー既読一覧")

    return (
        <Stack
            direction="row"
            gap={1.5}
        >
            <Thumbnail
                src={data.メイン画像}
                newly={!dynamoData.includes(data.エントリーNo)}
                to={`/research/entry/${data.リクエストNo}/${data.クライアント企業ID}/${data.エントリーNo}`}
            />
            <Stack
                gap={0.5}
                alignItems="flex-start"
            >
                {data.結果 === '成立'  &&
                <RedLabel>連絡先入手済み</RedLabel>
                }
                <H5Overflow>
                    <UnderlineLink
                        to={`/research/entry/${data.リクエストNo}/${data.クライアント企業ID}/${data.エントリーNo}`}
                    >
                        {data.タイトル}
                    </UnderlineLink>
                </H5Overflow>
                <BodyLH1>{company?.会社名}</BodyLH1>
            </Stack>
        </Stack>
    )
}

export default EntryListItem
