import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'


const getCompany = async (id) => {
    const ret = (await API.get(apiName, `/items/getCompanyById?id=${id}`, {}))
    ret.住所 = [[ret.都道府県, ret.市区町村, ret.町名_番地].filter(v => v).join(''), ret.ビル名].filter(v => v).join('　')
    ret.契約開始日 = new Date(ret.契約開始日)
    return ret
}

export const useCompanyById = (id) => {
    return useQuery(['companyById', id], () => getCompany(id))
}

