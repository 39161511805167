import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material' 
import { Body } from 'components/styledFonts'
import { LabeledTextField } from 'components/Inputs'
import Settings from './Settings'
import { Auth } from 'aws-amplify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAtom } from 'jotai'
import { messageAtom } from 'store/commonAtoms'
import { message } from 'config/message'

const defaultValues = {
    現在のパスワード : '',
    新しいパスワード : '',
    新しいパスワード確認用 : '',
}

const schema = yup.object().shape({
    現在のパスワード : yup
        .string()
        .required(message.REQUIRED)
    ,
    新しいパスワード : yup
        .string()
        .required(message.REQUIRED)
        .matches(/^([a-zA-Z0-9@#$%^&+=!]{6,})$/,
            { message : message.PASSWORD_VALIDATION })
    ,
    新しいパスワード確認用 : yup
        .string()
        .required(message.REQUIRED)
        .oneOf([yup.ref('新しいパスワード'), null], message.PASSWORD_MATCH_FAILED)
})

const MyPagePassword = () => {
    const { control, handleSubmit, reset } = useForm({ defaultValues : defaultValues, resolver: yupResolver(schema) })
    const [, setMessage] = useAtom(messageAtom)
    const [edit, setEdit] = useState(false)

    const onSubmit = async (d) => {
        try {
            // パスワード変更
            const user = await Auth.currentAuthenticatedUser()
            await Auth.changePassword(user, d.現在のパスワード, d.新しいパスワード)
            const msg = { type : 'info', text : message.OK_UPDATE_SUCCESS }
            setMessage(msg)
            reset()
        } catch (e) {
            const msg = { type : 'error', text : message.ERR_REGISTRATION_FAILED }
            setMessage(msg)
            reset()
        }
            setEdit(false)
    }

    return (
        <Settings
            category="パスワード"
            currents={[
                { value : <Body>******　※セキュリティの為パスワードは非表示になっています。</Body> },
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <input type="text" hidden autoComplete="username" />
                    <LabeledTextField
                        name="現在のパスワード"
                        type="password"
                        control={control}
                        autoComplete="current-password"
                    />
                    <LabeledTextField
                        name="新しいパスワード"
                        type="password"
                        control={control}
                        autoComplete="new-password"
                        helperText="※半角6文字以上"
                    />
                    <LabeledTextField
                        name="新しいパスワード確認用"
                        type="password"
                        label="新しいパスワード（確認用）"
                        control={control}
                        autoComplete="new-password"
                        helperText="※半角6文字以上"
                    />
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
        />
    )
}

export default MyPagePassword
