import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Button, IconButton } from '@mui/material'
import Badge from 'components/Badge'
import { blue, grey } from '@mui/material/colors'

// メニューバーのアイコンボタン
export const MenuBarIconButton = styled(IconButton)({
    padding : '4px',
    '& .MuiSvgIcon-root' : {
        fontSize : '2.5rem',
    },
})

// ボタン
export const ButtonBase = styled(Button)({
    borderRadius : 9999,
    fontWeight : 700,
    padding : 0,
    boxShadow : 'none',
    '&:hover' : {
        opacity : 0.8,
        boxShadow : 'none',
    },
})

// メニューバーのリンクボタン
export const MenuBarButton = styled(ButtonBase)({
    fontSize : 16,
    minWidth : 170,
    height : 38,
})
export const BadgeButton = ({ count, to, children, ...props }) => (
    <Badge
        count={count}
        circular
    >
        <MenuBarButton
            color="grey800"
            variant="outlined"
            component={Link}
            to={to || "/"}
            {...props}
        >
            {children}
        </MenuBarButton>
    </Badge>
)

// ページタイトル横ボタン
const SideButton = styled(ButtonBase)({
    width : 150,
    fontSize : 16,
    height : 38,
    textTransform : "none",
    '@media (max-width: 480px)' : {
//        width : 120,
    },
})
export const BlueContainedButton = ({ label, ...props }) => {
    return (
        <SideButton
            variant="contained"
            color="blue"
            {...props}
        >
            {label}
        </SideButton>
    )
}
export const BlueOutlinedButton = ({ label, sx, ...props }) => {
    return (
        <SideButton
            variant="outlined"
            color="blue"
            sx={{
                bgcolor : '#fff',
                border : `3px solid ${blue[400]}`,
                '&:hover' : {
                    border : `3px solid ${blue[400]}`,
                },
                ...sx,
            }}
            {...props}
        >
            {label}
        </SideButton>
    )
}

export const SnsButton = ({ label, to }) => (
    <BlueOutlinedButton
        component={Link}
        to={to}
        label={label}
        target="_blank"
        sx={{ width : 120 }}
    />
)
export const SnsButtons = ({ data }) => (
    ['Facebook', 'X', 'Instagram', 'TikTok'].filter(s => data[s]).map(s => (
        <SnsButton
            key={s}
            label={s}
            to={data[s]}
        />
    ))
)


// メッセージ新規作成ボタン
export const EntryMessageButton = ({ sx, ...props }) => {
    return (
        <BlueOutlinedButton
            sx={{
                fontSize : 14,
                lineHeight : 1.7,
                border : `2px solid ${blue[400]}`,
                '&:hover' : {
                    border : `2px solid ${blue[400]}`,
                },
                width : 145,
                ...sx,
            }}
            {...props}
        />
    )
}

// リンクボタン（中）
export const LinkButtonMiddle = styled(ButtonBase)({
    fontWeight : 400,
    fontSize : 16,
    height : 38,
    color : grey[600],
    background : '#fff',
    border : `solid 1px ${grey[600]}`,
    minWidth : 160,
})
// リンクボタン（大）
export const LinkButtonLarge = styled(LinkButtonMiddle)({
    height : 44,
    minWidth : 240,
})
// 編集ボタン（各種設定画面）
export const EditButton = styled(LinkButtonMiddle)({
    fontSize : 14,
    width : 150,
    height : 32,
    paddingTop : 2,
})
// 編集サブミットボタン（各種設定画面）
export const EditSubmitButton = styled(BlueContainedButton)({
    fontSize : 14,
    fontWeight : 400,
    height : 32,
    paddingTop : 2,
    width : 150,
})


// 講座申し込みボタン
export const EntryButton = styled(ButtonBase)({
    fontSize : 12,
    color : blue[500],
    border : `2px solid ${blue[500]}`,
    minWidth : 120,
    lineHeight : 1.7,
})

// ブロック下部ボタン
const StyledBlockButton = styled(ButtonBase)({
    fontSize : 14,
    color : grey[500],
    background : grey[50],
    border : `1px solid ${grey[500]}`,
    minWidth : 170,
    paddingTop : 1,
    lineHeight : 2,
})
export const BlockButton = ({ label, to, ...props }) => {
    return (
        <StyledBlockButton
            component={Link}
            to={to}
            {...props}
        >
            {label}
        </StyledBlockButton>
    )
}

// ステータスボタン
export const StatusButton = styled(ButtonBase)({
    fontSize : 12,
    fontWeight : 400,
    minWidth : 110,
})
export const StatusWhiteButton = ({ label, to="/", ...props }) => {
    return (
        <StatusButton
            component={Link}
            variant="outlined"
            color="button"
            to={to}
            {...props}
        >
            {label}
        </StatusButton>
    )
}
export const StatusBlueButton = ({ label, to="/", ...props }) => {
    return (
        <StatusButton
            component={Link}
            variant="contained"
            color="blue"
            to={to}
            {...props}
        >
            {label}
        </StatusButton>
    )
}

// プログラム申込みボタン
export const EntryProgramButton = ({ to, ...props }) => {
    return (
        <Button
            to={to}
            variant="contained"
            size="small"
            color="indigo"
            sx={{
                fontWeight : 400,
                boxShadow : 'none',
                padding : 0,
                width : 40,
                minWidth : 40,
                '&:hover' : {
                    opacity : 0.8,
                    boxShadow : 'none',
                },
            }}
        >
            申込
        </Button>
    )
}

// 企業探訪設定 下書き保存ボタン
export const DraftButton = ({ label, type="submit", ...props }) => {
    return (
        <ButtonBase
            color="grey600"
            variant="outlined"
            type={type}
            {...props}
            sx={{
                fontSize : 14,
                background : '#fff',
                width : 140,
                lineHeight : 2.7,
            }}
        >
            {label}
        </ButtonBase>
    )
}
// 企業探訪設定 投稿ボタン
export const PostButton = ({ label, ...props }) => {
    return (
        <BlueContainedButton
            label={label}
            type="submit"
            {...props}
            sx={{
                fontSize : 14,
                width : 140,
                lineHeight : 2.7,
            }}
        />
    )
}


