
export const PenIcon = (props) => (
    <svg
        viewBox="0 0 24 24"
        width={20}
        height={20}
        {...props}
    >
        <path
            d="M22.503 20.058v-1.085l-2.032-2.032-1.765 1.765-1.765 1.765 2.032 2.03h1.086l2.444-2.443zm-2.032-5.235L24 18.352V24h-5.647l-3.531-3.529v-.001L0 5.647 5.647 0l14.824 14.823z"
            transform="translate(-504 -375) translate(504 375)"
            fill="#606060"
            stroke="none"
            strokeWidth={1}
            fillRule="evenodd"
        />
    </svg>
)

