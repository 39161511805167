import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { Stack } from '@mui/material'
import { H1 } from 'components/styledFonts'
import { BlueOutlinedButton } from 'components/styledButtons'
import CompanyInfo from 'components/CompanyInfo'
import TwoColumns from 'components/TwoColumns'
import DownloadMaterials from 'components/DownloadMaterials'
import { loadedAtom } from 'store/commonAtoms'
import PostsOfClient from './PostsOfClient'
import OtherInquiries from './OtherInquiries'
import { useType } from 'hooks/post'


const PostDetailBase = ({ resource, data }) => {
    const { clientId, no } = useParams()
    const type = useType()
    const setLoaded = useSetAtom(loadedAtom)

    useEffect(() => {
        if (data) setLoaded(true)
    }, [data, setLoaded])

    const contact = resource.問い合わせ担当者_上書き ?
        `${resource.問い合わせ担当者部署_上書き}　${resource.問い合わせ担当者_上書き}` : ''

    return (
        <Stack
            gap={2}
        >
            <H1>{type === 'inquiry' ? data?.カテゴリ : '報道資料'}</H1>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <Stack
                    gap={1.5}
                >
                    {resource}
                </Stack>
                <Stack
                    gap={3}
                    alignItems="center"
                    alignSelf="start"
                >
                    <CompanyInfo
                        contact={contact}
                        title={data?.タイトル}
                    />
                    <BlueOutlinedButton
                        component={Link}
                        to={`/print/${clientId}/${type}/${no}`}
                        target="_blank"
                        label="印刷"
                        sx={{
                            width: "70%",
                            height: 44,
                        }}
                    />
                    {data?.企業探訪No ?
                    <OtherInquiries />
                    :
                    <DownloadMaterials
                        data={data}
                    />
                    }
                    <PostsOfClient /> 
                </Stack>
            </TwoColumns>
        </Stack>
    )
}

export default PostDetailBase
