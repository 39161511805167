import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { Divider, Stack } from '@mui/material'
import { Body2, H5Overflow } from 'components/styledFonts'
import { BlueLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import { TitledBlock } from 'components/Block'
import Thumbnail from 'components/Thumbnail'
import { usePostsOfClient } from 'hooks/post'


const PostsOfClient = () => {
    const { clientId, no } = useParams()
    const { data } = usePostsOfClient(clientId, no)

    if (!data || !data.length) return null

    return (
        <TitledBlock
            title="この企業のプレスリリース・その他"
        >
            <Stack
                gap={2}
                px={1}
            >
                {data.slice(0, 5).map((d, i) => (
                <Stack
                    key={i}
                    gap={2}
                >
                    {i !== 0 &&
                    <Divider />
                    }
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        gap={2}
                    >
                        <Thumbnail
                            src={d.サムネイル}
                            to={d.to}
                            newly={d.newly}
                        />
                        <Stack
                            gap={0.5}
                        >
                            <Stack
                                direction="row"
                                flexWrap="wrap"
                                alignItems="center"
                                columnGap={1}
                                rowGap={0.5}
                            >
                                <BlueLabel>{d.種類 === 'プレスリリース' ? d.種類 : 'その他'}</BlueLabel>
                                <Body2>{format(d.公開日, 'yyyy/MM/dd HH:mm')}</Body2>
                            </Stack>
                            <Stack
                                gap={0.75}
                            >
                                <H5Overflow>
                                    <UnderlineLink
                                        to={d.to}
                                    >
                                        {d.タイトル}
                                    </UnderlineLink>
                                </H5Overflow>
                            </Stack>
                                <Body2>{d.会社名}</Body2>
                        </Stack>
                    </Stack>
                </Stack>
                ))}
            </Stack>
        </TitledBlock>
    )
}

export default PostsOfClient
