import { useParams } from 'react-router-dom'
import PressRelease from 'components/PressRelease'
import PostDetailBase from './PostDetailBase'
import { usePressReleaseByNo } from 'hooks/post'


const PressReleaseDetail = () => {
    const { no } = useParams()
    const { data } = usePressReleaseByNo(no)

    return (
        <PostDetailBase
            data={data}
            resource={
                <PressRelease data={data} />
            }
        />
    )
}

export default PressReleaseDetail
