import { Divider, Stack, useMediaQuery } from '@mui/material'
import { Body, H2, Lead } from 'components/styledFonts'
import Block from 'components/Block'
import PaginationList from './PaginationList'


const SearchList = ({ query, dispRow }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    return (
        <Block
            isLoading={query.isLoading === undefined}
        >
            <Stack
                gap={2}
                sx={{ px : { mobile : 2, xs : 0 } }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Body>検索結果：</Body>
                    <H2>{`${query?.totalCount || 0}件`}</H2>
                </Stack>
                {!query?.totalCount &&
                <Lead>検索条件に一致する情報はありません</Lead>
                }
                {!!query?.totalCount &&
                <PaginationList
                    max={10}
                    gap={2}
                    siblingCount={isMobile ? 0 : 2}
                    data={query?.records.map((d, i) => (
                        <Stack
                            key={i}
                            gap={2}
                            sx={{ wordBreak : "break-word" }}
                        >
                            <Divider />
                            {dispRow(d)}
                            {(i % 10 === 9 || i === query?.records.length - 1) &&
                            <Divider />
                            }
                        </Stack>
                    ))}
                    total={query?.totalCount}
                />
                }
            </Stack>
        </Block>
    )
}

export default SearchList
