import { useSetAtom } from 'jotai'
import { draftResearchNo, researchModal } from 'jotaiAtoms'
import ResearchList from './ResearchList'
import { useDraftResearches } from 'hooks/research'


const DraftResearches = () => {
    const { data } = useDraftResearches()
    const setOpen = useSetAtom(researchModal)
    const setDraft = useSetAtom(draftResearchNo)

    const handleClick = no => {
        setDraft(no)
        setOpen(true)
    }

    return (
        <ResearchList
            title="一時保存中のリサーチ"
            data={data}
            handleClick={handleClick}
        />
    )
}

export default DraftResearches

