import { Alert, Snackbar } from '@mui/material'
import { useAtom } from 'jotai'
import { messageAtom } from 'store/commonAtoms'

const FlashMessage = () => {
    const [msg, setMsg] = useAtom(messageAtom)

    return (<>
        {msg?.text &&
        <Snackbar
            open={!!msg}
            anchorOrigin={{vertical : 'top', horizontal : 'center'}}
            autoHideDuration={ ['success', 'info'].includes(msg?.type) ? 2000 : null }
            onClose={() => setMsg(null)}
            sx={{ mt : 4 }}
        >
            <Alert
                onClose={() => setMsg(null)}
                severity={msg?.type}
                variant="filled"
                sx={{ py : 1.5 }}
            >
                {msg?.text}
            </Alert>
        </Snackbar>
        }
    </>)
}

export default FlashMessage
