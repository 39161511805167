import { I18n } from 'aws-amplify'
import { translations } from '@aws-amplify/ui-react'

translations.ja['Sign In'] = 'ログイン'
translations.ja['Sign in'] = '報道関係者ログイン'
translations.ja['Signing in'] = 'ログイン中'
translations.ja['Forgot your password?'] = 'パスワードをお忘れの方'
translations.ja['Sign in to your account'] = 'Welcome Back!'
translations.ja['Enter your email'] = 'メールアドレス'
translations.ja['Send code'] = '送信する'
translations.ja['Back to Sign In'] = 'ログイン画面に戻る'
translations.ja['Reset your password'] = '会員登録時に登録されたメールアドレスを入力してください。/nパスワード再設定のためのコードをお送りします。'
translations.ja['Incorrect username or password.'] = 'ユーザー名かパスワードが異なります。'
translations.ja['Your passwords must match'] = '異なるパスワードが入力されています。'
translations.ja['Password must have at least 6 characters'] = 'パスワードは6文字以上である必要があります。'

translations.ja['User does not exist.'] = 'ユーザー名かパスワードが異なります。'
translations.ja['Password attempts exceeded'] = 'ログインの試行回数が上限に達しました。しばらくしてからもう一度お試しください。パスワードをお忘れの方は、「パスワードをお忘れの方はこちら」から再設定が可能です。'
translations.ja['Temporary password has expired and must be reset by an administrator.'] = '一時パスワードは有効期限切れです。ネタもとへお問い合わせいただけますでしょうか。'
translations.ja['User password cannot be reset in the current state.'] = '初回ログインが完了していない為、アカウントのパスワードをリセットすることはできません。'
translations.ja['Attempt limit exceeded, please try after some time.'] = '試行制限を超過しました。しばらくしてからもう一度お試しください。'
translations.ja['Invalid verification code provided, please try again.'] = '無効なコードです。'
translations.ja['Invalid code provided, please request a code again.'] = '有効期限切れのコードです。コードを再送信してください。'
translations.ja['Username/client id combination not found.'] = '会員登録時に登録されたメールアドレスと異なります。'

// 認証前Lambdaトリガーの処理においてログイン条件を満たさなかった場合のエラー内容を定義
translations.ja['PreAuthentication failed with error accountInvalidError.'] = '無効なアカウントのためログインに失敗しました'
translations.ja['PreAuthentication failed with error internalError.'] = '予期しないエラーが発生しました'

I18n.setLanguage('ja')
I18n.putVocabularies(translations)

