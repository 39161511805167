import { Box, Backdrop, CircularProgress } from '@mui/material'
import { useAtom } from 'jotai'
import { spinnerAtom } from 'store/commonAtoms'

const Spinner = () => {
    const [open] = useAtom(spinnerAtom)

    return (
        <Box>
            <Backdrop
                open={open}
                sx={{
                    color : '#fff',
                    zIndex : (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress color="grey400" />
            </Backdrop>
        </Box>
    )
}

export default Spinner
