import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'


const AbsoluteBox = styled(Box)({
    position : "absolute",
    top : 0,
    left : 0,
    '& iframe' : {
        width : "100%",
        height : "100%",
    },
})

const ResponsivePlayer = ({ url }) => {
    let embedURL = ''

    const [, ...id] = url.match(/(?:youtu\.be\/([^/?]+)|youtube\.com\/watch\?.*v=([^&]+)|vimeo\.com\/([^/?]+))/) || []
    if (id[0] || id[1]) {
        embedURL = `https://www.youtube.com/embed/${id[0] || id[1]}`
    }
    else if (id[2]) {
        embedURL = `https://player.vimeo.com/video/${id[2]}`
    }
    const iframe = embedURL ? `<iframe src="${embedURL}" allowfullscreen></iframe>` : ''

    return (<>
        {/s3-ap-northeast-1/.test(url) ?
        <Box
            component="video"
            controls
            src={url}
            width="100%"
            height="100%"
        />
        :
        <Box
            position="relative"
            pt="56.25%"
        >
            <AbsoluteBox
                dangerouslySetInnerHTML={{ __html: iframe || url }}
                width="100%"
                height="100%"
            />
        </Box>
        }
    </>)
}

export default ResponsivePlayer
