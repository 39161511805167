import { Link, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Body } from 'components/styledFonts'
import { urls } from 'config/urls'

const FooterLink = ({ label, url }) => 
    <Link
        href={url}
        underline="hover"
        color={grey[800]}
        target='_blank'
    >
        <Body>{label}</Body>
    </Link>


const Footer = () => {
    return (
        <Stack
            alignItems="center"
            width="100%"
            bgcolor={grey[300]}
        >
            <Stack
                justifyContent="space-between"
                alignItems="center"
                rowGap={2}
                width="100%"
                maxWidth={1212}
                px={1}
                pt={3}
                pb={6}
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                }}
            >
                <Stack
                    direction="row"
                    columnGap={4}
                >
                    {Object.keys(urls.footer).map(l => (
                    <FooterLink
                        key={l}
                        label={l}
                        url={urls.footer[l]}
                    />
                    ))}
                </Stack>
                <Body>Copyright © Netamoto Inc. All rights reserved.</Body>
            </Stack>
        </Stack>
    )
}

export default Footer
