import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PressRelease from 'components/PressRelease'
import { API } from 'lib/api'
import { apiName } from 'config/api'


const PrintPressRelease = () => {
    const { no } = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        (async () => {
            const ret = await API.get(apiName, `/items/getPressRelease?no=${no}`, {})
            console.log(ret)
            ret.種類 = 'プレスリリース'
            ret.公開日 = new Date(ret.リリース日時)
            setData(ret)
        })()
    }, [no])

    return (
        <PressRelease
            data={data}
            print
        />
    )
}

export default PrintPressRelease
