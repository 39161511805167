import { useParams } from 'react-router'
import { Stack } from '@mui/material'
import { H2 } from 'components/styledFonts'
import Block from 'components/Block'
import InquiryItem from 'components/InquiryItem'
import { cate, useInquiryByClientId } from 'hooks/post'


const Inquery = () => {
    const { id } = useParams()
    const { data } = useInquiryByClientId(id)

    return (
        <Block
            sx={{
                height: "100%"
            }}
        >
            <H2>企業探訪</H2>
            <Stack
                alignItems="center"
                pt={3}
            >
                <Stack
                    direction="column"
                    gap={3}
                >
                    {cate.filter(c => data[c]).map((c, i) => (
                    <InquiryItem
                        key={i}
                        category={c}
                        data={data[c]}
                        link={data[c].to}
                    />
                    ))}
                </Stack>
            </Stack>
        </Block>
    )
}

export default Inquery
