import { Link } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { researchModal } from 'jotaiAtoms'
import { styled } from '@mui/material/styles'
import { blue, deepOrange, indigo } from '@mui/material/colors'
import { Button, Box, Stack } from '@mui/material'
import { H2, H4, Body, Bold } from 'components/styledFonts'
import { BlueContainedButton, BlueOutlinedButton } from 'components/styledButtons'
import Block from 'components/Block'
import { Balloon1, Balloon2, Balloon3, Balloon4, Balloon5 } from './Balloons'
import research from 'img/research.png'


const Title1 = styled(Bold)({
    fontSize : 28,
    lineHeight : 1.25,
    textAlign : "center",
    color : indigo[900],
})

const Title2 = styled(Bold)({
    fontSize : 30,
    color : deepOrange[400],
    paddingTop : 8,
})

const ResearchButton = () => {
    const setOpen = useSetAtom(researchModal)

    return (
        <Button
            variant="contained"
            color="orange"
            sx={{
                px : { mobile : 4.25, xs : 2 },
                pt : 1,
                height : 74,
                fontSize : { mobile : 34, xs : 26 },
                fontWeight : 700,
                borderRadius : 9999,
            }}
            onClick={() => setOpen(true)}
        >
            無料リサーチはこちら
        </Button>
    )
}

const Detail = ({ title, text }) => {
    return (
        <Box
            sx={{
                maxWidth : { sm : "32.5%", xs : "100%" },
            }}
            bgcolor="#fff"
            border={`3px solid ${indigo[100]}`}
            borderRadius={2}
        >
            <H4
                whiteSpace="pre-wrap"
                sx={{
                    color : indigo[900],
                    bgcolor : indigo[100],
                    lineHeight : 1.25,
                    textAlign : "center",
                    py : 0.5,
                    px : 1,
                }}
            >
                {title}
            </H4>
            <Body
                sx={{
                    py : 1,
                    px : 0.75,
                }}
            >
                {text}
            </Body>
        </Box>
    )
}


const FreeResarch = () => {
    const setOpen = useSetAtom(researchModal)

    return (
        <Block
            sx={{
                px : { mobile : 4, xs : 2 },
                height : "100%",
            }}
        >
            <Stack
                alignItems="center"
            >
                <Box
                    position="relative"
                    width="100%"
                    maxWidth={700}
                    gap={1.5}
                    sx={{
                        pt : { sm : 0, xs : 2 },
                        mb : { mobile : 0, xs : 3 },
                        position : { sm : "relative", xs : "static" },
                        display : { sm : "block", xs : "flex" },
                        flexDirection : "column",
                        minHeight : { sm : 210, xs : 360 },
                    }}
                >
                    <Balloon1>プレスリリースになっていない情報が欲しい</Balloon1>
                    <Balloon2>情報収集先を増やしたい</Balloon2>
                    <Balloon3>連載コーナーの情報を充実させたいが情報のバリエーションに限界がある</Balloon3>
                    <Balloon4>取材先が急遽キャンセルになった</Balloon4>
                    <Balloon5>人の情報を探すのが難しい</Balloon5>
                </Box>
                <Title1>こんなことでお困りではありませんか？</Title1>
                <Title2>＼今すぐ解決‼／</Title2>
                <ResearchButton />
                <Box
                    component="img"
                    src={research}
                    py={2}
                />
                <H2
                    sx={{
                        color : blue[500],
                        textAlign : "center",
                        lineHeight : 1.35,
                        pt : 0.5,
                        pb : 1,
                    }}
                >
                    欲しい情報、探している情報などを無料でお探しするサービスです
                </H2>
                <Stack
                    justifyContent="space-between"
                    rowGap={1.5}
                    sx={{
                        flexDirection : { sm : "row", xs : "column" },
                    }}
                >
                    <Detail
                        title={'いつでも、どんな情報も\n無料でリサーチ!'}
                        text="「ネタもと」を利用している企業・団体から、物・サービス・企業・人・事象･･依頼いただいたどんな情報も無料で探します。"
                    />
                    <Detail
                        title={'面倒なやりとりは\nありません!'}
                        text="「取材したい」「追加情報を聞きたい」という段階になったら、企業の広報担当を紹介します。すべての情報が必要ないときは、企業へのお断りも弊社が行います。"
                    />
                    <Detail
                        title={'匿名でもリサーチ\nできます!'}
                        text="媒体名を企業へ開示しないでリサーチすることも可能です。"
                    />
                </Stack>
                <Stack
                    direction="row"
                    pt={3}
                    pb={1}
                    gap={2}
                >
                    <BlueOutlinedButton
                        label="リサーチ管理"
                        component={Link}
                        to="/research"
                        sx={{ width : { mobile : 160, xs : 150 } }}
                    />
                    <BlueContainedButton
                        label="無料リサーチ"
                        sx={{ width : { mobile : 160, xs : 150 } }}
                        onClick={() => setOpen(true)}
                    />
                </Stack>
            </Stack>
        </Block>
    )
}

export default FreeResarch
