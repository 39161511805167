import { useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { H1 } from 'components/styledFonts'
import Block from 'components/Block'
import S3Image from 'components/S3Image'
import SendMessageButton from './SendMessageButton'
import { useCompanyById } from 'hooks/company'


const Logo = () => {
    const { id } = useParams()
    const { data } = useCompanyById(id)

    return (
        <Block>
            {data &&
            <Stack
                alignItems="center"
                gap={3}
                py={1}
            >
                <Stack
                    alignItems="center"
                    columnGap={10}
                    rowGap={1}
                    sx={{
                        flexDirection : { sm : "row", xs : "column" },
                    }}
                >
                    <Stack
                        justifyContent="center"
                        sx={{
                            width : { mobile : 240, xs : 150 },
                            height : { mobile : 150, xs : 80 },
                        }}
                    >
                        <S3Image url={data.会社ロゴ} />
                    </Stack>
                    <H1 sx={{ px : 2 }}>{data.会社名 || data.企業名}</H1>
                </Stack>
                <SendMessageButton />
            </Stack>
            }
            {!data &&
            <Stack
                py={14}
            >
            </Stack>
            }
        </Block>
    )
}

export default Logo
