import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useSetAtom } from 'jotai'
import { searchPageAtom, searchTriggerAtom } from 'store/commonAtoms'
import { H3 } from 'components/styledFonts'
import { RadioGroupBase } from 'components/Inputs'
import SearchButton from 'components/SearchButton'


const list = [
    { label : '企業検索', value : '/search/company' },
    { label : 'プレスリリース', value : '/search/pressrelease' },
    { label : 'ニュースレター', value : '/search/newsletter' },
    { label : '企業探訪', value : '/search/inquiry' },
]

const TargetSelector = () => {
    const [value, setValue] = useState(list[0].value)
    const setPage = useSetAtom(searchPageAtom)
    const setTrigger = useSetAtom(searchTriggerAtom)
    const navi = useNavigate()
    const { pathname } = useLocation()

    useEffect(() => {
        const v = list.find(l => l.value === pathname)?.value
        if (v) setValue(v)
    }, [pathname])

    const handleChange = e => {
        setPage(1)
        setTrigger(true)
        navi(e.target.value)
    }

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            pb={1}
        >
            <Stack
                gap={0.5}
            >
                <H3>検索対象</H3>
                <RadioGroupBase
                    value={value}
                    list={list}
                    onChange={handleChange}
                    sx={{ px : 1 }}
                />
            </Stack>
            <SearchButton />
        </Stack>
    )
}

export default TargetSelector
