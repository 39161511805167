import { Stack } from '@mui/material'
import { Link, Outlet, useParams } from 'react-router-dom'
import { H1 } from 'components/styledFonts'
import { LinkButtonLarge } from 'components/styledButtons'
import TwoColumns from 'components/TwoColumns'
import SubmittedFeedback from './SubmittedFeedback'
import FeedbackForm from './FeedbackForm'

const FeedbackDetail = () => {
    const { type } = useParams()

    return (
        <Stack
            gap={2}
        >
            <H1>{type === 'input' ? "フィードバック入力フォーム" : "フィードバック履歴詳細"}</H1>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <Outlet />
                <Stack
                    gap={4}
                    alignSelf="start"
                >
                    {type === 'history' ?
                    <SubmittedFeedback />
                    :
                    <FeedbackForm />
                    }
                    <Stack
                        alignItems="center"
                    >
                        <LinkButtonLarge
                            component={Link}
                            to={`/feedback/list`}
                        >
                            フィードバック一覧
                        </LinkButtonLarge>
                    </Stack>
                </Stack>
            </TwoColumns>
        </Stack>
    )
}

export default FeedbackDetail
