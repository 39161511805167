import { useParams } from 'react-router-dom'
import Inquiry from 'components/Inquiry'
import PostDetailBase from './PostDetailBase'
import { useInquiryByNo } from 'hooks/post'


const InquiryDetail = () => {
    const { no } = useParams()
    const { data } = useInquiryByNo(no)

    return (
        <PostDetailBase
            data={data}
            resource={
                <Inquiry data={data} />
            }
        />
    )
}

export default InquiryDetail
