import { useParams } from 'react-router-dom'
import NewsLetter from 'components/NewsLetter'
import PostDetailBase from './PostDetailBase'
import { useNewsLetterByNo } from 'hooks/post'


const NewsLetterDetail = () => {
    const { no } = useParams()
    const { data } = useNewsLetterByNo(no)

    return (
        <PostDetailBase
            data={data}
            resource={
                <NewsLetter data={data} />
            }
        />
    )
}

export default NewsLetterDetail
