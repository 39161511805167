import { useQuery } from '@tanstack/react-query'
import { addDays, addHours, isAfter } from 'date-fns'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useLoginUser } from 'hooks/loginuser'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getFeedback', {})
    const list = ret.map(r => {
        r.メディア公開日時 = new Date(r.メディア公開日時)
        r.一時保存締切日 = new Date(r.一時保存締切日)
        r.メディア投稿日時 = new Date(r.メディア投稿日時)
        r.クライアント公開日時 = new Date(r.クライアント公開日時)
        r.新着 = addDays(r.メディア公開日時, 2) > new Date()
        if (r.リリースNo) {
            r.path = `pressrelease/${r.リリースNo}`
        }
        if (r.ネタNo) {
            r.path = `newsletter/${r.ネタNo}`
        }
        if (r.企業探訪No) {
            r.path = `story/${r.企業探訪No}`
        }
        
        return r
    })
    
    return list
}

export const useAllFeedbacks = () => {
    return useQuery(['feedback'], getData)
}

// フィードバック依頼一覧の取得
export const useFeedbackRequest = () => {
    return useQuery(['feedback'], getData, {
        select : data => data
            .filter(d => d.媒体担当者ID === "")
            .filter(d => d.一時保存 === false)
            .filter(d => d.クライアント公開 === false)
            .sort((a, b) => b.メディア公開日時 - a.メディア公開日時)
    })
}

// フィードバック一時保存一覧の取得
export const useTemporaryFeedbacks = () => {
    const { data : loginuser } = useLoginUser()

    return useQuery(['feedback'], getData, {
        select : data => data
            .filter(d => d.媒体担当者ID === loginuser?.媒体担当者ID)
            .filter(d => d.一時保存 === true)
            .sort((a, b) => b.一時保存締切日 - a.一時保存締切日)
    })
}

// ネタもと確認中のフィードバック一覧の取得
export const useCheckingFeedbacks = () => {
    const { data : loginuser } = useLoginUser()

    return useQuery(['feedback'], getData, {
        select : data => data
            .filter(d => d.媒体担当者ID === loginuser?.媒体担当者ID)
            .filter(d => d.一時保存 === false)
            .filter(d => d.クライアント公開 === false)
            .sort((a, b) => b.メディア投稿日時 - a.メディア投稿日時)
    })
}

// フィードバック履歴一覧の取得
export const useFeedbackHistories = () => {
    const { data : loginuser } = useLoginUser()

    return useQuery(['feedback'], getData, {
        select : data => data
            .filter(d => d.媒体担当者ID === loginuser?.媒体担当者ID)
            .filter(d => d.一時保存 === false)
            .filter(d => d.クライアント公開 === true)
            .sort((a, b) => b.メディア投稿日時 - a.メディア投稿日時)
    })
}

// 新着フィードバック依頼件数取得
export const useNewFeedbackCount = () => {
    return useQuery(['feedback'], getData, {
        select : (data) => data
            .filter(d => d.メディア公開)
            .filter(d => !d.一時保存)
            .filter(d => !d.クライアント公開)
            .filter(d => isAfter(addHours(d.メディア公開日時, 48), new Date()))
            .filter(d => !d.媒体担当者ID)
            .length,
    })
}

// 新着フィードバック依頼取得
export const useNewFeedbackList = () => {
    return useQuery(['feedback'], getData, {
        select : (data) => data
            .filter(d => d.メディア公開)
            .filter(d => !d.一時保存)
            .filter(d => !d.クライアント公開)
            .filter(d => isAfter(addHours(d.メディア公開日時, 48), new Date()))
            .filter(d => !d.媒体担当者ID)
            .sort((a, b) => b.メディア公開日時 - a.メディア公開日時)
    })
}

export const useFeedbackByNo = (no) => {
    return useQuery(['feedback'], getData, {
        select : data => data.find(d => d.フィードバックNo === no)
    })
}

