import ResearchList from './ResearchList'
import { useCheckingResearches } from 'hooks/research'


// 運営が確認中のリサーチ
const CheckingResearches = () => {
    const { data } = useCheckingResearches()

    return (
        <ResearchList
            title="運営が確認中のリサーチ"
            data={data}
        />
    )
}

export default CheckingResearches

