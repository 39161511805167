import { Stack } from '@mui/material'
import { format } from 'date-fns'
import ArticleImage from 'components/ArticleImage'
import Block from 'components/Block'
import ResponsivePlayer from 'components/ResponsivePlayer'
import { Body, H2 } from 'components/styledFonts'
import { BlueLabel, WhiteLabel } from 'components/styledLabels'


const Inquiry = ({ data, isLoading, print, feedback }) => {

    const sx = print ? { border : 0 } : {}

    return (
        <Block
            sx={{
                ...sx,
                minHeight : 700,
            }}
            isLoading={isLoading}
        >
            {data &&
            <Stack
                gap={2}
                pt={1}
                pb={3}
                sx={{ wordBreak : "break-word" }}
            >
                {feedback &&
                <H2>元の報道資料</H2>
                }
                <H2>{data.タイトル}</H2>
                <Stack
                    gap={1}
                    direction="row"
                    flexWrap="wrap"
                    sx={{
                        alignItems: { mobile: "center", xs: "flex-start" },
                    }}
                >
                    {print &&
                    <BlueLabel>{data.カテゴリ}</BlueLabel>
                    }
                    {data.公開日 &&
                    <WhiteLabel>{`公開日：${format(data.公開日, 'yyyy/MM/dd HH:mm')}`}</WhiteLabel>
                    }
                </Stack>
                <Stack
                    gap={4}
                >
                    {data.リード文 &&
                    <Body whiteSpace="pre-wrap">{data.リード文}</Body>
                    }
                    <Stack
                        gap={4}
                    >
                        {data.メインイメージ &&
                        <ArticleImage
                            src={data.メインイメージ}
                            caption={data.メインイメージキャプション}
                        />
                        }
                        {data.本文 &&
                        <Body whiteSpace="pre-wrap">{data.本文}</Body>
                        }
                        {(!print && (data.動画 || data.動画URL)) &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                url={data.動画 || data.動画URL}
                            />
                        </Stack>
                        }
                        {[1, 2, 3, 4].filter(n => data[`イメージ${n}`]).map(n => (
                        <ArticleImage
                            key={n}
                            src={data[`イメージ${n}`]}
                            caption={data[`イメージ${n}キャプション`]}
                        />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
            }
        </Block>
    )
}

export default Inquiry
