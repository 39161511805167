import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NewsLetter from 'components/NewsLetter'
import { API } from 'lib/api'
import { apiName } from 'config/api'


const PrintNewsLetter = () => {
    const { no } = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        (async () => {
            const ret = await API.get(apiName, `/items/getNewsLetter?no=${no}`, {})
            console.log(ret)
            ret.種類 = 'ニュースレター'
            ret.公開日 = new Date(ret.公開日)
            setData(ret)
        })()
    }, [no])

    return (
        <NewsLetter
            data={data}
            print
        />
    )
}

export default PrintNewsLetter
