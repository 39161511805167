import { Stack } from '@mui/material'
import { Body, H1 } from 'components/styledFonts'
import { TitledBlock } from 'components/Block'
import TwoColumns from 'components/TwoColumns'
import CheckingFeedbacks from './CheckingFeedbacks'
import FeedbackRequest from './FeedbackRequest'
import FeedbackHistories from './FeedbackHistories'
import TemporaryFeedbacks from './TemporaryFeedbacks'


const note = 
`企業が発信した情報に対して、メディアの方の視点からフィードバックをいただくサービスです。
興味を惹かれた/持てなかった点や、よりよい情報にするための客観的なアドバイスなどをご入力いただきます。
※フィードバックは管理者による確認があるため、確認後、企業へ公開させていただきます。`

const FeedbackList = () => {
    return (
        <Stack
            gap={2}
        >
            <Stack
                gap={1}
            >
                <H1>フィードバック一覧</H1>
                <Body
                    sx={{ whiteSpace : "pre-wrap" }}
                >
                    {note}
                </Body>
            </Stack>
            <TwoColumns
                ratio="7fr 5fr"
            >
                <TitledBlock
                    title="フィードバック依頼"
                >
                    <FeedbackRequest />
                </TitledBlock>
                <Stack
                    gap={1.5}
                >
                    <TemporaryFeedbacks />
                    <CheckingFeedbacks />
                    <FeedbackHistories />
                </Stack>
            </TwoColumns>
        </Stack>
    )
}

export default FeedbackList
