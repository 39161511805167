import { Link, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { format } from 'date-fns'
import { Lead, H1 } from 'components/styledFonts'
import { WhiteLabel } from 'components/styledLabels'
import { LinkButtonLarge } from 'components/styledButtons'
import { TitledBlock } from 'components/Block'
import TwoColumns from 'components/TwoColumns'
import EntryListItem from './EntryListItem'
import PaginationList from 'components/PaginationList'
import RecentlyResearches from './RecentlyResearches'
import { useResearchByNo } from 'hooks/research'


const ResearchDetail = () => {
    const { no } = useParams()
    const { data } = useResearchByNo(no)

    if (!data) return

    const cnt = data.エントリー.length

    return (
        <Stack
            gap={3}
            alignItems="center"
        >
            <Stack
                alignItems="flex-start"
                width="100%"
            >
                <Lead>{format(data.募集開始日時_通常用, 'yyyy/MM/dd')}</Lead>
                <H1>{data.タイトル}</H1>
                <WhiteLabel>リサーチ締切：{format(data.募集締切日時, 'yyyy/MM/dd HH:mm')}</WhiteLabel>
            </Stack>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <TitledBlock
                    title={`集まった情報 ${cnt}件`}
                >
                    <Stack
                        sx={{ px : { mobile : 2, xs : 1 } }}
                    >
                        <PaginationList
                            max={20}
                            gap={3}
                            data={data.エントリー.map((e, i) => (
                                <EntryListItem
                                    key={i}
                                    data={e}
                                />
                            ))}
                        />
                    </Stack>
                </TitledBlock>
                <RecentlyResearches
                    alignSelf="start"
                />
            </TwoColumns>
            <LinkButtonLarge
                component={Link}
                to="/research"
            >
                リサーチ管理
            </LinkButtonLarge>
        </Stack>
    )
}

export default ResearchDetail
