import { useParams } from 'react-router-dom'
import NewsLetter from 'components/NewsLetter'
import { useNewsLetterByNo } from 'hooks/post'


const FeedbackNewsLetterDetail = () => {
    const { no } = useParams()
    const { data } = useNewsLetterByNo(no)

    return (
        <NewsLetter data={data} feedback />
    )
}

export default FeedbackNewsLetterDetail
