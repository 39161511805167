import { format } from 'date-fns'
import { Stack } from '@mui/material'
import ComponentBase from './ComponentBase'
import { UnderlineLink } from 'components/links'
import { H5, SmallBody } from 'components/styledFonts'
import Thumbnail from 'components/Thumbnail'
import { useNewEnterpriseList } from 'hooks/post'

const path = (d) => {
    return `/company/${d.企業ID}`
}

const NewEnterpriseItem = ({ data }) => {
    return (
        <Stack
            alignItems="center"
            width={110}
            gap={1}
        >
            <Thumbnail
                src={data.会社ロゴ}
                to={path(data)}
            />
            <UnderlineLink
                to={path(data)}
            >
                <H5>{data.会社名}</H5>
            </UnderlineLink>
            <SmallBody>{format(data.公開日, 'yyyy/MM/dd HH:mm')}</SmallBody>
        </Stack>
    )
}

const NewEnterprise = () => {
    const { data } = useNewEnterpriseList()

    return (
        <ComponentBase
            title="新着情報のある企業"
            buttons={[{ label : '企業検索', to : 'search' }]}
        >
            {(data && data.length > 0) &&
            <Stack
                direction="row"
                flexWrap="wrap"
                columnGap={3.7}
                rowGap={2}
                sx={{
                    px : { mobile : 0, xs : 2 },
                    justifyContent : { mobile : "flex-start", xs : "space-between" },
                }}
            >
                {data.map((d, i) => (
                <NewEnterpriseItem data={d} key={i} />
                ))}
            </Stack>
            }
        </ComponentBase>
    )
}

export default NewEnterprise

